export function StringComposer() {

  function transformContent(str, variables) {
    if (typeof str !== 'string') {
      throw new TypeError('First argument must be a string.');
    }
  
    if (typeof variables !== 'object' || variables === null) {
      throw new TypeError('Second argument must be a non-null object.');
    }
  
    // Create a copy of replacements with lowercased keys for case-insensitive matching
    const variablesLower = {};
    for (const key in variables) {
      if (Object.prototype.hasOwnProperty.call(variables, key)) {
        variablesLower[key.toLowerCase()] = variables[key];
      }
    }
  
    // Regular expression to match {{ word }}, {{Word}}, {{ WORD }}, etc.
    const regex = /{{\s*([a-zA-Z0-9_]+)\s*}}/gi;
  
    // Replace each placeholder with the corresponding replacement
    const replacedStr = str.replace(regex, (match, p1) => {
      const key = p1.toLowerCase();
      if (key in variablesLower) {
        return variablesLower[key];
      } else {
        // If no replacement found, return the original match or decide to replace with empty string
        // return match; // or return ''; to remove the placeholder
        return ''
      }
    });
  
    return replacedStr;
  }

  function truncate(input, length = 10) {
    if (input && input.length > length) {
      return input.substring(0, length) + '...';
    }
    return input;
  };

  function capitalizeWords(str) {
    if (!str) return '';
    return str.replace(/\b\w/g, function (char) {
      return char.toUpperCase();
    });
  }

  function getS3Key(url) {
    if (!url) return '';

    // Split after .com if it exists
    let parts = url.split('.com');
    let result = parts.length > 1 ? parts[1] : url;

    // Split at ? if it exists
    parts = result.split('?');
    result = parts[0];

    // Remove leading slash if present
    return result.startsWith('/') ? result.slice(1) : result;
    return
  }

  function objectToQueryString(obj) {
    const filteredObj = Object.fromEntries(
      Object.entries(obj).filter(([key, value]) => value !== '')
    );
    const queryString = new URLSearchParams(filteredObj).toString();
    return queryString ? `?${queryString}` : '';
  }

  function getFileNameWithoutExtension(url) {
    if (!url) return '';

    // Extract the file name from the URL
    const fileName = url.split('/').pop();

    // Remove the timestamp and dash at the beginning (if present)
    const nameWithoutTimestamp = fileName.replace(/^\d+-/, '');

    // Remove the file extension
    return nameWithoutTimestamp.replace(/\.[^/.]+$/, '');
  }
  function getFileNameWithExtension(url) {
    if (!url) return '';

    // Extract the file name from the URL
    const fileName = url.split('/').pop();

    // Remove the timestamp and dash at the beginning (if present)
    const nameWithoutTimestamp = fileName.replace(/^\d+-/, '');

    // Keep the file extension
    return nameWithoutTimestamp;
  }

  function toUpper(str) {
    return (str || '').toUpperCase()
  }

  function toLower(str) {
    return (str || '').toLowerCase()
  }

  function getFileName(filePath) {
    if (!filePath) return ''
    return filePath.substring(filePath.lastIndexOf('/') + 1);
  }

  function getFileType(filePath) {
    if (!filePath) return ''
    return toUpper(filePath.split('.').pop());
  }

  function actionToPrompt(action) {
    const { t } = useI18n({})

    switch (action) {
      case 'link':
        return t('common.website_link')
      case 'jump':
        return t('common.select_time')
      case 'video':
        return t('common.select_video')
      case 'message':
        return t('common.enter_message')
      case 'article':
        return t('common.select_article')
      case 'view':
        return t('common.select_image')
      case 'cart':
        return t('common.select_product')
      case 'clip':
        return t('common.select_clip')
      case 'audio':
        return t('common.select_audio')
      case 'email':
        return t('common.enter_email')
      case 'phone':
        return t('common.enter_phone')
      case 'modal':
        return t('common.enter_link')
      case 'collect':
        return t('common.enter_prompt')
      case 'download':
        return t('common.select_file')
      case 'show_card':
        return t('common.show_card')
      case 'get':
        // TODO nix these?
        return t('common.get')
      case 'post':
        // TODO nix these?
        return t('common.post')
      case 'none':
        return t('common.none')
      case 'back':
        return t('common.back')
      case 'genie':
        return t('common.genie_ai')
      case 'openai':
        return t('common.enter_prompt')
      case 'resourceTray':
        return t('common.magic_menu')
      default:
        return t('common.none')
    }
  }

  function animationToWord(animation) {
    switch (animation) {
      case 'pulse_v1':
        return t('common.pulse')
      case 'gelatine_v1':
        return t('common.gelatine')
      case 'spin':
        return t('common.spin')
      case 'elastic-spin':
        return t('common.elastic_spin')
      case 'hithere_v1':
        return t('common.hithere')
      case 'grow_v1':
        return t('common.grow')
      case 'bounce_v1':
        return t('common.bounce')
      case 'flip_v1':
        return t('common.flip')
      case 'shake_v1':
        return t('common.shake')
      case 'swing_v1':
        return t('common.swing')
      case 'wobble_v1':
        return t('common.wobble')
      case 'flash_v1':
        return t('common.flash')
      case 'jumbotron_v1':
        return t('common.jumbotron')
    }
  }
  function formatNumber(number, abbreviate = true) {
    if (number == undefined) {
      return 0
    }
    if (number >= 9999) {
      return (number / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
    } else if (number >= 1000) {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else {
      return abbreviate ? number.toString() : number.toFixed(2)
    }
  }

  function formatDollars(amount) {
    return '$' + formatNumber(amount, false)
  }
  function formatCents(amount) {
    return '$' + formatNumber(amount / 100, false)
  }
  return {
    formatDollars,
    formatCents,
    actionToPrompt,
    animationToWord,
    capitalizeWords,
    truncate,
    getFileType,
    toUpper,
    toLower,
    getFileName,
    getS3Key,
    getFileNameWithoutExtension,
    getFileNameWithExtension,
    objectToQueryString,
    formatNumber,
    transformContent,
  };
}