<template>
  <div class="p-4">
    <div class="p-8 text-center bg-white w-fit mx-auto h-[90vh] rounded-2xl shadow-xl w-[60dvw]">
      <AssetSelector class="h-full overflow-scroll" :manage="true" />
    </div>
  </div>
</template>

<script setup>  
import AssetSelector from '@/front/components/AssetSelector.vue'
onMounted(() => {
});
</script>
