<script setup>
import { VideoStore } from '@/front/stores/video_store.js'
const videoStore = VideoStore()
import { useI18n } from 'vue-i18n'
const { t } = useI18n({});
import Breadcrumb from 'primevue/breadcrumb'

const props = defineProps({
  items: {
    type: Array,
    required: true
  }
})

const getBreadcrumbItems = () => {
  const results = [
    { label: 'Home', to: '/' },
  ]
  return results.concat(props.items)
}
</script>

<template>
  <div>
    <Breadcrumb :model="getBreadcrumbItems()">
      <template #item="{ item, props }">
        <router-link v-if="item.to" v-slot="{ href, navigate }" :to="item.to" custom>
          <a :href="href" v-bind="props.action" @click="navigate">
            <!-- <span :class="[item.icon, 'text-color']" /> -->
            <span class="text-primary font-semibold">{{ item.label }}</span>
          </a>
        </router-link>
      </template>
    </Breadcrumb>
  </div>
</template>