<template>
  <div>
    <div v-for="result in results"> 
      {{ result }},
    </div>
  </div>
</template>

<script setup>
import { StringComposer } from '@/front/composables/StringComposer.js';  
const { transformContent } = StringComposer()
import { TimeFormatter } from '@/front/composables/TimeFormatter.js'
const { convertDecitimeToKey, formatSeconds } = TimeFormatter();

const results = ref([])
onMounted(() => {
  for (let i = 0; i < 5000; i++) {
    const val = Number((i / 10).toFixed(1))
    results.value.push({ label: formatSeconds(val), value: val, key: convertDecitimeToKey(val) })
  }
})
</script>
