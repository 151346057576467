  <template>
  <div >
    <div class="p-4">
      <div class="p-8 mb-96 text-center bg-white w-fit mx-auto rounded-2xl shadow-xl mt-8 min-w-[60dvw]">
        <DesignForm v-if="userStore.present"/>
      </div>
    </div>
  </div>
</template>

<script setup>  
import { UserStore } from '@/front/stores/user_store.js';
const userStore = UserStore();

import DesignForm from '@/front/components/DesignForm.vue';
onMounted(() => {
});
</script>
