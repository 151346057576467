<template>
  <section class="container mx-auto">
    <AccessListsIndex />
    
  </section>
</template>

<script setup>
import AccessListsIndex from '@/front/components/AccessListsIndex.vue'

onMounted(() => {
});
</script>
