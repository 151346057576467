<script setup>
import { UserStore } from '@/front/stores/user_store.js'
const userStore = UserStore()
import { SeriesStore } from '@/front/stores/series_store.js'
const seriesStore = SeriesStore()
import { VideoStore } from '@/front/stores/video_store.js'
const videoStore = VideoStore()
import { AssetStore } from '@/front/stores/asset_store.js'
const assetStore = AssetStore()
import { useToast } from "primevue/usetoast";
const toast = useToast();
import { useI18n } from 'vue-i18n'
const { t } = useI18n({});
import ToggleSwitch from 'primevue/toggleswitch';
import InputNumber from 'primevue/inputnumber';
import TimeSelector from '@/front/components/TimeSelector.vue';
import Select from 'primevue/select';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import { PaywallHelper } from '@/front/composables/PaywallHelper.js'
const { getCurrencies, getFee, getLinkExpirations } = PaywallHelper()

const busy = ref(false)

const props =  defineProps({
  series_id: {
    type: String,
    required: false
  },
  video_id: {
    type: String,
    required: false
  },
})

const capture_payment = ref(false)
const capture_payment_amount = ref(10)
const capture_payment_currency = ref("USD")
const stripe_expiration = ref(-1)
const stripe_contact_email = ref(userStore.organization.email || userStore.user.email)
const capture_payment_point = ref(-1)
const capture_payment_value = ref(null)

const updatePaymentTime = (value) => {
  capture_payment_value.value = value
  console.log("💾 PaywallForm updatePaymentTime", capture_payment_value.value)
}

onMounted(() => {
  if (props.series_id) {
    capture_payment.value = seriesStore.series.capture_payment
    capture_payment_amount.value = seriesStore.series.capture_payment_amount
    capture_payment_currency.value = seriesStore.series.capture_payment_currency
    stripe_expiration.value = seriesStore.series.stripe_expiration
    stripe_contact_email.value = seriesStore.series.stripe_contact_email
  } else if (props.video_id) {
    capture_payment.value = videoStore.video.capture_payment
    capture_payment_amount.value = videoStore.video.capture_payment_amount
    capture_payment_currency.value = videoStore.video.capture_payment_currency
    stripe_expiration.value = videoStore.video.stripe_expiration
    stripe_contact_email.value = videoStore.video.stripe_contact_email
    capture_payment_point.value = videoStore.video.capture_payment_point
    capture_payment_value.value = videoStore.video.capture_payment_value || 0
    capture_payment.value = capture_payment_point.value != -1
    console.log("💾 PaywallForm onMounted video_id", capture_payment.value, capture_payment_point.value)
  } else {
    console.log("💾 PaywallForm onMounted no video_id or series_id")
  }
})

const capture_payment_points = ref([
  { label: t('common.before_video_plays'), value: 0 },
  { label: t('common.at_specific_time'), value: 2}
])

import StripeForm from '@/front/components/StripeForm.vue';

const savePaywall = () => {
  console.log("💾 PaywallForm savePaywall", capture_payment.value, capture_payment_amount.value, capture_payment_currency.value, stripe_expiration.value, stripe_contact_email.value)
  if (props.series_id) {
    updateSeries()
  } else if (props.video_id) {
    updateVideo()
  } else {
    toast.add({ title: t('common.error'), summary: t('common.something_went_wrong'), severity: 'error', life: 3000 })
  }
}

const updateSeries = () => {
  console.log("💾 PaywallForm updateSeries", capture_payment.value, capture_payment_amount.value, capture_payment_currency.value, stripe_expiration.value, stripe_contact_email.value)
  busy.value = true
  seriesStore.update({ capture_payment: capture_payment.value, capture_payment_amount: capture_payment_amount.value, capture_payment_currency: capture_payment_currency.value, stripe_expiration: stripe_expiration.value, stripe_contact_email: stripe_contact_email.value }).then(() => {
    busy.value = false
    toast.add({ title: t('common.success'), summary: t('common.series_updated'), severity: 'success', life: 3000 })
  }).catch((error) => {
    console.log("💾 SeriesManage updateSeries error", error)
    toast.add({ title: t('common.error'), summary: error.response.data.message, severity: 'error', life: 3000 })
    busy.value = false
  }).finally(() => {
    busy.value = false
  })
}

const updateVideo = () => {
  console.log("💾 PaywallForm updateVideo", capture_payment.value, capture_payment_amount.value, capture_payment_currency.value, stripe_expiration.value, stripe_contact_email.value)
  busy.value = true
  videoStore.video.capture_payment_point = capture_payment_point.value
  videoStore.video.capture_payment_amount = capture_payment_amount.value
  videoStore.video.capture_payment_value = capture_payment_value.value
  videoStore.video.capture_payment_currency = capture_payment_currency.value
  videoStore.video.stripe_expiration = stripe_expiration.value
  videoStore.video.stripe_contact_email = stripe_contact_email.value
  videoStore.update().then(() => {
    busy.value = false
    var msg = t('common.video_updated')
    if (capture_payment_point.value == -1) {
      msg = t('common.paywall_disabled')
    }
    toast.add({ title: t('common.success'), summary: msg, severity: 'success', life: 3000 })
  }).catch((error) => {
    console.log("💾 VideoManage updateVideo error", error)
    toast.add({ title: t('common.error'), summary: error.response.data.message, severity: 'error', life: 3000 })
    busy.value = false
  }).finally(() => {
    busy.value = false
  })
}

const toggleCapturePayment = () => {
  console.log("💾 SeriesPaywall toggleCapturePayment", capture_payment.value)
  // Runs before v-model updates so we need to set the values manually
  if (!capture_payment.value) {
    capture_payment_amount.value = capture_payment_amount.value || 10
    capture_payment_currency.value = capture_payment_currency.value || "USD"
    stripe_expiration.value = stripe_expiration.value || -1
    stripe_contact_email.value = stripe_contact_email.value || userStore.organization.email || userStore.user.email

    if (props.video_id) {
      console.log("💾 PaywallForm toggleCapturePayment video_id set to 0")
      capture_payment_point.value = 0
    }
  } else {
    if (props.video_id) {
      capture_payment_point.value = -1
      updateVideo()
    }
  }
}

</script>

<template>
  <div class="max-w-md mx-auto">
    <div v-if="userStore.user.id && !userStore.user.stripe_connect_connected">
      <StripeForm />
    </div>
    <div v-else-if="userStore.user.id">
      <div class="flex items-center mb-4 mx-auto w-fit">
        <ToggleSwitch v-model="capture_payment" @click="toggleCapturePayment" />
        <label class="ml-2">{{ $t('common.capture_payment_switch') }} {{ props.series_id ? $t('common.series') : $t('common.video') }}</label>
      </div>
      <div v-if="capture_payment">

        <div v-if="props.video_id" class="mb-4">
          <div class="mb-2">
            <label class="block text-lg font-medium">{{ $t('common.when_to_capture_payment') }}</label>
            <Select v-model="capture_payment_point" :options="capture_payment_points" optionLabel="label" optionValue="value" class="w-full h-12" />
          </div>
          <div class="mb-2" v-if="capture_payment_point == 2">
            <!-- <label class="block text-lg font-medium">{{ $t('common.at_specific_time') }}</label> -->
            <TimeSelector :value="Number(capture_payment_value) || 0" :min="0" :max="videoStore.video.duration || 600" class="w-full" @update="updatePaymentTime" />
          </div>
        </div>  
          <!-- Payment info -->
        <div class="grid grid-cols-4 gap-4">
          <div class="col-span-2">
            <label class="block text-lg font-medium">{{ $t('common.amount') }}</label>
            <InputNumber v-model="capture_payment_amount" :min="2" :max="500" class="w-full h-12" />
          </div>
          <div class="col-span-2">
            <label class="block text-lg font-medium">{{ $t('common.currency') }}</label>
            <Select v-model="capture_payment_currency" :options="getCurrencies()"
              class="w-full h-12" />
          </div>
          <div class="col-span-4">
            <div class="text-xs text-gray-500">{{ getFee(capture_payment_amount) }}</div>
          </div>

          <!-- Expiration -->
          <div class="col-span-4">
            <label class="block text-lg font-medium">{{ $t('common.link_expiration_question') }}</label>
            <Select v-model="stripe_expiration" :options="getLinkExpirations()" optionLabel="label" optionValue="value"
              class="w-full h-12" />
            <div class="text-xs text-gray-500 mt-4">{{ $t('common.link_expiration_explanation') }}</div>
          </div>

          <!-- Contact Email -->
          <div class="col-span-4">
            <label class="block text-lg font-medium">{{ $t('common.contact_email') }}</label>
            <InputText v-model="stripe_contact_email" class="w-full h-12" />
          </div>
          <div class="col-span-4">
            <div class="text-xs text-gray-500">{{ $t('common.stripe_email_explanation') }}</div>
          </div>

        </div>
      </div>
      <Button @click="savePaywall" :loading="busy" size="large" class="mt-4">{{
        $t('common.save') }}</Button>
    </div>
  </div>
</template>

