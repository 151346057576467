<script setup>  
  import { SeriesStore } from "../stores/series_store.js";
  import TablePagination from '@/front/components/TablePagination.vue';
  import RowSkeleton from '@/front/components/RowSkeleton.vue';
  import { TimeFormatter } from "../composables/TimeFormatter.js";
  const { formatSeconds, getDomain } = TimeFormatter();
  import SeriesFilters from '@/front/components/SeriesFilters.vue';
  import Button from 'primevue/button'
  

  import dayjs from 'dayjs'
  import isToday from "dayjs/plugin/isToday";
  import isYesterday from "dayjs/plugin/isYesterday";
  dayjs.extend(isToday);
  dayjs.extend(isYesterday);

  import { useToast } from "primevue/usetoast";
  const toast = useToast();

  const store = SeriesStore();

  const location = useRoute();
  const router = useRouter();

  const index = (event => {  
    store.index(location.fullPath)
  });

  onMounted(() => {
    index(); 
  });

  const showFilters = ref(false);
  const showColumns = ref(false);
  const showSelect = ref(false);

  const activeFilters = ref([]);
  const setActiveFilters = (filters) => {
    activeFilters.value = filters
  }

  const setQuery = (page) => {
    const query = JSON.parse(JSON.stringify(location.query));
    query['page'] = page;
    return query;
  }

  const clearSelection = () => {
    store.selected = []
  }
  const tryAddSelect = (id) => {
    if (!store.selected.includes(id)) {
      store.selected.push(id)
    } else {
      store.selected = store.selected.filter(i => i != id)
    }
  }

  const deleteSelected = () => {
    console.log('deleteSelected', store.selected)
    store.deleteSelected().then(()=> {
      showSelect.value = false
      toast.add({ severity: 'success', summary: 'Success', detail: t('views.deleted'), life: 3000 });
      index()
    }).catch((error)=> {
      console.log('error', error)
      toast.add({ severity: 'error', summary: 'Error', detail: error.response?.data?.errors || 'An error occurred', life: 3000 });
    })
  }

  const selectAll = () => {
    store.views.forEach((v)=> {
      if (!store.selected.includes(v.id)) {
        store.selected.push(v.id)
      }
    })
  }

  const toggleSelect = () => {
    showFilters.value = false
    showColumns.value = false

    store.selected = []
    showSelect.value = !showSelect.value;
  }

  const toggleFilters = () => {
    showColumns.value = false
    showSelect.value = false
    showFilters.value = !showFilters.value;
  }

  const toggleColumns = () => {
    showFilters.value = false
    showSelect.value = false
    showColumns.value = !showColumns.value;
  }


  const getFilterButtonClass = (show) => {
    var str = getActiveButtonClass(show)
    if (activeFilters.value.length && !showFilters.value) {
      str += ' bg-red-300 text-black'
    }
    return str
  }

  const getActiveButtonClass = (show) => {
    return show ? 'hover:bg-gray-800 hover:text-white !bg-gray-800 text-white' : ''
  }
</script>

<template>
  <div class="container select-none">
    <div class="grid grid-cols-3 pt-4 px-4 bg-gray-900 text-white mb-2">
      <div class="col-span-1">
        <TablePagination v-if="store.pagination" :type="5" :store="store" @clicked="index" ></TablePagination>
      </div>
      <div class="col-span-1 text-center">
        <SeriesFilters class="mb-4"/>
      </div>
      <div class="col-span-1 text-right">
        <Button to="/videos">New</Button>
      </div>
    </div>


    <table class="w-full text-lg overflow-scroll text-left rtl:text-right text-gray-800 m-4">
        <thead class="uppercase bg-gray-200 w-full sticky top-0">
          <tr class="text-xs">

            <!-- TODO localize these -->
            <th v-for="c in store.columns" :key="c" scope="col" class="pl-2 py-3 whitespace-nowrap"> {{ c }} </th>

          </tr>
        </thead>
        <tbody v-if="store.loading">
          <RowSkeleton v-for="i in 10" :columns="store.columns.length" :key="i"/>
        </tbody>
        <tbody v-else>
            <tr v-for="(s, index) in store.series_list" @click="router.push({name: 'show_series_path', params: { id: s.id} })" class="border-b hover:bg-yellow-50 cursor-pointer" :key="s.id" :class="{'bg-white': index % 2 == 0, 'bg-gray-100': index % 2 == 1}">
              
              <!-- Started -->
              <td scope="row" class="px-4 py-4 font-light text-gray-900 whitespace-nowrap overflow-scroll">
                <!-- TODO put this into reusable -->
                {{  dayjs(s.created_at).isToday() ? 
                    `Today, ${dayjs(s.created_at).format(' @ h:mm a')}` : 
                    s.created_at.includes('2024') ? 
                    dayjs(s.created_at).format('MMMM D') : 
                    dayjs(s.created_at).format('MMM D YYYY') }}
              </td>

              <!-- Name -->
              <td scope="row" class="px-2 py-2 text-gray-900 whitespace-nowrap overflow-scroll">
                {{  s.name }} 
              </td>

              
            </tr>
        </tbody>
    </table>
  </div>
</template>