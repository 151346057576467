<template>
  <div>
    <div class="card">
        <Tabs value="0">
            <TabList class="w-full ">
                <Tab value="0">{{ $t('common.upload_image') }}</Tab>
                <Tab value="1">{{ $t('common.my_images') }}</Tab>
                <Tab value="2">{{ $t('common.stock_library') }}</Tab>
            </TabList>
            <TabPanels>
                <TabPanel value="0">
                  <FileUploader class="h-48 w-full items-center justify-center" @success="finishUpload" :type="['image/png', 'image/jpeg', 'image/webp']" :remote="false"/>

                </TabPanel>
                <TabPanel value="1">
                    <div class="grid grid-cols-6 gap-8 h-96 overflow-y-auto">
                        <div v-for="image in store.images" :key="image.id" @click="selectImage(image)">
                            <img :src="image.content" alt="Image" class="w-full h-full object-contain">
                        </div>
                    </div>
                </TabPanel>
                <TabPanel value="2">
                  <!-- TODO stock library -->
                </TabPanel>
            </TabPanels>
        </Tabs>
    </div>
  </div>
</template>

<script setup>
  import Dialog from 'primevue/dialog';
  import InputText from 'primevue/inputtext';
  import Tabs from 'primevue/tabs';
  import TabList from 'primevue/tablist';
  import Tab from 'primevue/tab';
  import TabPanel from 'primevue/tabpanel';
  import TabPanels from 'primevue/tabpanels';
  import FileUploader from "@/front/components/FileUploader.vue";
  import { AssetStore } from "@/front/stores/asset_store.js";

  const emit = defineEmits(['setImage'])

  defineProps({
    visible: {
      type: Boolean,
      required: true,
    },
  });

  const store = AssetStore();
  store.index()

  const selectImage = (image) => {
    console.log("Emit setThumbnail", image)
    emit('setImage', image.content)
  }

  const finishUpload = (url) => {
    console.log("Finish Upload", url)
    emit('setImage', url)
  }

</script>