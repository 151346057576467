<template>
  <section class="container mx-auto">
    <OwnerOrg v-if="userStore.user.org_owner" />
    <MemberOrg v-else />
  </section>
</template>

<script setup>  
import { UserStore } from '@/front/stores/user_store.js'
const userStore = UserStore();
import OwnerOrg from '@/front/components/OwnerOrg.vue'
import MemberOrg from '@/front/components/MemberOrg.vue'

onMounted(() => {
});
</script>
