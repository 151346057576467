<template>
  <div class="text-left">    
    <div v-if="store.pagination">  

      <div class="grid grid-cols-1">
        <div class="col-span-2 text-2xl font-bold mb-4 text-center"> 
          {{ false && store.loading ? 'Loading...' : formatNumber(store.pagination.count || 0) }} 
          {{ false && store.loading ? '' : type == 0 ? $t('common.views') : type == 1 ? $t('common.interactions') : type == 2 ? $t('common.videos') : type == 3 ? $t('common.viewers') : type == 4 ? $t('common.groups') : type == 5 ? $t('common.series') : '' }} 
          <div v-if="store.pagination.current != 1" class="text-sm font-normal text-center"> 
            <span v-if="!store.loading"> {{ $t('pagination.page') }} {{ store.pagination.current}} {{ $t('common.of') }} {{ Math.max(1, store.pagination.pages) }} </span> 
            <span v-else> &nbsp; </span> 
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { TimeFormatter } from "../composables/TimeFormatter.js";
const { formatNumber } = TimeFormatter();


const clickEvent = defineEmits(['clicked'])
const location = useRoute();
const props = defineProps(['store', 'type']);
const router = useRouter();

watch(() => location.query, () => {
  clickEvent('clicked', true);
});

const setQuery = (page) => {
  const query = JSON.parse(JSON.stringify(location.query));
  query['page'] = page;
  return query;
}

</script>
