<script setup>
import { UserStore } from '@/front/stores/user_store.js'
const userStore = UserStore();
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'
const { t } = useI18n({});
import Dialog from 'primevue/dialog'
import InputText from 'primevue/inputtext'
import Button from 'primevue/button'
import { StringComposer } from '@/front/composables/StringComposer.js'
const { capitalizeWords } = StringComposer()

onMounted(() => {
});

const editOrgDialog = ref(false)
const saveOrg = () => {
  editOrgDialog.value = false
  userStore.organization.name = orgName.value
  userStore.updateOrganization()
}

const orgName = ref(userStore.organization.name)
</script>

<template>
  <div>
    <div class="p-4">
      <div class="p-8 mb-96 text-center bg-white w-fit mx-auto rounded-2xl shadow-xl mt-8 min-w-[60dvw]">
        <h1 class="text-4xl font-extrabold"> {{ userStore.organization.name }} </h1><Button text size="small" label="Edit" @click="editOrgDialog = true" />
        <div class="flex justify-center mt-4">
          {{ capitalizeWords(userStore.organization.plan) }}
        </div>

        <!-- User management -->
        <div class="flex justify-center mt-4">
          <router-link to="/account/organization/users">
            <Button :label="$t('common.manage_users')" />
          </router-link>
        </div>

        <!-- Quotas and limits -->
        <div class="flex justify-center mt-4">
          <router-link to="/account/organization/quotas">
            <Button :label="$t('common.quotas_and_limits')" />
          </router-link>
        </div>

        <!-- Bulk edit -->
        <div class="flex justify-center mt-4">
          <router-link to="/account/organization/bulk-edit">
            <Button :label="$t('common.bulk_edit')" />
          </router-link>
        </div>

        <!-- Access Lists -->
        <div class="flex justify-center mt-4">
          <router-link to="/account/organization/access-lists">
            <Button :label="$t('common.access_lists')" />
          </router-link>
        </div>
      </div>
    </div>
  </div>
  <Dialog v-model:visible="editOrgDialog" :header="$t('common.edit_organization')" modal class="w-[500px] max-w-[90vw]"
    :closable="false">
    <div>
      <InputText v-model="orgName" class="w-full" />
    </div>
    <div class="flex justify-center mt-4">
      <Button label="Save" @click="saveOrg" />
    </div>
  </Dialog>
</template>
