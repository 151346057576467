<template>
  <div role="status" class="max-w-sm rounded-lg shadow-lg bg-white">
    <div class="flex items-center justify-center h-40 mb-3 relative">
      <img :src="video.thumbnail || getDefaultVideoThumbnail()" alt="Thumbnail" class="w-full h-full object-cover bg-gray-200 rounded-t-md">
      <div v-if="stats" class="absolute top-2 left-2 w-full flex flex-wrap">
        <span v-if="video.group_id" class="text-white text-xs bg-black/50 px-2 py-1 rounded-md mr-2 mb-2"> {{ findGroupById(video.group_id).name }} </span>
        <span v-for="tag in video.tags" class="text-white text-xs bg-black/50 px-2 py-1 rounded-md mr-2 mb-2" :key="tag">{{ tag }}</span>
      </div>
    </div>
  
    <div class="text-center px-4 pb-4">
      <div class="pb-3">
        <h3 class="text-lg font-bold leading-6 text-gray-900 whitespace-nowrap overflow-scroll">
          {{ video.title }}
        </h3>
      </div>
      <div v-if="stats" class="grid grid-cols-3 text-xs text-center text-gray-500">
        <div class="flex items-center justify-center">
          <EyeIcon class="h-4 w-4 mr-1" /> {{ video.stats_views || 0 }} 
        </div>
        <div class="flex items-center justify-center">
          <Touch01Icon class="h-4 w-4 mr-1" /> {{ video.stats_interactions || 0 }} 
        </div>
        <div class="flex items-center justify-center">
          <SmileIcon class="h-4 w-4 mr-1" /> {{ video.stats_viewers || 0 }} 
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>  
import { AssetFinder } from '@/front/composables/AssetFinder.js'
const { findGroupById } = AssetFinder()

import { ImageComposer } from '@/front/composables/ImageComposer.js'
const { getDefaultVideoThumbnail } = ImageComposer()
import { EyeIcon, Touch01Icon, SmileIcon } from 'hugeicons-vue';

// TODO handle real defaults
 
 defineProps({
  video: {
    type: Object,
    required: true
  },
  stats: {
    type: Boolean,
    default: true
  }
 })
</script>
