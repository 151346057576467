<script setup>
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import { AssetStore } from '@/front/stores/asset_store.js';  
const assetStore = new AssetStore()

import { useI18n } from 'vue-i18n';
const { t } = useI18n();

const content = ref('')
const title = ref('')
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import { useToast } from 'primevue/usetoast';
const toast = useToast();


const props = defineProps({
  article: {
    type: Object,
    default: null
  },
  readOnly: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits(['close'])

const save = () => {
  console.log("save", title.value, content.value)
  if (props.article) {
    assetStore.updateArticle(props.article.id, { title: title.value, content: content.value }).then(response => {
      console.log("🌜 Article updated", response)
      toast.add({ severity: 'success', summary: t('common.success'), detail: t('common.saved')});
      emit('close')
    })
  } else {
    assetStore.createArticle({ title: title.value, content: content.value }).then(response => {
      console.log("🌜 Article created", response)
      toast.add({ severity: 'success', summary: t('common.success'), detail: t('common.saved')});
      emit('close')
    })
  }
}

watch(() => props.article, (newVal) => {
  if (newVal) {
    content.value = newVal.content
    title.value = newVal.title
  }
}, { immediate: true })
</script>

<template>
  <div :class="{ 'readonly': readOnly }">
    <div v-if="!readOnly">
      <div class="mb-2"><label class="font-bold mb-2">{{ t('common.article') }} {{ t('common.title') }}</label></div>
      <div><InputText v-model="title" class="w-full" /></div>
    </div>
    <div>
      <div v-if="!readOnly" class="mt-4 mb-2"><label class="font-bold mb-2">{{ t('common.article') }} {{ t('common.content') }}</label></div>
      <QuillEditor theme="snow" v-model:content="content" toolbar="full" contentType="html" :readOnly="readOnly"
        class="w-full h-auto min-h-[400px] bg-white" />
    </div>
    <div v-if="!readOnly" class="my-4 text-center">
      <Button :label="t('common.save')" @click="save" class="ml-2" :disabled="!title || !content" />
    </div>
  </div>
</template>

<style>
.ql-toolbar {}

.readonly .ql-toolbar {
  @apply hidden;
}
</style>