export function CountryHelper() {

  function getCountryList() {
    return [
      { text: 'English', value: 'en'},
      { text: 'Spanish', value: 'es'},
      { text: 'French', value: 'fr'},
      { text: 'Turkish', value: 'tr'},
      { text: 'Portuguese', value: 'pt'},
      { text: 'Korean', value: 'ko'},
      { text: 'Hindi', value: 'hi'},
      { text: 'Arabic', value: 'ar'},
      { text: 'Russian', value: 'ru'},
      { text: 'German', value: 'de'},
      { text: 'Italian', value: 'it'},
      { text: 'Dutch', value: 'nl'},
      { text: 'Chinese (Simplified)', value: 'zh'},
      { text: 'Chinese (Simplified, PRC)', value: 'zh-cn'},
      { text: 'Chinese (Simplified, SNG)', value: 'zh-sg'},
      { text: 'Chinese (Traditional, HK)', value: 'zh-cn'},
      { text: 'Chinese (Cantonese)', value: 'zh-yue'},
      { text: 'Chinese (Mandarin)', value: 'zh-cmn'},
      { text: 'Japanese', value: 'ja'},
      { text: 'Swedish', value: 'sw'},
      { text: 'Indonesian', value: 'id'},
      { text: 'Bengali', value: 'bn'},
      { text: 'Punjabi', value: 'pa'},
      { text: 'Urdu', value: 'ur'},
      { text: 'Telugu', value: 'ta'},
      { text: 'Norwegian', value: 'no'},
    ]
  }

  return {
    getCountryList,
  };
}