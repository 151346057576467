import { UAParser } from 'ua-parser-js';
export function BrowserHelper() {


  // Instantiate UAParser once
  const parser = new UAParser();

  // Reactive object to store browser information
  const browserInfo = reactive({
    isIOS: parser.getOS().name === 'iOS',
    isSafari: parser.getBrowser().name === 'Safari',
    isSafariOrIOS: parser.getOS().name === 'iOS' || parser.getBrowser().name === 'Safari',
    userAgent: parser.getUA(),
    browser: parser.getBrowser(),
    device: parser.getDevice(),
    os: parser.getOS(),
    isMobile: parser.getDevice().type === 'mobile',
    isTablet: parser.getDevice().type === 'tablet',
    isDesktop: parser.getDevice().type === 'desktop',
  })

  return {
    browserInfo
  };
}