<script lang="ts" setup>
// Import styles.
import 'vidstack/player/styles/base.css';
// Register elements.
import 'vidstack/player';
import 'vidstack/player/ui';
import 'vidstack/icons';


import { type MediaCanPlayEvent, type MediaProviderChangeEvent } from 'vidstack';
import type { MediaPlayerElement } from 'vidstack/elements';
import { computed, onMounted, ref } from 'vue';

import { AudioStore } from '@/front/stores/audio_store.js';
import Button from 'primevue/button';

const audioStore = AudioStore();
const $player = ref<MediaPlayerElement>();
const $src = ref('');

onMounted(() => {
  
  audioStore.player = $player

  // const { currentSrc, controlsHidden, currentTime, duration, fullscreen, playing, qualities, quality, seekableEnd, seekableStart, textTrack, volume, waiting, controlsVisible, ended, error, height, muted, paused, playbackRate, seeking, started, width } = $player.value?.state
  $src.value =  { "src": audioStore.src, "type": "audio/mp3" } 

  $player.value?.subscribe(({ currentSrc, controlsHidden, fullscreen, playing, qualities, quality, seekableEnd, seekableStart, textTrack, volume, waiting, controlsVisible, duration, ended, error, height, muted, paused, playbackRate, seeking, started, width }) => {
    audioStore.setPlayerState({ currentSrc, controlsHidden, fullscreen, playing, qualities, quality, seekableEnd, seekableStart, textTrack, volume, waiting, controlsVisible, duration, ended, error, height, muted, paused, playbackRate, seeking, started, width })
  });
  
  console.log("onMounted", $player)
});

  //

// We can listen for the `can-play` event to be notified when the player is ready.
function onCanPlay(event: MediaCanPlayEvent) {
  console.log("onCanPlay", event)
  $player.value?.play()

  // ...
}

function togglePlay() {
  audioStore.playerState.playing ? $player.value?.pause() : $player.value?.play()
}

function onTimeUpdate(event: MediaTimeUpdateEvent) {
  console.log("onTimeUpdate", event.detail.currentTime)
  audioStore.currentTime = event.detail.currentTime
}

const props = defineProps({
  controls: {
    type: Boolean,
    default: true
  }
})

</script>

<template>
  <media-player
    view-type="audio"
    :src="$src"
    playsInline
    @can-play="onCanPlay"
    @time-update="onTimeUpdate"
    :controls="props.controls"
    ref="$player"
    id="media-player"
    class="w-96"
  >
    <media-provider id="media-provider">
      <track :src="audioStore.src" kind="audio" />
    </media-provider>
  </media-player>
</template>