import { defineStore } from 'pinia'
import { UserStore } from '@/front/stores/user_store.js'
import { VideoStore } from '@/front/stores/video_store.js'
import { useRoute } from 'vue-router'
import { BrowserHelper } from '@/front/composables/BrowserHelper.js'


import _ from 'lodash'; // To create a deep clone of the interaction object

export const PlaybackStore = defineStore('playback', {
  state: () => {
    return {
      started: false,
      error: null,
      loading: false,
      id: null, // playback id
      variables: {}, // variables to be used in the playback
      seconds: [], // watched seconds
      viewer: {}, // viewer object
      params: null, // url arguments at initialization
      url: null, // url of the playback
      browser: null, // browser object
      screenWidth: null, // screen width
      screenHeight: null, // screen height
      refer_video: null, // token of video that branched to this playback
      captions: null, // captions label being used if any
      trackingInterval: null, // interval to track playback
      lastTime: null, // last second updated,
      lastUpdate: null,
    }
  },

  actions: {

    async update() {
      if (this.error) {
        console.log("🔴 PlaybackStore update error, returning", this.error)
        clearInterval(this.trackingInterval)
        this.trackingInterval = null
        return
      }
      this.loading = true
      let endpoint = `/plays/${this.id}`
      if (!this.id) {
        console.log("🔎 PlaybackStore CREATE")
        endpoint = '/plays'
      } else {
        console.log(`🔎 PlaybackStore UPDATE, id: ${this.id}`)
      }

      console.log(this.data)
      if (!this.started) {
       console.log("🔎 Not started, skipping update")
       return
      }
      this.Api.post(endpoint, { play: this.data }).then((response)=> {
        console.log("✅ Play saved", response)
        this.id = response.data.play.id
        this.viewer_id = response.data.play.viewer_id
        this.storeViewer()
      }).catch((error)=> {
        this.error = error
      }).finally(() => {
        this.loading = false
      })
    },

    setVariable(key, value) {
      console.log(`⭐️ SetVariable, ${key.toUpperCase()} --> ${value}`)
      this.variables[key.toUpperCase()] = value || this.variables[key.toUpperCase()]
    },

    initialize(route) {
      // TODO handle resetting / dont remember flag
      this.viewer = localStorage.getItem('viewer') ? JSON.parse(localStorage.getItem('viewer')) : {}

      // Process browser and URL
      this.screenWidth = window.innerWidth
      this.screenHeight = window.innerHeight
      this.browser = BrowserHelper().browserInfo.browser.name + ' ' + BrowserHelper().browserInfo.browser.version
      this.url = document.referrer || window.location.href
      this.params = route.query ? `?${Object.entries(route.query).map(([key, value]) => `${key}=${value}`).join('&')}` : null
      Object.entries(route.query).forEach(([key, value]) => {
        this.setVariable(key, value)
      })
      console.log("Variables", this.variables)
      this.viewer.name = this.variables['NAME'] || localStorage.getItem('viewer')?.name
      this.viewer.email = this.variables['EMAIL'] || localStorage.getItem('viewer')?.email
      this.viewer.phone = this.variables['PHONE'] || localStorage.getItem('viewer')?.phone
      this.viewer.custom_id = this.variables['CUSTOM_ID'] || localStorage.getItem('viewer')?.custom_id
      console.log("Viewer", this.viewer)

      // Begin looping updates
    },

    beginTracking() {
      console.log("🔎 PlaybackStore beginTracking")
      setTimeout(()=> {
        this.update().then(()=> {
          this.trackingInterval = setInterval(this.update, 5000)
        })
      }, 1000)
      this.started = true
    },

    handleTimeUpdate(time) {
      // Start tracking if not already started
      if (time > 0 && !this.started) {
        this.beginTracking()
      }

      // Update last time
      const newTime = Math.floor(time)
      if (newTime !== this.lastTime) {
        console.log("🔎 PlaybackStore newTime", newTime)
        this.lastTime = newTime
        this.seconds.push(newTime)
      }
    },

    storeViewer() {
      localStorage.setItem('viewer', JSON.stringify(this.viewer))
      localStorage.setItem('variables', JSON.stringify(this.variables))
    }
    
  },
  getters: {
    data: (state) => {
      const videoStore = VideoStore()
      const data = {
        seconds: state.seconds,
        variables: state.variables,
        viewer_id: state.viewer?.id,
        guestName: state.viewer?.name, // TODO rename fields
        guestEmail: state.viewer?.email, // TODO rename fields
        guestPhone: state.viewer?.phone, // TODO rename fields
        guestID: state.viewer?.custom_id, // TODO rename fields
        screenWidth: state.screenWidth,
        screenHeight: state.screenHeight,
        refer_video: state.refer_video,
        captions: state.captions,
        url: state.url,
        token: videoStore.video.token,
        params: state.params,
        browser: state.browser,
      }
      return data
    }
  }
})

