<script setup>

const props = defineProps({
  ann:{
    type: Object,
    required: true
  }
})

import { VideoStore } from '@/front/stores/video_store.js';
const videoStore = VideoStore();

const customStyle = props.ann.customStyle && JSON.parse(props.ann.customStyle) || {}
const styling = computed(() => {
  const baseDesign = videoStore.video.design || {}
  // TODO make sure this doesnt break in diff context
  return {
    'color': props.ann.text_color || customStyle.color || baseDesign.button_text_color, 
    'background': props.ann.background_color || customStyle.background_color || baseDesign.button_background_color, 
    'borderColor': props.ann.borderColor || customStyle.borderColor || baseDesign.button_border_color || 'red',
    'borderRadius': (props.ann.borderRadius || customStyle.borderRadius || baseDesign.button_border_radius) + 'px',
  }
})
</script>

<template>
  
  <button class="button shadow-md border-2 w-full min-h-12 px-4 py-2" :style="styling">
    <span v-html="ann.transformed_content"></span>
  </button>


</template>