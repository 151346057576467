<script setup>
import { UserStore } from '@/front/stores/user_store.js'
const userStore = UserStore();
import { useRoute } from 'vue-router'
const router = useRouter()
import { useToast } from 'primevue/usetoast';
const toast = useToast();
import { useI18n } from 'vue-i18n'
const { t } = useI18n({});
import { StringComposer } from '@/front/composables/StringComposer.js'
const { formatCents } = StringComposer();
import { DateComposer } from '@/front/composables/DateComposer.js'
const { daysFromNow, dmyyyy } = DateComposer();

const loading = ref(true)

const busy = ref(false)

import Button from 'primevue/button'
onMounted(() => {
  if (userStore.organization.plan != 'free') {
    userStore.fetchBilling().then(() => {
      console.log(userStore.billing)
    loading.value = false
  })
  } else {
    loading.value = false
  }
});

const keepSubscription = () => {
  userStore.keepSubscription().then(() => {
    toast.add({
      title: t('common.subscription_kept'),
      severity: 'success',
      life: 3000
    })
    loading.value = true
    userStore.fetchBilling().then(() => {
      loading.value = false
    })
  })
}

const resumeSubscription = () => {
  busy.value = true
  userStore.resumeSubscription().then(() => {
    toast.add({
      title: t('common.subscription_resumed'),
      severity: 'success',
      life: 3000
    })
    loading.value = true
    userStore.fetchBilling().then(() => {
      loading.value = false
      busy.value = false
    })
  })
}

const getStripePortalSession = () => {
  console.log("Getting stripe portal session")
  userStore.getStripePortalSession().then(data => {
    console.log("Stripe portal session fetched", data)
    window.location.href = data.url
  })
}

</script>

<template>
  <div>
    <div class="p-4">
      <div class="p-8 mb-96 text-center bg-white w-fit mx-auto rounded-2xl shadow-xl mt-8 min-w-[60dvw]">
        <h1 class="text-4xl font-bold">{{ $t('common.billing') }}</h1>
        <div v-if="loading">
          Loading..
        </div>

        <!-- Free trial -->
        <div v-else-if="userStore.organization.plan == 'free'" class="mt-4 text-xl text-center">
          <div class="my-4 text-2xl font-medium"> {{ $t('common.thanks_for_trying') }}</div>
          <div class="max-w-xl mx-auto">{{ $t('common.you_have') }} <span class="text-red-500 font-medium">{{ daysFromNow(userStore.organization.trial_end_date) }} {{ $t('common.days_left') }} </span> {{ $t('common.in_your_free_trial') }}. {{ $t('common.upgrade_by') }} {{ dmyyyy(userStore.organization.trial_end_date) }} {{ $t('common.to_keep_using_mindstamp') }}.</div>
          <div class="my-4"> 
            <Button :label="$t('common.upgrade_plan')" size="large" severity="success" class="mx-1" :loading="busy"/>
            <Button :label="$t('common.talk_to_sales')" size="large" class="mx-1" :loading="busy"/>
          </div>
          <div class="my-4 text-sm text-gray-500"> {{ $t('common.wont_be_billed') }}</div>
        </div>
        <div v-else>
          <div class="my-2"> {{ $t('common.thanks_for_customer') }}</div>

          <!-- Will cancel -->
          <div v-if="userStore.billing.cancel_at" class="max-w-2xl mx-auto mb-6">
            <div class="mb-2">
              <div class="mb-2 text-red-500 font-bold text-xl">{{ $t('common.subscription_will_cancel') }} {{ dmyyyy(userStore.billing.cancel_at) }}.</div>
              <Button @click="keepSubscription" class="w-full my-2" severity="success" size="large" :label="$t('common.keep_my_subscription')" :loading="busy"   />
              <div class="my-2 text-sm text-gray-500">{{ $t('common.videos_will_remain') }}</div>
            </div>
          </div>

          <!-- Paused -->
          <div v-else-if="userStore.organization.is_paused" class="max-w-lg mx-auto mb-6">
            <div class="mb-2">
              <div class="mb-2 text-orange-500 font-bold text-xl">{{ $t('common.subscription_paused') }} {{ $t('common.for') }} {{ formatCents(userStore.billing.amount) }} {{ $t('common.per') }} {{ $t('common.month') }}.</div>
              <Button @click="resumeSubscription" class="w-full my-2" severity="success" size="large" :label="$t('common.resume_subscription')" :loading="busy"/>
              <Button class="w-full my-2" size="large" :loading="busy" >
                <router-link to="/support">{{ $t('common.get_support') }}</router-link>
              </Button>
              <Button class="w-full mt-4" severity="danger" size="small" text :loading="busy" >
                <router-link to="/account/cancel">{{ $t('common.cancel_subscription') }}</router-link>
              </Button>
            </div>
          </div>
          
          <!-- Past due -->
          <div v-else-if="userStore.billing.status == 'past_due'" class="max-w-lg mx-auto">
            <div class="mb-2">
              <div v-if="userStore.billing.invoice" class="mb-2 text-red-500 font-bold text-xl">{{ $t('common.past_due_warning') }}</div>
              <Button class="w-full my-2" severity="success" size="large" :loading="busy"> <a :href="userStore.billing.invoice.hosted_invoice_url" target="_blank" class="text-white font-bold text-2xl">{{ $t('common.pay_invoice') }}</a></Button>
              <!-- <Button class="w-full my-2" severity="secondary" size="large" > <router-link to="/support">{{ $t('common.get_support') }}</router-link></Button> -->
            </div>
          </div>

          <!-- Unpaid -->
          <div v-else-if="userStore.billing.status == 'unpaid'" class="max-w-lg mx-auto">
            <div class="mb-2">
              <div v-if="userStore.billing.invoice" class="mb-2 text-red-500 font-bold text-xl">{{ $t('common.unpaid_warning') }}</div>
              <Button @click="openPastDueModal" class="w-full my-2" severity="success" size="large" :loading="busy" > <a :href="userStore.billing.invoice.hosted_invoice_url" target="_blank">{{ $t('common.pay_invoice') }}</a></Button>
            </div>
          </div>

          <table v-if="!userStore.organization.is_paused" class="w-full max-w-lg mx-auto mt-4">
            <tr>
              <td class="text-right">{{ $t('common.status') }}</td>
              <td>
                <span v-if="userStore.billing.status == 'active'" class="text-green-600 font-bold">{{ $t('common.active') }}</span>
                <span v-else-if="userStore.billing.status == 'past_due'" class="text-red-500 font-bold">{{ $t('common.past_due') }}</span>
                <span v-else-if="userStore.billing.status == 'unpaid'" class="text-red-500 font-bold">{{ $t('common.unpaid') }}</span>
                <span v-else-if="userStore.billing.status == 'canceled'" class="text-red-500">{{ $t('common.canceled') }}</span>
              </td>
            </tr>
            <tr>
              <td class="text-right">{{ $t('common.start_date') }}</td>
              <td>{{ new Date(userStore.billing.created * 1000).toLocaleDateString() }}</td>
            </tr>
            <tr>
              <td class="text-right">{{ $t('common.next_billing') }}</td>
              <td>{{ userStore.billing.cancel_at ? `${t('common.none')}` : new Date(userStore.billing.current_period_end * 1000).toLocaleDateString() }}</td>
            </tr>
            <tr>
              <td class="text-right">{{ $t('common.period') }}</td>
              <td>{{ userStore.billing.period == 'month' ? $t('common.monthly') : userStore.billing.period == 'year' ?
                $t('common.annual') : $t('common.other') }}</td>
            </tr>
            <tr>
              <td class="text-right">{{ $t('common.amount') }}</td>
              <td>{{ formatCents(userStore.billing.amount) }}</td>
            </tr>
            <tr v-if="userStore.billing.discount_valid && userStore.billing.discount_name">
              <td class="text-right">{{ $t('common.discount') }}</td>
              <td>
                {{ userStore.billing.discount_name }}
                <span v-if="userStore.billing.discount_percent">({{ userStore.billing.discount_percent }}%)</span>
                <span v-if="userStore.billing.discount_amount">({{ formatCents(userStore.billing.discount_amount) }})</span>
              </td>
            </tr>
          </table>

          <div v-if="userStore.billing.status == 'active' && !userStore.billing.cancel_at && !userStore.organization.is_paused" class="my-6 max-w-md mx-auto">
            <div class="mb-2"><Button @click="getStripePortalSession" :label="$t('common.upgrade_plan')" class="w-full" size="large" severity="success" :loading="busy"/></div>
            <div class="mb-2"><Button :label="$t('common.manage_invoices_and_payments')" class="w-full" size="large" :loading="busy"/></div>
            <div class="mb-2"><Button :label="$t('common.get_support')" class="w-full" size="large" :loading="busy"/></div>
            <div class="mb-2"><Button :label="$t('common.pause_or_cancel')" class="w-full" severity="danger" text :loading="busy">
              <router-link to="/account/pause">{{ $t('common.pause_or_cancel') }}</router-link>
            </Button></div>
            <!-- <div class="mb-2"><Button :label="$t('common.admin_cancel')" class="w-full" @click="cancelSubscription" severity="danger" :loading="busy"/></div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
td {
  @apply p-2;
  @apply border border-gray-200;
}
</style>
