<script setup>

const props = defineProps({
  value: {
    type: Number,
    required: true
  },
  duration: {
    type: Number,
    required: false
  },
  min: {
    type: Number,
    required: false
  },
  max: {
    type: Number,
    required: false
  },
  deci: {
    type: Boolean,
    required: false,
    default: true
  },
  disabled: {
    type: Boolean,
    required: false,
    default: false
  },
  placeholder: {
    type: String,
    required: false,
  }
});

import Select from 'primevue/select'
import { ref } from 'vue'
import { TimeFormatter } from '@/front/composables/TimeFormatter';
const { formatSeconds } = TimeFormatter() 
import { useI18n } from 'vue-i18n'
const { t } = useI18n()
import Axios from 'axios';


const emit = defineEmits(['update'])

const modelValue = ref(props.value)
const options = ref([])

const minVal = props.min || 0

onMounted(() => {
  // If decimal and max is greater than 600, get hours JSON, else compute options
  if (props.deci && maxVal.value > 300) {
    Axios.get(window.location.origin + '/h1.json').then((response) => {
      options.value = JSON.parse(response.data)
      if (maxVal.value > 3600) {
        Axios.get(window.location.origin + '/h2.json').then((response) => {
          options.value = options.value.concat(JSON.parse(response.data))
          if (maxVal.value > 7200) {
            Axios.get(window.location.origin + '/h34.json').then((response) => {
              options.value = options.value.concat(JSON.parse(response.data))
            })
          }
        })
      }
    })
  } else {
    console.log("Setting options for " + maxVal.value)
    setOptions()
  }
})



const scrollOptions = {
  itemSize: 20,
  maxVisibleItems: 1000,
  minBufferPx: 1000,
  minVisibleItems: 400,
}

const updateValue = (value) => {
  console.log("updateValue", value)
  modelValue.value = value.value
  emit('update', value.value)
}


const maxVal = computed(() => {
  return props.max || props.duration || 600
})

const setOptions = () => {
  if (isNaN(Number(maxVal.value))) {
    console.log("maxVal is not finite")
    return []
  }
  options.value = []
  if (props.deci) {
    for (let i = minVal; i <= Math.floor(maxVal.value) * 10; i++) {
      const val = Number((i / 10).toFixed(1))
      options.value.push({ t: formatSeconds(val), v: val })
    }
  } else {
    for (let i = minVal; i <= Math.floor(maxVal.value); i++) {
      options.value.push({ t: formatSeconds(i), v: i })
    }
  }
}


setOptions()

watch(() => props.value, (newValue) => {
  modelValue.value = newValue;
});

</script>

<template>
  <div>
    <Select v-model="modelValue" :options="options" optionLabel="t" optionValue="v"
      :virtualScrollerOptions="scrollOptions" filter dropdown resetFilterOnHide autoFilterFocus autoOptionFocus
      @change="updateValue" checkmark class="w-full" scrollHeight="75vh" :disabled="disabled" :placeholder="props.placeholder || t('common.choose_time')"/>
  </div>
</template>
