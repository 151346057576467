import { defineStore } from 'pinia'
import { AnnStore } from '@/front/stores/ann_store.js';

export const PlayerStore = defineStore('player', {
  state: () => {
    return {
      player: null,
      decitime: 0.0,
      fulltime: 0,
      playerState: {},
      screenToVideoRatio: 1.0,
      layerStyle: {
        top: 0,
        left: 0,
        height: '100%',
        width: '100%',
      },
      layerDimensions: {
        top: 0,
        left: 0,
        height: 100,
        width: 100,
      },
      videoElement: null,
      autoplayUnmuted: true,
      activeSrc: null,
      activeTracks: []
    }
  },

  actions: {
    async handleTimeUpdate(time) {
      const annStore = AnnStore();
      if (annStore.isEditing) {
        this.videoElement.pause()
      }

      // console.log("PlayerStore handleTimeUpdate ", time)
      const currentDecitime = Math.round(time * 10) / 10;

      // Check if the decitime has changed since the last update
      if (currentDecitime !== this.decitime) {
        // Decitime has changed, process the interaction map
        // console.log("PlayerStore handleTimeUpdate ", currentDecitime)
        annStore.updateActive(currentDecitime);

        // Update the last decitime to the current one
        this.decitime = currentDecitime;
        // console.log("\n🕔", this.decitime)
      }
    },
    setPlayerState(state) {
      // console.log("PlayerStore setPlayerState", state)
      this.playerState = state
      this.videoElement = document.querySelector('#media-provider video')
      this.updateRatios()
    },
    toggleMute(muted) {
      this.videoElement.muted = muted
      // this.videoElement.volume = muted ? 0.0 : 1.0
    },
    seek(time) {
      this.videoElement.currentTime = time
    },
    togglePlayer() {
      console.log("PlayerStore togglePlayer")
      if (!this.autoplayUnmuted) {
        this.toggleMute(false)
        this.autoplayUnmuted = true
        return
      }
      const annStore = AnnStore();
      if (this.playerState.seeking) {
        return
      }
      if (annStore.isEditing) {
        this.videoElement.pause()
        return
      }
      if (this.playerState.paused) {
        this.playerState.paused = false
        this.videoElement.play()
      } else {
        this.playerState.paused = true
        this.videoElement.pause()
      }
    },
    play() {
      this.videoElement.play()
    },
    pause() {
      this.videoElement.pause()
    },
    updateRatios() {
      if (this.videoElement) {
        // Container
        const containerWidth = this.playerState.width
        const containerHeight = this.playerState.height
        const containerRatio = containerWidth / containerHeight
        // Video
        const videoWidth = this.videoElement.videoWidth
        const videoHeight = this.videoElement.videoHeight
        const videoRatio = videoWidth / videoHeight
        // Ratio between container and video
        this.screenToVideoRatio = (containerRatio / videoRatio)
        let width = 100
        let height = 100
        let left = 0
        let top = 0
        if (containerRatio > videoRatio) {
          width = containerHeight * videoRatio
          height = containerHeight
        } else {
          width = containerWidth
          height = containerWidth / videoRatio
        }
        left = (containerWidth - width) / 2
        top = Math.max(0, (containerHeight - height) / 2)
        this.layerStyle = {
          width: `${width}px`,
          height: `${height}px`,
          left: `${left}px`,
          top: `${top}px`,
        }
        this.layerDimensions = {
          top: top,
          left: left,
          height: height,
          width: width,
        }
      }
    }
  },
})