import { defineStore } from 'pinia'

export const UploadStore = defineStore('uploads', {
  state: () => {
    return {
      uploadProgress: 0,
      isUploading: false,
      videoToReplace: {
        id: 'dJuGtXdPqmXm',
        title: 'Test Video',
      },
      uploadFile: null,
      uploadSource: {
        src: null,
        type: null,
        temporary: true,
      },
      uploadSettings: {
        title: '',
        key: '',
        type_of: '',
        size: 0,
        duration: 0,
        transcode: true,
        transcribe: true,
        vimeo_id: null,
        wistia_id: null,
        youtube_id: null,
        loom_id: null,
        heygen_id: null,
        synthesia_id: null,
        kaltura_id: null,
        panopto_id: null,
        storyboard_url: null,
        storyboard_frame_width: null,
        storyboard_frame_height: null,
        storyboard_frame_count: null,
        storyboard_ratio: null,
        aspect_ratio: 1.77,
        backup_url: null,
      },
      pagination: {page: 1},
      fetchingSynthesiaVideos: false,
      synthesia_videos: [],
    }
  },

  actions: {

    getArgs() {
      return { 
        key: this.uploadSettings.key, 
        name: this.uploadFile?.name, 
        size: this.uploadFile?.size,
        title: this.uploadSettings.title,
        type_of: this.uploadSettings.type_of,
        transcode: this.uploadSettings.transcode,
        transcribe: this.uploadSettings.transcribe,
        vimeo_id: this.uploadSettings.vimeo_id,
        wistia_id: this.uploadSettings.wistia_id,
        duration: this.uploadSettings.duration,
        // youtube_id: this.uploadSettings.youtube_id,
        loom_id: this.uploadSettings.loom_id,
        heygen_id: this.uploadSettings.heygen_id,
        synthesia_id: this.uploadSettings.synthesia_id,
        // kaltura_id: this.uploadSettings.kaltura_id,
        panopto_id: this.uploadSettings.panopto_id,
        storyboard_url: this.uploadSettings.storyboard_url,
        storyboard_frame_width: this.uploadSettings.storyboard_frame_width,
        storyboard_frame_height: this.uploadSettings.storyboard_frame_height,
        storyboard_frame_count: this.uploadSettings.storyboard_frame_count,
        backup_url: this.uploadSettings.backup_url,
        aspect_ratio: this.uploadSettings.aspect_ratio,
      }
    },

    async replaceVideo() {
      const args = this.getArgs()
      console.log("🌜 UploadStore replaceVideo", args)
      return this.Api.post(`/videos/${this.videoToReplace.id}/replace`, args).then(response => {  
        this.setUploadSource()
        console.log("UploadSource", this.uploadSource)
        return response.data.video
      })
    },

    async createVideo() {
      const args = this.getArgs()
      console.log("🌜 UploadStore createVideo", args)
      return this.Api.post('/videos', args).then(response => {  
        this.video = response.data.video; 
        this.setUploadSource()
        console.log("UploadSource", this.uploadSource)
      }).finally(() => {
        this.loading = false
      })
    },

    setUploadSource() {
      console.log("SetUploadSource", this.uploadFile)
      if (this.uploadFile) {
        this.uploadSource = {
          src: URL.createObjectURL(this.uploadFile),
          type: this.uploadFile.type,
          temporary: true
        } 
      } else {
        this.uploadSource = false
      }
    },

    async checkLink(link) {
      return this.Api.get(`/links/check_link?link=${link}`).then(response => {
        return response.data.valid
      })
    },

    async getWistiaAssets(wistia_id) {
      return this.Api.get(`/links/get_wistia_assets?id=${wistia_id}`).then(response => {
        return response
      })
    },

    async getLoomMP4(loom_id) {
      return this.Api.get(`/links/get_loom_mp4?id=${loom_id}`).then(response => {
        return response
      })
    },

    async getPanoptoMP4(url) {
      console.log("🌜 UploadStore getPanoptoMP4", url)
      return this.Api.get(`/links/get_panopto_mp4?url=${url}`).then(response => {
        return response
      })
    },

    async getKalturaMP4(url) {
      return this.Api.get(`/links/get_kaltura_mp4?url=${url}`).then(response => {
        return response
      })
    },

    async getSynthesiaVideos(token='') {
      this.fetchingSynthesiaVideos = true
      return this.Api.get(`/synthesia_videos?token=${token}`).then(response => {
        console.log("🌜 UploadStore getSynthesiaVideos", response)
        if (response.data.connected) {
          this.synthesia_videos = response.data.videos
        }
      }).catch(error => {
        console.log("🌜 UploadStore getSynthesiaVideos", error)
      }).finally(() => {
        this.fetchingSynthesiaVideos = false
      })
    },
  }
})