<script setup>
import { SeriesStore } from "@/front/stores/series_store.js";
const seriesStore = SeriesStore();
const location = useRoute();
const router = useRouter();
import { useToast } from 'primevue/usetoast';
const toast = useToast()
import { useI18n } from 'vue-i18n'
const { t } = useI18n({});
import VideoSelector from '@/front/components/VideoSelector.vue';
import Tabs from 'primevue/tabs';
import TabList from 'primevue/tablist';
import Tab from 'primevue/tab';
import TabPanel from 'primevue/tabpanel';
import Dialog from 'primevue/dialog';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Button from 'primevue/button';
import BulkEdit from '@/front/components/BulkEdit.vue';
import SeriesSharing from '@/front/components/SeriesSharing.vue';
import ToggleSwitch from 'primevue/toggleswitch';
import SeriesSettings from '@/front/components/SeriesSettings.vue';
import PaywallForm from '@/front/components/PaywallForm.vue';
import Crumbs from '@/front/components/Crumbs.vue';
import VideoCard from '@/front/components/VideoCard.vue';

const activeTab = ref('paywall')
const videoSelectDialog = ref(false)

const videoIds = computed(() => {
  return seriesStore.series.videos.map(video => video.id)
})

const saveVideos = (videos) => {
  console.log("💾 SeriesShow saveVideos", videos)
  seriesStore.updateVideos({ videos: videos }).then(() => {
    videoSelectDialog.value = false
  })
}

const deleteGroup = () => {
  seriesStore.destroy(seriesStore.series.id).then(() => {
    router.push({ name: 'index_series_path' })
  })
}



const openVideo = (video) => {
  console.log("💾 GroupShow openVideo", video)
  if (seriesStore.series.manage) {
    router.push({ name: 'edit_video_path', params: { id: video.id } })
  } else {
    router.push({ name: 'playback_path', params: { id: video.id } })
  }
}

const updateCollaboration = (user) => {
  console.log("💾 SeriesManage updateCollaboration", user, user.collaboration + " --> " + !user.collaboration)
  seriesStore.updateCollaboration({ series_id: seriesStore.series.id, user_id: user.id, collaboration: !user.collaboration })
}

</script>

<template>
  <div v-if="seriesStore.series.id">
    <!-- TODO breadcrumbs -->
    <!-- {{  seriesStore.series }} -->

    <h1 class="text-4xl font-bold mb-4">{{ seriesStore.series.name }}</h1>
    <Tabs :value="activeTab" class="mb-4 w-full mx-auto">
      <TabList class="mb-6">
        <Tab value="videos"> {{ $t('common.videos') }}</Tab>
        <Tab value="reporting"> {{ $t('common.reporting') }}</Tab>
        <Tab value="sharing"> {{ $t('common.sharing') }}</Tab>
        <Tab value="settings"> {{ $t('common.settings') }}</Tab>
        <Tab value="collaboration"> {{ $t('common.collaboration') }}</Tab>
        <Tab value="paywall"> {{ $t('common.paywall') }}</Tab>
        <Tab value="bulk"> {{ $t('common.bulk_edit') }}</Tab>
      </TabList>

      <!-- Videos -->
      <TabPanel value="videos">
        <!-- TODO only show for admins -->
        <Button v-if="seriesStore.series.manage" @click="videoSelectDialog = true" size="large" class="mb-4">{{
          $t('common.edit_videos') }}</Button>
        <div class="grid grid-cols-4 gap-4">
          <VideoCard v-for="video in seriesStore.series.videos" :video="video" :key="video.id"
            class="mb-8 cursor-pointer" @click="openVideo(video)" />
        </div>
      </TabPanel>

      <!-- Collaboration -->
      <TabPanel value="collaboration">
        {{ seriesStore.series.people }}
        <!-- Members -->
        <h2 class="text-xl font-bold mb-6">{{  $t('common.collaboration_choose_members')  }}</h2>
        <DataTable :value="seriesStore.series.people">
          <Column  :header="$t('common.name')">
            <template #body="slotProps"> {{ slotProps.data.full_name }} </template>
          </Column>
          <Column field="email" :header="$t('common.email')"></Column>
          <Column :header="$t('common.access')">
            <template #body="slotProps">
              <ToggleSwitch v-model="slotProps.data.collaboration" @input="updateCollaboration(slotProps.data)"/>
            </template>
          </Column>
        </DataTable>
      </TabPanel>

      <!-- Paywall -->
      <TabPanel value="paywall">
        <PaywallForm :series_id="seriesStore.series.id" />
      </TabPanel>


      <!-- Reporting -->
      <TabPanel value="reporting">
        <div>Reporting</div>
        Insights Component
        <Button>
          <router-link :to="`/reporting/views?q[series_id]=${seriesStore.series.id}`">
            {{ $t('common.views') }}
          </router-link>
        </Button>
        <Button>
          <router-link :to="`/reporting/interactions?q[series_id]=${seriesStore.series.id}`">
            {{ $t('common.interactions') }}
          </router-link>
        </Button>
        <Button>
          <router-link :to="`/reporting/viewers?q[series_id]=${seriesStore.series.id}`">
            {{ $t('common.viewers') }}
          </router-link>
        </Button>
        <!-- <TabReporting /> -->
      </TabPanel>

      <!-- Settings -->
      <TabPanel value="settings">
        <SeriesSettings />
      </TabPanel>

      <!-- Sharing -->
      <TabPanel value="sharing">
        <SeriesSharing />
      </TabPanel>

      <!-- Bulk Edit -->
      <TabPanel value="bulk">
        <div>Bulk Edit</div>
        <BulkEdit :video_ids="videoIds" />
      </TabPanel>

      <!-- Edit -->
      <TabPanel value="edit">
        <div>Settings</div>
        <button @click="deleteGroup">Delete</button>
      </TabPanel>
    </Tabs>
  </div>

  <Dialog v-model:visible="videoSelectDialog" :header="$t('common.select_videos')" :modal="true" :closable="false"
    :closeOnEscape="false" :closeOnBackdrop="false">
    <VideoSelector :videoIds="videoIds" @save="saveVideos" />
  </Dialog>

</template>
