<script setup>
import { UserStore } from '@/front/stores/user_store.js'
const userStore = UserStore();
import { SeriesStore } from '@/front/stores/series_store.js'
const seriesStore = SeriesStore();
import SeriesShow from '@/front/components/SeriesShow.vue';
import SeriesManage from '@/front/components/SeriesManage.vue';
import Crumbs from '@/front/components/Crumbs.vue';
import { useRoute } from 'vue-router'
const router = useRouter()
const location = useRoute()
import { useToast } from 'primevue/usetoast';
const toast = useToast();
import { useI18n } from 'vue-i18n'
const { t } = useI18n({});

onMounted(() => {

seriesStore.show(location.params.id).then(() => {
  console.log("💾 SeriesShow onMounted", seriesStore.series)
}).catch((error) => {
  console.log("💾 SeriesShow onMounted error", error)
  if (error.response.status === 403) {
    toast.add({ title: t('common.error'), summary: t('common.user_not_authorized'), severity: 'error' })
    router.push({ name: 'index_series_path' })
  }
})
});
const crumbs = computed(() => {
  return [
    { label: t('common.series'), to: '/series' },
    { label: seriesStore.series.name, to: `/series/${seriesStore.series.id}` }
  ]
})

</script>

<template>
  <Crumbs :items="crumbs" />
  <div>
    <div class="p-4">
      <div class="p-8 mb-96 text-center bg-white w-fit mx-auto rounded-2xl shadow-xl mt-8 min-w-[60dvw]">
        <SeriesManage v-if="seriesStore.series.manage" />
        <SeriesShow v-else-if="seriesStore.series.id" />
      </div>
    </div>
  </div>
</template>
