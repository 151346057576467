import { ref } from "vue";

export function UxComposer() {

  function focus(id, delay=10) {
    setTimeout(() => {
      // console.log("🔎 focus", id, document.getElementById(id))
      document.getElementById(id)?.focus()
    }, delay)
  }

  function focusAndSelect(id, delay=10) {
    focus(id, delay)
    setTimeout(() => {
      document.getElementById(id).select()
      // console.log("🔎 focusAndSelect", id, document.getElementById(id))
    }, delay)
  }

  function focusAndHighlight(id, delay=10) {
    focus(id, delay)
    setTimeout(() => {
      document.getElementById(id).classList.add('highlight')
      setTimeout(() => {
        document.getElementById(id).classList.remove('highlight')
      }, 1000)
    }, delay)
  }

  function toggleAutocomplete(id, on, delay=10) {
    setTimeout(() => {
      // console.log("🌜 toggleAutocomplete", id, on, document.getElementById(id))
      document.getElementById(id) && (document.getElementById(id).autocomplete = on ? "on" : "off")
    }, delay)
  }
  function toggleAutocompleteByElement(element, on, delay=10) {
    setTimeout(() => {
      element.autocomplete = on ? "on" : "off"
    }, delay)
  }

  return { focus, focusAndSelect, focusAndHighlight, toggleAutocomplete, toggleAutocompleteByElement };
}