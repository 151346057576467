// TODO uppy extra packages
import { createApp } from 'vue';
const app = createApp(Main);

import Router from '@/front/routes.js';
import Main from '@/front/views/shared/Main.vue';
import Axios from "axios";

// API + Axios wrapper
import { createApi } from '@/plugins/api';
const Api = createApi({ handler: Axios, namespace: '' });

// Pinia + Axios setup
import { createPinia } from 'pinia';
const Pinia = createPinia();
Pinia.use(({ store }) => { store.Api = Api })

import PrimeVue from 'primevue/config';
import Lara from '@/front/presets/lara';      //import preset
import VuePapaParse from 'vue-papa-parse'
import Tooltip from 'primevue/tooltip';
import ConfirmationService from 'primevue/confirmationservice';
import ToastService from 'primevue/toastservice';
import Toast from 'primevue/toast';
import ConfirmDialog from 'primevue/confirmdialog';




// I18n loader
import { createI18n } from 'vue-i18n';
const I18n = createI18n({ locale: 'current', messages: translations, legacy: false });

app.use(Router)
  .use(Pinia)
  .use(I18n)
  .use(Api)
  .use(VuePapaParse)


app.use(PrimeVue, {
  pt: Lara,
  unstyled: true,
  // apply preset
}).use(ConfirmationService)
.use(ToastService);

app.component('Toast', Toast);
app.component('ConfirmDialog', ConfirmDialog);
app.directive('tooltip', Tooltip);

app.mount('#app')