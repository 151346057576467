<script setup>
import { UserStore } from '@/front/stores/user_store.js'
const userStore = UserStore();
import { useRoute } from 'vue-router'
const route = useRoute()
import { useI18n } from 'vue-i18n'
const { t } = useI18n({});
import { AssetStore } from '@/front/stores/asset_store.js'
const assetStore = AssetStore();

import { useToast } from 'primevue/usetoast';
const toast = useToast();

import Papa from "papaparse";

import Dialog from 'primevue/dialog';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';

import { StringValidator } from '@/front/composables/StringValidator.js';
const { isValidEmail } = StringValidator();


const validEntry = computed(() => {
  return !!newItem.value.name && isValidEmail(newItem.value.email)
})


import { useConfirm } from "primevue/useconfirm";
import { PlusSignCircleIcon, Upload02Icon } from 'hugeicons-vue';
const confirm = useConfirm();

onMounted(() => {
  assetStore.fetchList(route.params.id).then(()=> {
    if (!assetStore.currentList.items.length) {
      addPersonForm.value = true
    }
  })
})

const newItem = ref({
  name: '',
  email: '',
  passcode: ''
})


const addItem = () => {
  assetStore.addListItem(newItem.value).then(()=> {
    assetStore.fetchList(route.params.id)
    newItem.value = {
      name: '',
      email: '',
      passcode: ''
    }
    toast.add({ severity: 'success', summary: t('common.success'), detail: t('common.entry_added'), life: 3000 });
  }).catch((error) => {
    toast.add({ severity: 'error', summary: t('common.error'), detail: error, life: 3000 });
    console.log(error)
  })
}

const removeItem = (item) => {
  console.log("removeItem", item)
  assetStore.removeListItem(item.id).then(()=> {
    assetStore.currentList.items = assetStore.currentList.items.filter(i => i.id !== item.id)
  }).catch((error) => {
    toast.add({ severity: 'error', summary: t('common.error'), detail: error, life: 3000 });
    console.log(error)
  })
}



const deleteList = (list) => {
  console.log(list)
  confirm.require({
    message: 'Are you sure you want to cancel? All changes will be lost.',
    header: 'Confirmation',
    icon: 'pi pi-exclamation-triangle',
    rejectProps: {
      label: 'Cancel',
      severity: 'secondary',
      outlined: true
    },
    acceptProps: {
      label: 'Discard Changes',
      severity: 'danger',
    },
    accept: () => {
      toast.add({ severity: 'info', summary: 'Confirmed', detail: 'You have accepted', life: 3000 });
      stop()
    },
    reject: () => {
      toast.add({ severity: 'error', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
    }
  });
}

const editListModal = ref(false)

const listName = ref('')
const listDescription = ref('')
const startEditList = () => {
  listName.value = assetStore.currentList.name
  listDescription.value = assetStore.currentList.description
  editListModal.value = true
}

const saveList = () => {
  assetStore.updateList(assetStore.currentList.id, { name: listName.value, description: listDescription.value }).then(() => {
    toast.add({ severity: 'success', summary: t('common.success'), detail: t('common.entry_added'), life: 3000 });
    assetStore.currentList.name = listName.value
    assetStore.currentList.description = listDescription.value
    editListModal.value = false
  }).catch((error) => {
    toast.add({ severity: 'error', summary: t('common.error'), detail: error, life: 3000 });
    console.log(error)
  })
}

const uploadCsvModal = ref(false)
const addPersonForm = ref(false)
const csvEntries = ref([])
const csvFile = ref(null)

const fileAdded = (event) => {
  console.log("File Added", event)
  csvFile.value = event.target.files[0]
  Papa.parse(csvFile.value, {
    complete: (results) => {
      console.log("Results", results)
      results.data.forEach(row => {
        if (row[0] && isValidEmail(row[0])) {
          csvEntries.value.push({
            email: row[0],
          name: row[1],
            passcode: row[2]
          })
        }
      })
      if (!csvEntries.value.length) {
        toast.add({ severity: 'error', summary: t('common.error'), detail: t('common.no_valid_emails_please_try_again'), life: 3000 });
        event.target.value = null
      }
    }
  })
}

const cancelCsv = () => {
  csvEntries.value = []
  csvFile.value = null
  uploadCsvModal.value = false
}

const doBulkAdd = () => {
  assetStore.addListItems(csvEntries.value).then((response) => {
    toast.add({ severity: 'success', summary: t('common.success'), detail: csvEntries.value.length + ' ' + t('common.people') + ' ' + t('common.added'), life: 3000 });
    assetStore.fetchList(route.params.id)
    cancelCsv()
  }).catch((error) => {
    toast.add({ severity: 'error', summary: t('common.error'), detail: error, life: 3000 });
    console.log(error)
  })
}

const search = ref('')
const searchEntries = computed(() => {
  return assetStore.currentList.items.filter(item => item.email.toLowerCase().includes(search.value.toLowerCase()) || item.name.toLowerCase().includes(search.value.toLowerCase()))
})


</script>

<template>
      <!-- TODO breadcrumbs -->

  <div v-if="assetStore.currentList">
    <div class="p-4">
      <div class="p-8 mb-96 text-center bg-white w-fit mx-auto rounded-2xl shadow-xl mt-8 min-w-[60dvw]">
        <div class="mb-4 cursor-pointer" @click="startEditList" v-tooltip.top="$t('common.edit')">
          <h1 class="text-4xl font-bold">{{ assetStore.currentList.name }} </h1>
          <p class="text-lg">
            {{ assetStore.currentList.description }}
          </p>
        </div>
        <div>
          <Button v-if="!addPersonForm" @click="addPersonForm = true" class="mr-1" size="small">
            <PlusSignCircleIcon class="w-4 h-4 mr-1" />  {{ $t('common.add_person') }}
          </Button>
          <Button @click="uploadCsvModal = true" class="ml-1" size="small">
            <Upload02Icon class="w-4 h-4 mr-1" />  {{ $t('common.upload_csv') }}
          </Button>
        </div>
        <div v-if="addPersonForm" class="grid grid-cols-11 gap-2 w-full my-4">
          <div class="col-span-4">
            <InputText v-model="newItem.email" :placeholder="$t('common.email')" class="w-full"/>
          </div>
          <div class="col-span-4">
            <InputText v-model="newItem.name" :placeholder="$t('common.name')" class="w-full"/>
          </div>
          <div class="col-span-2">
            <InputText v-model="newItem.passcode" :placeholder="$t('common.passcode_optional')" class="w-full"/>
          </div>
          <div class="col-span-1 text-right" >
            <Button @click="addItem" :label="$t('common.save')" :disabled="!validEntry"/>
          </div>
        </div>

        <div v-if="assetStore.currentList.items.length" class="my-4"> <InputText v-model="search" :placeholder="$t('common.search') + ' ' + assetStore.currentList.items.length + ' ' + $t('common.people')" autocomplete="off" autofocus class="w-full" /></div>
        <DataTable :value="searchEntries" class="mt-4">
          <Column field="email" :header="$t('common.email')"></Column>
          <Column field="name" :header="$t('common.name')"></Column>
          <Column field="passcode" :header="$t('common.passcode')"></Column>
          <Column :header="$t('common.actions')"> 
            <template #body="slotProps">
              <Button @click="removeItem(slotProps.data)" :label="$t('common.delete')" size="small" text severity="danger"/>
            </template>
          </Column>
        </DataTable>

      </div>
    </div>
    <Dialog v-model:visible="editListModal" modal :header="$t('common.edit')" class="w-4/5 max-w-xl">
      <div class="grid grid-cols-1 gap-4">
        <div>
          <p class="font-medium">{{ $t('common.description') }}</p>
          <InputText v-model="listDescription" :placeholder="$t('common.description')" class="w-full"/>
        </div>
        <div>
          <p class="font-medium">{{ $t('common.name') }}</p>
          <InputText v-model="listName" :placeholder="$t('common.name')" class="w-full"/>
        </div>
        <div class="text-center">
          <Button @click="saveList" :label="$t('common.save')" />
        </div>
        <div class="text-center">
          <Button @click="editListModal = false" :label="$t('common.cancel')" severity="secondary" text/>
        </div>
      </div>
    </Dialog>
    <Dialog v-model:visible="uploadCsvModal" modal :header="$t('common.upload_csv')" class="w-4/5 max-w-xl">
      <div class="grid grid-cols-1 gap-4">
        <div v-if="!csvEntries.length">
          <input 
            type="file" 
            accept=".csv"
            @change="fileAdded"
            class="block w-auto text-sm text-gray-500
              file:mr-4 file:py-2 file:px-4
              file:rounded-md file:border-0
              file:text-sm file:font-semibold
              file:bg-primary-50 file:text-primary-700
              hover:file:bg-primary-100 mx-auto"
          />
        </div>
        <div v-if="csvEntries.length" class="max-h-[50dvh] overflow-y-auto text-sm">
          <DataTable :value="csvEntries" class="w-full text-sm">
            <Column field="email" :header="$t('common.email')" class="text-sm !p-1" ></Column>
            <Column field="name" :header="$t('common.name')" class="text-sm !p-1" ></Column>
            <Column field="passcode" :header="$t('common.passcode')" class="text-sm !p-1" ></Column>
          </DataTable>
        </div>
        <div class="text-center">
          <Button v-if="csvEntries.length" @click="doBulkAdd" :label="$t('common.add') + ' ' + csvEntries.length + ' ' + $t('common.people')" />
        </div>
        <div class="text-center">
          <Button @click="cancelCsv" :label="$t('common.cancel')" severity="secondary" text/>
        </div>
      </div>
    </Dialog>
  </div>
</template>
