<template>
  <div>
    {{ props.value }}
    <div class="grid grid-cols-4 gap-4">
      <div v-for="option in options" :key="option.value" class="text-center relative">
        <div
          @click="setTransition(option.value)" 
          class="w-full h-48 border-2 rounded-md border-black bg-gray-200 text-black text-center flex items-center justify-center overflow-hidden cursor-pointer" 
          :class="{ '!border-yellow-500 border-4' : option.value === props.value }">
          <Transition :name="option.value" tag="div" class="mb-10" style="--animate-duration: 1s;">
              <Button v-if="show"> {{$t('common.content')}} </Button>
          </Transition>
        <div class="text-2xl font-medium absolute bottom-0 w-full text-center flex items-center justify-center py-6">{{ option.text }}</div>

      </div>
      </div>
    </div>
  </div>  
</template>


<script setup>

const emit = defineEmits(['setTransition'])

const props = defineProps({
  value: {
    type: String,
    default: 'none'
  }
})
const show = ref(false)
import Button from 'primevue/button'

const setTransition = (transition) => {
  console.log('setTransition', transition)
  emit('setTransition', transition)
}

onMounted(() => {
  show.value = true
  setInterval(() => {
  show.value = !show.value
}, 2000)  
})

const options = [
  { text: 'Zoom', value: 'zoom'},
  { text: 'Zoom Down', value: 'zoomDown'},
  { text: 'Zoom Left', value: 'zoomLeft'},
  { text: 'Zoom Right', value: 'zoomRight'},
  { text: 'Zoom Up', value: 'zoomUp'},
  { text: 'Fade', value: 'fade'},
  { text: 'Fade Down', value: 'fadeDown'},
  { text: 'Fade Left', value: 'fadeLeft'},
  { text: 'Fade Right', value: 'fadeRight'},
  { text: 'Fade Up', value: 'fadeUp'},
  { text: 'Bounce', value: 'bounce'},
  { text: 'Bounce Down', value: 'bounceDown'},
  { text: 'Bounce Left', value: 'bounceLeft'},
  { text: 'Bounce Right', value: 'bounceRight'},
  { text: 'Bounce Up', value: 'bounceUp'},
  { text: 'Rotate', value: 'rotate'},
  { text: 'Slide Down', value: 'slideDown'},
  { text: 'Slide Left', value: 'slideLeft'},
  { text: 'Slide Right', value: 'slideRight'},
  { text: 'Slide Up', value: 'slideUp'},
  { text: 'None', value: 'none'},

]

</script>

<style scoped>  
  @import "@asika32764/vue-animate/dist/vue-animate.css";

</style>