<template>
  <div v-if="!store.loading"> 
    <Select v-model="store.design.language" :options="languageItems" @change="setTranslations(true); debouncedSave()" optionLabel="text" optionValue="text" placeholder="Select Language" class="w-full md:w-56" />
    <!-- Auto translate -->
    <div class="flex items-center mb-4">
        <Checkbox v-model="store.design.auto_translate" :binary="true" @update:modelValue="debouncedSave()" :label="$t('common.auto_translate')"/>
      <label for="default-checkbox" class="ms-2 text-sm font-medium text-gray-900">{{ $t('common.auto_translate') }}</label>
    </div>
    <div class="grid grid-cols-2">
      <div class="text-lg font-bold"> {{ $t('common.string') }}</div>
      <div class="text-lg font-bold"> {{ $t('common.translation') }}</div>
    </div>
    <div v-for="(item, key, index) in store.design" :key="index">
      <div class="grid grid-cols-2 flex items-center" v-if="key.includes('_string')">
        <div class="text-sm text-right mr-4 mb-2">{{  keys[key] }}</div>
        <InputText v-model="store.design[key]" @input="debouncedSave()" class="w-full mb-2"/>
      </div>
    </div>
  </div>

</template>

<script setup>
import { DesignStore } from "@/front/stores/design_store.js";
const store = DesignStore();

import { debounce } from "lodash";
import Select from "primevue/select";
import InputText from "primevue/inputtext";
import Checkbox from "primevue/checkbox";
import Papa from "papaparse";
import { CountryHelper } from "@/front/composables/CountryHelper.js";
const { getCountryList } = CountryHelper();

const languageItems = ref(getCountryList())

const translationsFile = ref({})

const stringsCsvUrl = "https://mindstamp-resources.s3.us-west-2.amazonaws.com/assets/strings_mar26_24.csv"

const debouncedSave = debounce(() => {
  console.log("Saving")
  store.update()
}, 3000)

onMounted(() => {
  // console.log("Url:", stringsCsvUrl)
  Papa.parse(stringsCsvUrl, {
    download: true,
    complete: (results) => {
        translationsFile.value = results.data;
        console.log("Store Language", store.design.language)
        if (!store.design.language) {
          store.design.language = "English"
          setTranslations(true)
        } else {
          setTranslations(false)
        }
    }
  })
})

const keys = ref({})



const setTranslations =(override = false) => {
  console.log("Setting Translations", translationsFile.value)
  var c = {}
  translationsFile.value.forEach((r)=> {
    if (r[0] == "English") {
      console.log("Found: " + r[0], r)
      c = r
      keys.value = c
    }
    
    if (r[0] == store.design.language) {
      // console.log("Found: " + r[0], r)
      store.design.language_code = r[1]
      store.design.correct_string = override ? r[2] : store.design.correct_string || r[2]
      store.design.incorrect_string = override ? r[3] : store.design.incorrect_string ||    r[3]
      store.design.your_answer_string = override ? r[4] : store.design.your_answer_string || r[4]
      store.design.correct_answer_string = override ? r[5] : store.design.correct_answer_string || r[5]
      store.design.continue_string = override ? r[6] : store.design.continue_string || r[6]
      store.design.skip_string = override ? r[7] : store.design.skip_string || r[7]
      store.design.cancel_string = override ? r[8] : store.design.cancel_string || r[8]
      store.design.question_string = override ? r[9] : store.design.question_string || r[9]
      store.design.of_string = override ? r[10] : store.design.of_string || r[10]
      store.design.done_string = override ? r[11] : store.design.done_string || r[11]
      store.design.close_string = override ? r[12] : store.design.close_string ||   r[12]
      store.design.chapters_string = override ? r[13] : store.design.chapters_string || r[13]
      store.design.interactions_string = override ? r[14] : store.design.interactions_string || r[14]
      store.design.search_string = override ? r[15] : store.design.search_string ||   r[15]
      store.design.add_comment_string = override ? r[16] : store.design.add_comment_string || r[16]
      store.design.transcripts_string = override ? r[17] : store.design.transcripts_string || r[17]
      store.design.name_string = override ? r[18] : store.design.name_string ||   r[18]
      store.design.email_string = override ? r[19] : store.design.email_string || r[19]
      store.design.phone_string = override ? r[20] : store.design.phone_string || r[20]
      store.design.custom_id_string = override ? r[21] : store.design.custom_id_string ||   r[21]
      store.design.resume_view_string = override ? r[22] : store.design.resume_view_string || r[22]
      store.design.resume_text_string = override ? r[23] : store.design.resume_text_string || r[23]
      store.design.restart_string = override ? r[24] : store.design.restart_string || r[24]
      store.design.try_again_string = override ? r[25] : store.design.try_again_string || r[25]
      store.design.play_string = override ? r[26] : store.design.play_string || r[26]
      store.design.pause_string = override ? r[27] : store.design.pause_string || r[27]
      store.design.mute_string = override ? r[28] : store.design.mute_string || r[28]
      store.design.unmute_string = override ? r[29] : store.design.unmute_string || r[29]
      store.design.color_string = override ? r[30] : store.design.color_string || r[30]
      store.design.draw_string = override ? r[31] : store.design.draw_string || r[31]
      store.design.text_string = override ? r[32] : store.design.text_string || r[32]
      store.design.line_string = override ? r[33] : store.design.line_string || r[33]
      store.design.pointer_string = override ? r[34] : store.design.pointer_string || r[34]
      store.design.circle_string = override ? r[35] : store.design.circle_string || r[35]
      store.design.arrow_string = override ? r[36] : store.design.arrow_string || r[36]
      store.design.hint_string = override ? r[37] : store.design.hint_string || r[37]
      store.design.rewind_string = override ? r[38] : store.design.rewind_string || r[38]
      store.design.reset_string = override ? r[39] : store.design.reset_string || r[39]
      store.design.exit_string = override ? r[40] : store.design.exit_string || r[40]
      store.design.tool_string = override ? r[41] : store.design.tool_string || r[41]
      store.design.show_resource_tray_string = override ? r[42] : store.design.show_resource_tray_string || r[42]
      store.design.leaving_string = override ? r[45] : store.design.leaving_string || r[45]
      store.design.show_cart_string = override ? r[43] : store.design.show_cart_string || r[43]
      store.design.checkout_string = override ? r[44] : store.design.checkout_string || r[44]
      store.design.show_resource_tray_string = override ? r[46] : store.design.show_resource_tray_string || r[46]
      store.design.payment_message_string = override ? r[47] : store.design.payment_message_string || r[47]
      store.design.buy_now_string = override ? r[48] : store.design.buy_now_string || r[48]
      store.design.help_string = override ? r[49] : store.design.help_string || r[49]
      store.design.have_purchased_string = override ? r[50] : store.design.have_purchased_string || r[50]
      store.design.choose_from_library_string = override ? r[51] : store.design.choose_from_library_string || r[51]
      store.design.upload_new_video_string = override ? r[52] : store.design.upload_new_video_string || r[52]
      store.design.record_new_video_string = override ? r[53] : store.design.record_new_video_string || r[53] 
      store.design.video_recording_not_supported_string = override ? r[54] : store.design.video_recording_not_supported_string || r[54] 
      store.design.recording_finished_string = override ? r[55] : store.design.recording_finished_string ||   r[55] 
      store.design.preview_not_supported_string = override ? r[56] : store.design.preview_not_supported_string || r[56] 
      store.design.save_video_string = override ? r[57] : store.design.save_video_string || r[57] 
      store.design.discard_string = override ? r[58] : store.design.discard_string || r[58] 
      store.design.recording_in_progress_string = override ? r[59] : store.design.recording_in_progress_string || r[59] 
      store.design.finish_recording_string = override ? r[60] : store.design.finish_recording_string || r[60] 
      store.design.start_recording_string = override ? r[61] : store.design.start_recording_string || r[61] 
      store.design.success_string = override ? r[62] : store.design.success_string || r[62] 
      store.design.video_clip_saved_string = override ? r[63] : store.design.video_clip_saved_string || r[63] 
      store.design.please_grant_string = override ? r[64] : store.design.please_grant_string || r[64] 
      store.design.continue_and_allow_string = override ? r[65] : store.design.continue_and_allow_string || r[65] 
      store.design.no_webcam_string = override ? r[66] : store.design.no_webcam_string ||   r[66] 
      store.design.no_microphone_string = override ? r[67] : store.design.no_microphone_string || r[67] 
      store.design.plug_in_devices_string = override ? r[68] : store.design.plug_in_devices_string || r[68] 
      store.design.yes_string = override ? r[69] : store.design.yes_string || r[69] 
      store.design.no_string = override ? r[70] : store.design.no_string || r[70] 
      store.design.add_clip_title_string = override ? r[71] : store.design.add_clip_title_string || r[71] 
      store.design.discard_video_string = override ? r[72] : store.design.discard_video_string || r[72] 
      store.design.add_audio_string = override ? r[73] : store.design.add_audio_string ||   r[73] 
      store.design.choose_existing_clip_string = override ? r[74] : store.design.choose_existing_clip_string || r[74] 
      store.design.record_voice_clip_string = override ? r[75] : store.design.record_voice_clip_string || r[75] 
      store.design.browser_cant_record_audio_string = override ? r[77] : store.design.browser_cant_record_audio_string || r[77] 
      store.design.choose_audio_clip_string = override ? r[78] : store.design.choose_audio_clip_string || r[78] 
      store.design.enter_link_string = override ? r[79] : store.design.enter_link_string || r[79] 
      store.design.save_string = override ? r[80] : store.design.save_string ||   r[80] 
      store.design.upload_new_audio_string = override ? r[81] : store.design.upload_new_audio_string || r[81] 
      store.design.save_audio_string = override ? r[82] : store.design.save_audio_string || r[82] 
      store.design.audio_clip_saved_string = override ? r[83] : store.design.audio_clip_saved_string || r[83] 
      store.design.audio_recording_not_supported_string = override ? r[84] : store.design.audio_recording_not_supported_string || r[84] 
      store.design.discard_audio_string = override ? r[85] : store.design.discard_audio_string || r[85] 
      store.design.dial_string = override ? r[86] : store.design.dial_string || r[86]
      store.design.afk_string = override ? r[87] : store.design.afk_string || r[87]

      console.log("Setting C again")
      keys.value["language_code"] = c[1]
      keys.value["correct_string"] = c[2]
      keys.value["incorrect_string"] = c[3]
      keys.value["your_answer_string"] = c[4]
      keys.value["correct_answer_string"] = c[5]
      keys.value["continue_string"] = c[6]
      keys.value["skip_string"] = c[7]
      keys.value["cancel_string"] = c[8]
      keys.value["question_string"] = c[9]
      keys.value["of_string"] = c[10]
      keys.value["done_string"] = c[11]
      keys.value["close_string"] = c[12]
      keys.value["chapters_string"] = c[13]
      keys.value["interactions_string"] = c[14]
      keys.value["search_string"] = c[15]
      keys.value["add_comment_string"] = c[16]
      console.log("add_comment_string", c[16])
      keys.value["transcripts_string"] = c[17]
      keys.value["name_string"] = c[18]
      keys.value["email_string"] = c[19]
      keys.value["phone_string"] = c[20]
      keys.value["custom_id_string"] = c[21]
      keys.value["resume_view_string"] = c[22]
      keys.value["resume_text_string"] = c[23]
      keys.value["restart_string"] = c[24]
      keys.value["try_again_string"] = c[25]
      keys.value["play_string"] = c[26]
      keys.value["pause_string"] = c[27]
      keys.value["mute_string"] = c[28]
      keys.value["unmute_string"] = c[29]
      keys.value["color_string"] = c[30]
      keys.value["draw_string"] = c[31]
      keys.value["text_string"] = c[32]
      keys.value["line_string"] = c[33]
      keys.value["pointer_string"] = c[34]
      keys.value["circle_string"] = c[35]
      keys.value["arrow_string"] = c[36]
      keys.value["hint_string"] = c[37]
      keys.value["rewind_string"] = c[38]
      keys.value["reset_string"] = c[39]
      keys.value["exit_string"] = c[40]
      keys.value["tool_string"] = c[41]
      keys.value["show_resource_tray_string"] = c[42]
      keys.value["leaving_string"] = c[45]
      keys.value["show_cart_string"] = c[43]
      keys.value["checkout_string"] = c[44]
      keys.value["show_resource_tray_string"] = c[46]
      keys.value["payment_message_string"] = c[47]
      keys.value["buy_now_string"] = c[48]
      keys.value["help_string"] = c[49]
      keys.value["have_purchased_string"] = c[50]
      keys.value["choose_from_library_string"] = c[51]
      keys.value["upload_new_video_string"] = c[52]
      keys.value["record_new_video_string"] = c[53] 
      keys.value["video_recording_not_supported_string"] = c[54] 
      keys.value["recording_finished_string"] = c[55] 
      keys.value["preview_not_supported_string"] = c[56] 
      keys.value["save_video_string"] = c[57] 
      keys.value["discard_string"] = c[58] 
      keys.value["recording_in_progress_string"] = c[59] 
      keys.value["finish_recording_string"] = c[60] 
      keys.value["start_recording_string"] = c[61] 
      keys.value["success_string"] = c[62] 
      keys.value["video_clip_saved_string"] = c[63] 
      keys.value["please_grant_string"] = c[64] 
      keys.value["continue_and_allow_string"] = c[65] 
      keys.value["no_webcam_string"] = c[66] 
      keys.value["no_microphone_string"] = c[67] 
      keys.value["plug_in_devices_string"] = c[68] 
      keys.value["yes_string"] = c[69] 
      keys.value["no_string"] = c[70] 
      keys.value["add_clip_title_string"] = c[71] 
      keys.value["discard_video_string"] = c[72] 
      keys.value["add_audio_string"] = c[73] 
      keys.value["choose_existing_clip_string"] = c[74] 
      keys.value["record_voice_clip_string"] = c[75] 
      keys.value["use_external_link_string"] = c[76] 
      keys.value["browser_cant_record_audio_string"] = c[77] 
      keys.value["choose_audio_clip_string"] = c[78] 
      keys.value["enter_link_string"] = c[79] 
      keys.value["save_string"] = c[80] 
      keys.value["upload_new_audio_string"] = c[81] 
      keys.value["save_audio_string"] = c[82] 
      keys.value["audio_clip_saved_string"] = c[83] 
      keys.value["audio_recording_not_supported_string"] = c[84] 
      keys.value["discard_audio_string"] = c[85] 
      keys.value["dial_string"] = c[86]
      keys.value["afk_string"] = c[87]
    }
  })
}
</script>