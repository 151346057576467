import { defineStore } from 'pinia'

export const DesignStore = defineStore('designs', {
  state: () => {
    return {
      loading: true,
      saving: false,
      design: {},
      pagination: {page: 1}
    }
  },

  actions: {

    async show(video_id=null, group_id=null) {

      this.loading = true
      return this.Api.get(`/designs?video_id=${video_id||''}&group_id=${group_id||''}`).then(response => {  
        this.design = response.data.design;        
      }).finally(() => {
        this.loading = false
      })
    },
    async update() {
      this.saving = true
      return this.Api.put(`/designs/${this.design.id}`, this.design).then(response => {  
        this.design = response.data.design;        
      }).finally(() => {
        this.saving = false
      })
    },

    async updateMagicMenu() {
      this.saving = true
      return this.Api.put(`/trays/${this.design.magic_menu.id}`, this.design.magic_menu).then(response => {  
        this.design.magic_menu = response.data.tray;        
      }).finally(() => {
        this.saving = false
      })
    },

    async updateEndMenu() {
      this.saving = true
      return this.Api.put(`/trays/${this.design.end_menu.id}`, this.design.end_menu).then(response => {  
        this.design.end_menu = response.data.tray;        
      }).finally(() => {
        this.saving = false
      })
    },

    async destroy(id) {      
      return this.Api.destroy(`/designs/${id}`).then(response => {  
        this.errors = {};
      }).catch(error => {
        this.errors = error.response.data.errors;
      }) 
    }
  }
})