export default {
  root: {
      class: [
          'relative overflow-auto [contain:strict] [transform:translateZ(0)] will-change-scroll outline-0 !h-48'
      ]
  },
  content: {
      class: [
          'absolute top-0 left-0 min-h-full min-w-full will-change-transform',
      ]
  },
  spacer: {
      class: [
          'absolute top-0 left-0 h-px w-px origin-top-left pointer-events-none'
      ]
  }
}