<script setup>
import { UserStore } from '@/front/stores/user_store.js'
const userStore = UserStore();

import { AssetStore } from '@/front/stores/asset_store.js'
const assetStore = AssetStore();

import { useRoute } from 'vue-router'
const router = useRouter()
import { useToast } from 'primevue/usetoast';
const toast = useToast();
import { useI18n } from 'vue-i18n'
const { t } = useI18n({});

import { GroupStore } from '@/front/stores/group_store.js'
const groupStore = GroupStore();

import InputText from 'primevue/inputtext';
import Checkbox from 'primevue/checkbox';
import Button from 'primevue/button';

const props = defineProps({
  videoIds: {
    type: Array,
    required: true
  }
})

const emit = defineEmits(['save'])

const selectedVideos = ref([])

onMounted(() => {
  selectedVideos.value = props.videoIds
});


const search = ref('')
const videoOptions = computed(() => {
  return assetStore.videos.filter(video => video.title.toLowerCase().includes(search.value.toLowerCase())).sort((a, b) => a.title.localeCompare(b.title))
})

const save = () => {
  console.log("💾 VideoSelector save", selectedVideos.value)
  emit('save', selectedVideos.value)
}


</script>

<template>
  <div class="p-2 w-full max-w-lg min-h-[50vh]">
    <InputText v-model="search" placeholder="Search" class="mb-2 w-full" />
    <div class="max-h-96 overflow-y-auto overflow-x-ellipsis">
      <div v-for="video in videoOptions" :key="video.id" class="p-1 whitespace-nowrap">
        <Checkbox v-model="selectedVideos" :value="video.id" class="mr-2" /> {{ video.title }}
      </div>
    </div>
    <div class="flex justify-center mt-4">
      <Button @click="save" size="large" :loading="groupStore.busy">{{ $t('common.save') }}</Button>
    </div>
  </div>
</template>
