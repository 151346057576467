<script setup>
import { UserStore } from '@/front/stores/user_store.js'
const userStore = UserStore();
import { useI18n } from 'vue-i18n'
const { t } = useI18n({});
import { createApi } from '@/plugins/api';
import Axios from 'axios';
import Checkbox from 'primevue/checkbox'
import Button from 'primevue/button'
import RadioButton from 'primevue/radiobutton'
import InputText from 'primevue/inputtext'
import { useToast } from "primevue/usetoast";
const toast = useToast();

import BulkVideoSelector from '@/front/components/BulkVideoSelector.vue'
const videoIds = ref([])

const props = defineProps({
  group_id: {
    type: String,
    required: false,
  },
  video_ids: {
    type: Array,
    required: false,
  }
})

const updateSelected = (selected) => {
  console.log("updatedSelected", selected)
  videoIds.value = selected
}

// TODO test this, password func, maybe vic and integrations
const videoAttributes = ref({
  showTitle: {
    enabled: false,
    name: t('common.show_video_title'),
    field: 'showTitle',
    value: false
  },
  showMessage: {
    enabled: false,
    name: t('common.show_share_message'),
    field: 'showMessage',
    value: false
  },
  show_branding: {
    enabled: false,
    name: t('common.show_icon'),
    field: 'show_branding',
    value: false
  },
  is_resumable: {
    enabled: false,
    name: t('common.allow_resumed_views'),
    field: 'is_resumable',
    value: false
  },
  use_afk: {
    enabled: false,
    name: t('common.verify_attention'),
    field: 'use_afk',
    value: false
  },
  tab_links: {
    enabled: false,
    name: t('common.open_links_in_new_tab'),
    field: 'tab_links',
    value: false
  },
  pause_inactive: {
    enabled: false,
    name: t('common.pause_video_when_tab_inactive'),
    field: 'pause_inactive',
    value: false
  },
  resume_focus: {
    enabled: false,
    name: t('common.resume_video_on_tab_focus'),
    field: 'resume_focus',
    value: false
  },
  use_chapter_timing: {
    enabled: false,
    name: t('common.use_chapter_timing_display'),
    field: 'use_chapter_timing',
    value: false
  },
  embed_only: {
    enabled: false,
    name: t('common.embed_only'),
    field: 'embed_only',
    value: false
  },
  can_skip_clips: {
    enabled: false,
    name: t('common.allow_skipping_of_video_audio_clips'),
    field: 'can_skip_clips',
    value: false
  },
  collect_geolocation: {
    enabled: false,
    name: t('common.collect_precise_viewer_location'),
    field: 'collect_geolocation',
    value: false
  },
  block_pausing: {
    enabled: false,
    name: t('common.prevent_pausing'),
    field: 'block_pausing',
    value: false
  },
  tab_title: {
    enabled: false,
    name: t('common.tab_title'),
    field: 'tab_title',
    value: ''
  },
  organization_access: {
    enabled: false,
    name: t('common.organization_access'),
    field: 'organization_access',
    value: false
  },
  organization_edit: {
    enabled: false,
    name: t('common.organization_edit'),
    field: 'organization_edit',
    value: false
  },
  tags: {
    enabled: false,
    name: t('common.tags'),
    field: 'tags',
    value: ''
  },
  privacy: {
    enabled: false,
    name: t('common.privacy'),
    field: 'privacy',
    value: 0
  },
  published: {
    enabled: false,
    name: t('common.published'),
    field: 'published',
    value: false
  },
  participate: {
    enabled: false,
    name: t('common.participation'),
    field: 'participate',
    value: false
  },
  observe: {
    enabled: false,
    name: t('common.observe'),
    field: 'observe',
    value: false
  },
})

const save = () => {
  console.log("save", videoAttributes.value)
  const enabledAttributes = Object.entries(videoAttributes.value)
    .filter(([_, attr]) => attr.enabled)
    .reduce((acc, [_, attr]) => {
      acc[attr.field] = attr.value;
      return acc;
    }, {});

  console.log("Enabled attributes:", enabledAttributes);

  const Api = createApi({ handler: Axios, namespace: '/' });


  Api.post('/videos/bulk_edit', { videoIds: videoIds.value, attributes: enabledAttributes })
    .then(() => {
      // Handle success
      toast.add({ severity: 'success', summary: t('common.saved'), detail: 'Message Content', life: 3000 });
    })
    .catch((error) => {
      // Handle error
      toast.add({ severity: 'error', summary: t('common.error'), detail: 'Message Content', life: 3000 });
    });
}
</script>

<template>
  <div>
    <!-- TODO Breadcrumbs  -->
    <div class="p-4">
      <h1 class="text-4xl font-extrabold mb-4"> {{ $t('common.bulk_edit') }} </h1>
      <div class="flex justify-center">
        <Button label="Save" @click="save()" />
      </div>
      <div class="grid grid-cols-3 gap-8">

        <div class="p-2 col-span-1">
          <div class="text-2xl font-bold mb-2"> {{ $t('common.select') }} {{ $t('common.videos') }}: {{
            videoIds.length }} </div>
          <BulkVideoSelector @update="updateSelected" :group_id="props.group_id" :video_ids="video_ids"
            class="max-h-[60vh] rounded-lg overflow-y-auto" />
        </div>

        <div class="p-2 col-span-2">
          <div class="text-2xl font-bold mb-2"> {{ $t('common.choose') }} {{ $t('common.attributes') }} </div>
          <div class="grid grid-cols-3 gap-2 mt-4">
            <div class="text-xl font-bold text-left col-span-2"> {{ $t('common.field') }} </div>
            <div class="text-xl font-bold text-left col-span-1"> {{ $t('common.value') }} </div>

            <!-- Pre-Video -->
            <!-- Video Title -->
            <div class="flex items-center p-1 col-span-2">
              <Checkbox v-model="videoAttributes.showTitle.enabled" :binary="true" class="mr-2" />
              <div class="overflow-hidden text-left">{{ $t('common.show_video_title') }}</div>
            </div>
            <div class="flex items-center p-1">
              <div v-if="videoAttributes.showTitle.enabled" class="flex items-left">
                <Checkbox v-model="videoAttributes.showTitle.value" :binary="true" />
                <label for="default-checkbox" class="ms-2 text-sm font-medium text-gray-900">{{
                  $t('common.enabled') }}</label>
              </div>
            </div>

            <!-- Show Share Message -->
            <div class="flex items-center p-1 col-span-2">
              <Checkbox v-model="videoAttributes.showMessage.enabled" :binary="true" class="mr-2" />
              <div class="overflow-hidden text-left">{{ $t('common.show_share_message') }}</div>
            </div>
            <div class="flex items-center p-1">
              <div v-if="videoAttributes.showMessage.enabled" class="flex items-left">
                <Checkbox v-model="videoAttributes.showMessage.value" :binary="true" />
                <label for="default-checkbox" class="ms-2 text-sm font-medium text-gray-900">{{
                  $t('common.enabled') }}</label>
              </div>
            </div>

            <!-- Show Icon -->
            <div class="flex items-center p-1 col-span-2">
              <Checkbox v-model="videoAttributes.show_branding.enabled" :binary="true" class="mr-2" />
              <div class="overflow-hidden text-left">{{ $t('common.show_icon') }}</div>
            </div>
            <div class="flex items-center p-1">
              <div v-if="videoAttributes.show_branding.enabled" class="flex items-left">
                <Checkbox v-model="videoAttributes.show_branding.value" :binary="true" />
                <label for="default-checkbox" class="ms-2 text-sm font-medium text-gray-900">{{
                  $t('common.enabled') }}</label>
              </div>
            </div>

            <!-- Allow Resumed Views -->
            <div class="flex items-center p-1 col-span-2">
              <Checkbox v-model="videoAttributes.is_resumable.enabled" :binary="true" class="mr-2" />
              <div class="overflow-hidden text-left">{{ $t('common.allow_resumed_views') }}</div>
            </div>
            <div class="flex items-center p-1">
              <div v-if="videoAttributes.is_resumable.enabled" class="flex items-left">
                <Checkbox v-model="videoAttributes.is_resumable.value" :binary="true" />
                <label for="default-checkbox" class="ms-2 text-sm font-medium text-gray-900">{{
                  $t('common.enabled') }}</label>
              </div>
            </div>

            <!-- Verify Attention -->
            <div class="flex items-center p-1 col-span-2">
              <Checkbox v-model="videoAttributes.use_afk.enabled" :binary="true" class="mr-2" />
              <div class="overflow-hidden text-left">{{ $t('common.verify_attention') }}</div>
            </div>
            <div class="flex items-center p-1">
              <div v-if="videoAttributes.use_afk.enabled" class="flex items-left">
                <Checkbox v-model="videoAttributes.use_afk.value" :binary="true" />
                <label for="default-checkbox" class="ms-2 text-sm font-medium text-gray-900">{{
                  $t('common.enabled') }}</label>
              </div>
            </div>

            <!-- Open Links in New Tab -->
            <div class="flex items-center p-1 col-span-2">
              <Checkbox v-model="videoAttributes.tab_links.enabled" :binary="true" class="mr-2" />
              <div class="overflow-hidden text-left">{{ $t('common.open_links_in_new_tab') }}</div>
            </div>
            <div class="flex items-center p-1">
              <div v-if="videoAttributes.tab_links.enabled" class="flex items-left">
                <Checkbox v-model="videoAttributes.tab_links.value" :binary="true" />
                <label for="default-checkbox" class="ms-2 text-sm font-medium text-gray-900">{{
                  $t('common.enabled') }}</label>
              </div>
            </div>

            <!-- Pause Video When Tab Inactive -->
            <div class="flex items-center p-1 col-span-2">
              <Checkbox v-model="videoAttributes.pause_inactive.enabled" :binary="true" class="mr-2" />
              <div class="overflow-hidden text-left">{{ $t('common.pause_video_when_tab_inactive') }}</div>
            </div>
            <div class="flex items-center p-1">
              <div v-if="videoAttributes.pause_inactive.enabled" class="flex items-left">
                <Checkbox v-model="videoAttributes.pause_inactive.value" :binary="true" />
                <label for="default-checkbox" class="ms-2 text-sm font-medium text-gray-900">{{
                  $t('common.enabled') }}</label>
              </div>
            </div>

            <!-- Resume Video on Tab Focus -->
            <div class="flex items-center p-1 col-span-2">
              <Checkbox v-model="videoAttributes.resume_focus.enabled" :binary="true" class="mr-2" />
              <div class="overflow-hidden text-left">{{ $t('common.resume_video_on_tab_focus') }}</div>
            </div>
            <div class="flex items-center p-1">
              <div v-if="videoAttributes.resume_focus.enabled" class="flex items-left">
                <Checkbox v-model="videoAttributes.resume_focus.value" :binary="true" />
                <label for="default-checkbox" class="ms-2 text-sm font-medium text-gray-900">{{
                  $t('common.enabled') }}</label>
              </div>
            </div>

            <!-- Use Chapter Timing Display -->
            <div class="flex items-center p-1 col-span-2">
              <Checkbox v-model="videoAttributes.use_chapter_timing.enabled" :binary="true" class="mr-2" />
              <div class="overflow-hidden text-left">{{ $t('common.use_chapter_timing_display') }}</div>
            </div>
            <div class="flex items-center p-1">
              <div v-if="videoAttributes.use_chapter_timing.enabled" class="flex items-left">
                <Checkbox v-model="videoAttributes.use_chapter_timing.value" :binary="true" />
                <label for="default-checkbox" class="ms-2 text-sm font-medium text-gray-900">{{
                  $t('common.enabled') }}</label>
              </div>
            </div>

            <!-- Embed Only -->
            <div class="flex items-center p-1 col-span-2">
              <Checkbox v-model="videoAttributes.embed_only.enabled" :binary="true" class="mr-2" />
              <div class="overflow-hidden text-left">{{ $t('common.embed_only') }}</div>
            </div>
            <div class="flex items-center p-1">
              <div v-if="videoAttributes.embed_only.enabled" class="flex items-left">
                <Checkbox v-model="videoAttributes.embed_only.value" :binary="true" />
                <label for="default-checkbox" class="ms-2 text-sm font-medium text-gray-900">{{
                  $t('common.enabled') }}</label>
              </div>
            </div>

            <!-- Allow Skipping of Video/Audio Clips -->
            <div class="flex items-center p-1 col-span-2">
              <Checkbox v-model="videoAttributes.can_skip_clips.enabled" :binary="true" class="mr-2" />
              <div class="overflow-hidden text-left">{{ $t('common.allow_skipping_of_video_audio_clips') }}</div>
            </div>
            <div class="flex items-center p-1">
              <div v-if="videoAttributes.can_skip_clips.enabled" class="flex items-left">
                <Checkbox v-model="videoAttributes.can_skip_clips.value" :binary="true" />
                <label for="default-checkbox" class="ms-2 text-sm font-medium text-gray-900">{{
                  $t('common.enabled') }}</label>
              </div>
            </div>

            <!-- Collect Precise Viewer Location -->
            <div class="flex items-center p-1 col-span-2">
              <Checkbox v-model="videoAttributes.collect_geolocation.enabled" :binary="true" class="mr-2" />
              <div class="overflow-hidden text-left">{{ $t('common.collect_precise_viewer_location') }}</div>
            </div>
            <div class="flex items-center p-1">
              <div v-if="videoAttributes.collect_geolocation.enabled" class="flex items-left">
                <Checkbox v-model="videoAttributes.collect_geolocation.value" :binary="true" />
                <label for="default-checkbox" class="ms-2 text-sm font-medium text-gray-900">{{
                  $t('common.enabled') }}</label>
              </div>
            </div>

            <!-- Prevent Pausing -->
            <div class="flex items-center p-1 col-span-2">
              <Checkbox v-model="videoAttributes.block_pausing.enabled" :binary="true" class="mr-2" />
              <div class="overflow-hidden text-left">{{ $t('common.prevent_pausing') }}</div>
            </div>
            <div class="flex items-center p-1">
              <div v-if="videoAttributes.block_pausing.enabled" class="flex items-left">
                <Checkbox v-model="videoAttributes.block_pausing.value" :binary="true" />
                <label for="default-checkbox" class="ms-2 text-sm font-medium text-gray-900">{{
                  $t('common.enabled') }}</label>
              </div>
            </div>

            <!-- Organization Access -->
            <div class="flex items-center p-1 col-span-2">
              <Checkbox v-model="videoAttributes.organization_access.enabled" :binary="true" class="mr-2" />
              <div class="overflow-hidden text-left">{{ $t('common.organization_access') }}</div>
            </div>
            <div class="flex items-center p-1">
              <div v-if="videoAttributes.organization_access.enabled" class="flex items-left">
                <Checkbox v-model="videoAttributes.organization_access.value" :binary="true" />
                <label for="default-checkbox" class="ms-2 text-sm font-medium text-gray-900">{{
                  $t('common.enabled') }}</label>
              </div>
            </div>

            <!-- Organization Edit -->
            <div class="flex items-center p-1 col-span-2">
              <Checkbox v-model="videoAttributes.organization_edit.enabled"
                :disabled="!(videoAttributes.organization_access.enabled && videoAttributes.organization_access.value)"
                :binary="true" class="mr-2" />
              <div class="overflow-hidden text-left">{{ $t('common.organization_edit') }}</div>
            </div>
            <div class="flex items-center p-1">
              <div v-if="videoAttributes.organization_edit.enabled" class="flex items-left">
                <Checkbox v-model="videoAttributes.organization_edit.value"
                  :disabled="!(videoAttributes.organization_access.enabled && videoAttributes.organization_access.value)"
                  :binary="true" />
                <label for="default-checkbox" class="ms-2 text-sm font-medium text-gray-900">{{
                  $t('common.enabled') }}</label>
              </div>
            </div>

            <!-- Tags -->
            <div class="flex items-center p-1 col-span-1">
              <Checkbox v-model="videoAttributes.tags.enabled" :binary="true" class="mr-2" />
              <div class="overflow-hidden text-left">{{ $t('common.tags') }}</div>
            </div>
            <div class="flex items-center p-1 col-span-2">
              <div v-if="videoAttributes.tags.enabled" class="w-full">
                <InputText v-model="videoAttributes.tags.value" class="w-full" />
                <div class="mr-2"> {{ $t('common.tags_enter_desc') }} </div>
              </div>
            </div>

            <!-- Published -->
            <div class="flex items-center p-1 col-span-1">
              <Checkbox v-model="videoAttributes.published.enabled" :binary="true" class="mr-2" />
              <div class="overflow-hidden text-left">{{ $t('common.published') }}</div>
            </div>
            <div class="flex items-center p-1 col-span-2">
              <div v-if="videoAttributes.published.enabled" class="flex items-left">
                <Checkbox v-model="videoAttributes.published.value" :binary="true" />
                <label for="default-checkbox" class="ms-2 text-sm font-medium text-gray-900">{{
                  $t('common.enabled') }}</label>
              </div>
            </div>

            <!-- Privacy -->
            <div class="flex items-center p-1 col-span-1">
              <Checkbox v-model="videoAttributes.privacy.enabled" :binary="true" class="mr-2" />
              <div class="overflow-hidden text-left">{{ $t('common.privacy') }}</div>
            </div>
            <div class="flex items-center p-1 col-span-2">
              <div v-if="videoAttributes.privacy.enabled" class="w-full">
                <div class="flex items-center my-2">
                  <RadioButton v-model="videoAttributes.privacy.value" :value="0" :label="$t('common.privacy_link')"
                    variant="filled" />
                  <label for="default-checkbox" class="ms-2 text-sm text-left font-medium text-gray-900">{{
                    $t('common.privacy_link') }}</label>
                </div>
                <div class="flex items-center my-2">
                  <RadioButton v-model="videoAttributes.privacy.value" :value="2" :label="$t('common.privacy_password')"
                    variant="filled" />
                  <label for="default-checkbox" class="ms-2 text-sm text-left font-medium text-gray-900">{{
                    $t('common.privacy_password') }}</label>
                </div>
                <div class="flex items-center my-2">
                  <RadioButton v-model="videoAttributes.privacy.value" :value="3"
                    :label="$t('common.privacy_access_list')" variant="filled" />
                  <label for="default-checkbox" class="ms-2 text-sm text-left font-medium text-gray-900">{{
                    $t('common.privacy_access_list') }}</label>
                </div>
              </div>
            </div>

            <!-- Visibility -->
            <div class="flex items-center p-1 col-span-1">
              <Checkbox v-model="videoAttributes.observe.enabled" :binary="true" class="mr-2" />
              <div class="overflow-hidden text-left">{{ $t('common.mode') }}</div>
            </div>
            <div class="flex items-center p-1 col-span-2">
              <div v-if="videoAttributes.observe.enabled" class="w-full">
                <div class="flex items-center my-2">
                  <RadioButton v-model="videoAttributes.observe.value" :value="true"
                    :label="$t('common.mode_presentation')" variant="filled" />
                  <label for="default-checkbox" class="ms-2 text-sm text-left font-medium text-gray-900">{{
                    $t('common.mode_presentation') }}</label>
                </div>
                <div class="flex items-center my-2">
                  <RadioButton v-model="videoAttributes.observe.value" :value="false"
                    :label="$t('common.mode_collaboration')" variant="filled" />
                  <label for="default-checkbox" class="ms-2 text-sm text-left font-medium text-gray-900">{{
                    $t('common.mode_collaboration') }}</label>
                </div>
              </div>
            </div>

            <!-- Participation -->
            <div class="flex items-center p-1 col-span-1">
              <Checkbox v-model="videoAttributes.participate.enabled" :binary="true" class="mr-2" />
              <div class="overflow-hidden text-left">{{ $t('common.participation') }}</div>
            </div>
            <div class="flex items-center p-1 col-span-2">
              <div v-if="videoAttributes.participate.enabled" class="w-full">
                <div class="flex items-center my-2">
                  <RadioButton v-model="videoAttributes.participate.value" :value="true"
                    :label="$t('common.participate_view')" variant="filled" />
                  <label for="default-checkbox" class="ms-2 text-sm text-left font-medium text-gray-900">{{
                    $t('common.participate_view') }}</label>
                </div>
                <div class="flex items-center my-2">
                  <RadioButton v-model="videoAttributes.participate.value" :value="false"
                    :label="$t('common.participate_add')" variant="filled" />
                  <label for="default-checkbox" class="ms-2 text-sm text-left font-medium text-gray-900">{{
                    $t('common.participate_add') }}</label>
                </div>
              </div>
            </div>




          </div>
        </div>
      </div>
    </div>
  </div>
</template>
