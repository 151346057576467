<script setup>
import { UserStore } from '@/front/stores/user_store.js'
const userStore = UserStore();
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'
const { t } = useI18n({});

import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';

import { Mail01Icon } from 'hugeicons-vue';

import { Delete02Icon } from 'hugeicons-vue';

import { DateComposer } from '@/front/composables/DateComposer.js'
const { hhmmyyyy } = DateComposer();

import { useConfirm } from "primevue/useconfirm";
const confirm = useConfirm();

import Dialog from 'primevue/dialog';
const inviteDialog = ref(false)
const inviteEmail = ref('')

import { StringValidator } from '@/front/composables/StringValidator.js'
const { isValidEmail } = StringValidator()

import { useToast } from "primevue/usetoast";
const toast = useToast();


onMounted(() => {
  userStore.fetchOrgUsers()
});

const deleteInvite = (id) => {
  console.log("deleteInvite", id)
  confirm.require({
    message: t('common.delete_invite_confirm'),
    header: t('common.delete_invite_header'),
    icon: 'pi pi-exclamation-triangle',
    accept: () => {
      userStore.destroyInvite(id).then(() => {
        toast.add({
          title: t('common.success'),
          summary: t('common.invite_deleted'),
          severity: 'success',
          life: 3000
        })
        userStore.fetchOrgUsers()
      })
    }
  })
}

const inviteUser = () => {
  userStore.createInvite(inviteEmail.value).then(() => {
    toast.add({
      title: t('common.success'),
      summary: t('common.invite_sent'),
      severity: 'success',
      life: 3000
    })
    userStore.fetchOrgUsers()
    inviteDialog.value = false
    inviteEmail.value = ''
  }).catch((error) => {
    toast.add({
      title: t('common.error'),
      summary: error.response.data.message,
      severity: 'error',
      life: 3000
    })
    inviteEmail.value = ''
  })
}

const remainingSpace = computed(() => {
  return userStore.orgUsers.quota - userStore.orgUsers.active_users.length - userStore.orgUsers.invites.length
})


</script>

<template>
  <div v-if="userStore.orgUsers && userStore.orgUsers.active_users">
    <!-- TODO Breadcrumbs  -->
    <div class="p-4">
      <div class="p-8 mb-96 text-center bg-white w-fit mx-auto rounded-2xl shadow-xl mt-8 min-w-[60dvw]">
        <h1 class="text-4xl font-extrabold"> {{ $t('common.manage_users') }} </h1>

        <!-- Invite users -->
        <div class="my-4">
          <div class="mb-2"> {{ $t('common.remaining_seats') }}: {{ remainingSpace }} </div>
          <Button :label="$t('common.invite')" size="large" @click="inviteDialog = true" :disabled="remainingSpace <= 0">
            <Mail01Icon class="w-4 h-4" /> {{ $t('common.invite') }}
          </Button>
        </div>
        
        <!-- Active users table -->
        <div class="my-6">
          <h2 class="text-2xl font-bold my-4">{{ $t('common.active_users') }}: {{ userStore.orgUsers.active_users.length }}</h2>
          <DataTable :value="userStore.orgUsers.active_users" tableStyle="min-width: 50rem" stripedRows>
            <Column :field="user => user.first + ' ' + user.last" :header="$t('common.name')"></Column>
            <Column field="email" :header="$t('common.email')"></Column>
            <Column field="video_count" :header="$t('common.videos')"></Column>
            <Column field="actions" :header="$t('common.actions')"> 
              <template #body="user">
                <Button size="small" severity="danger">
                  <Delete02Icon class="w-4 h-4 mr-2" /> {{ $t('common.deactivate') }}
                </Button>
              </template>
            </Column>
          </DataTable>
        </div>

        <!-- Invites table -->
        <div>
          <h2 class="text-2xl font-bold my-4">{{ $t('common.invites') }}: {{ userStore.orgUsers.invites.length }}</h2>
          <DataTable :value="userStore.orgUsers.invites" tableStyle="min-width: 50rem" stripedRows>
            <Column :field="invite => hhmmyyyy(invite.created_at)" :header="$t('common.sent')"></Column>
            <Column field="email" :header="$t('common.email')"></Column>
            <Column field="actions" :header="$t('common.actions')"> 
              <template #body="invite">
                <Button size="small" severity="info" class="mr-2" @click="remindInvite(invite.data.id)">
                  <Mail01Icon class="w-4 h-4 mr-2" /> {{ $t('common.remind') }}
                </Button>
                <Button size="small" severity="danger" @click="deleteInvite(invite.data.id)">
                  <Delete02Icon class="w-4 h-4 mr-2" /> {{ $t('common.retract') }}
                </Button>
              </template>
            </Column>
          </DataTable>
        </div>
      </div>
    </div>

    <!-- Invite dialog  -->
    <Dialog v-model:visible="inviteDialog" :header="$t('common.invite_user')" :modal="true">
      <div class="min-w-[400px]">
        <div class="my-2">
          <InputText v-model="inviteEmail" :placeholder="$t('common.email')" autofocus class="w-full"/>
        </div>
        <div class="flex justify-center mt-4">
          <Button :label="$t('common.invite')" @click="inviteUser" :disabled="!isValidEmail(inviteEmail)" />
        </div>
      </div>
    </Dialog>
  </div>
</template>
