<script lang="ts" setup>
  import AudioCore from '@/front/components/AudioCore.vue';
  // import AudioElement from '@/front/components/AudioElement.vue';
  import { AudioStore } from '@/front/stores/audio_store.js';
  const audioStore = AudioStore();
</script>

<template>
  <div class="w-full">
    <AudioCore v-if="audioStore.src"/>
    <!-- <AudioElement /> -->
  </div>
</template>