<script setup>
import { UserStore } from '@/front/stores/user_store.js'
const userStore = UserStore();
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'
const { t } = useI18n({});

import { StringComposer } from '@/front/composables/StringComposer.js'
const { formatNumber } = StringComposer();

import { DateComposer } from '@/front/composables/DateComposer.js'
const { hhmmyyyy } = DateComposer();


import ProgressBar from 'primevue/progressbar';


onMounted(() => {
  userStore.fetchOrgQuotas()
});

const minutesPercentage = computed(() => {
  return userStore.orgQuotas.minutes / userStore.organization.minutes_quota * 100
})

const videosPercentage = computed(() => {
  return userStore.orgQuotas.videos / userStore.organization.video_quota * 100
})

const groupsPercentage = computed(() => {
  return userStore.orgQuotas.groups / userStore.organization.group_quota * 100
})

const seriesPercentage = computed(() => {
  return userStore.orgQuotas.series / userStore.organization.series_quota * 100
})  

</script>

<template>
  <div v-if="userStore.orgQuotas">
    <!-- TODO Breadcrumbs  -->
    <div class="p-4">
      <div class="p-8 mb-96 text-center bg-white w-fit mx-auto rounded-2xl shadow-xl mt-8 min-w-[60dvw]">
        <h1 class="text-4xl font-extrabold mb-4"> {{ $t('common.quotas') }} </h1>

        <!-- Videos -->
        <div class="mb-4">
          <div class="flex justify-between">
            <h2 class="text-2xl font-bold"> {{ $t('common.videos') }} </h2>
            <div> {{ formatNumber(userStore.orgQuotas.videos) }} {{ $t('common.of') }} {{ formatNumber(userStore.organization.video_quota) }} {{ $t('common.videos') }} {{ $t('common.used') }} </div>
          </div>
          <ProgressBar :value="videosPercentage" :showValue="videosPercentage > 10" class="w-full rounded-md bg-green-200 my-2" />
        </div>
        
        <!-- Minutes -->
        <div class="mb-4">
          <div class="flex justify-between">
            <h2 class="text-2xl font-bold"> {{ $t('common.minutes') }} </h2>
            <div> {{ formatNumber(userStore.orgQuotas.minutes) }} {{ $t('common.of') }} {{ formatNumber(userStore.organization.minutes_quota) }} {{ $t('common.minutes') }} {{ $t('common.used_since') }} {{ hhmmyyyy(userStore.orgQuotas.started) }}</div>
          </div>
          <ProgressBar :value="minutesPercentage" :showValue="minutesPercentage > 10" class="w-full rounded-md bg-green-200 my-2" />
        </div>

        <!-- Groups -->
        <div class="mb-4">
          <div class="flex justify-between">
            <h2 class="text-2xl font-bold"> {{ $t('common.groups') }} </h2>
            <div> {{ formatNumber(userStore.orgQuotas.groups) }} {{ $t('common.of') }} {{ formatNumber(userStore.organization.group_quota) }} {{ $t('common.groups') }} {{ $t('common.used') }}</div>
          </div>
          <ProgressBar :value="groupsPercentage" :showValue="groupsPercentage > 10" class="w-full rounded-md bg-green-200 my-2" />
        </div>

        <!-- Series -->
        <div class="mb-4">
          <div class="flex justify-between">
            <h2 class="text-2xl font-bold"> {{ $t('common.series') }} </h2>
            <div> {{ formatNumber(userStore.orgQuotas.series) }} {{ $t('common.of') }} {{ formatNumber(userStore.organization.series_quota) }} {{ $t('common.series') }} {{ $t('common.used') }}</div>
          </div>
          <ProgressBar :value="seriesPercentage" :showValue="seriesPercentage > 10" class="w-full rounded-md bg-green-200 my-2" />
        </div>
      </div>
    </div>
  </div>
</template>
