import { ref } from "vue";

export function ImageComposer() {

  const cdnImageUrl = (url) => {
    // console.log("🌜 ImageComposer cdnImageUrl", url)
    var result = url
    // Return empty if no url
    if (!result) {
      return ''
    }
    // Dont transform gifs
    if (url.includes(".gif")) {
      return url
    }

    // Bad content
    if (url == '?optimizer=image' || url == '?optimizer=image&optimizer=image') {
      return ''
    }
    // Check if has S3 prefix
    if (!result.includes('http')) {
      result = `https://mindstamp-prod.s3.us-west-1.amazonaws.com${url}`
    }

    // Replace pub cdn
    const resourceCdn = 'https://resource-cdn.mindstamp.com'
    const pubCdn = 'https://pub-cdn.mindstamp.com'
    result = result.replaceAll('https://mindstamp-pub.imgix.net', pubCdn)
    result = result.replaceAll('https://mindstamp-pub.s3.us-west-1.amazonaws.com', pubCdn)
    result = result.replaceAll('https://mindstamp-pub.s3.us-west-2.amazonaws.com', pubCdn)
    result = result.replaceAll('https://mindstamp-pub.s3.us-west-3.amazonaws.com', pubCdn)

    // Replace resource cdn
    result = result.replaceAll('https://mindstamp-resources.s3.us-west-1.amazonaws.com', resourceCdn)
    result = result.replaceAll('https://mindstamp-resources.s3.us-west-2.amazonaws.com', resourceCdn)
    // console.log("🌞 ImageComposer cdnImageUrl", result)
    return result
  }

  const getIntegrationImage = (path) => {
    switch (path.toLowerCase()) {
      case 'facebook':
        return 'https://mspb.b-cdn.net/fb.png'
      case 'hubspot':
        return 'https://resource-cdn.mindstamp.com/assets/images/integrations/logos/hubspot.svg'
      case 'zapier':
        return 'https://resource-cdn.mindstamp.com/assets/images/integrations/logos/zapier.png'
      case 'google-analytics':
        return 'https://resource-cdn.mindstamp.com/assets/images/integrations/logos/ga.png'
      case 'shopify':
        return 'https://resource-cdn.mindstamp.com/assets/images/integrations/logos/shopify.png'
      case 'synthesia':
        return 'https://resource-cdn.mindstamp.com/assets/images/integrations/logos/synthesia.png'
      case 'webhooks':  
        return 'https://resource-cdn.mindstamp.com/logos/v2/full/color.png'
      case 'open-ai':
        return 'https://resource-cdn.mindstamp.com/assets/images/integrations/logos/openai.png'
      case 'constant-contact':
        return 'https://resource-cdn.mindstamp.com/assets/images/integrations/logos/cc.svg'
      case 'stripe':
        return 'https://resource-cdn.mindstamp.com/assets/images/integrations/logos/stripe.png'
      default:
        return ''
    }
  }

  // TODO make all of these icons all the same size
  const getIntegrationIcon = (path) => {
    switch (path.toLowerCase()) {
      case 'facebook':
        return 'https://mspb.b-cdn.net/fb.png'
      case 'upload':
        return 'https://resource-cdn.mindstamp.com/assets/images/icons/loop-blue.png'
      case 'link':
        return 'https://cdn.mindstamp.com/7471685.png'
      case 'vimeo':
        return 'https://resource-cdn.mindstamp.com/assets/images/vimeo.png'
      case 'wistia':
        return 'https://resource-cdn.mindstamp.com/assets/images/wistia.png'
      case 'synthesia':
        return 'https://resource-cdn.mindstamp.com/assets/images/synthesia.png'
      case 'heygen':
        return 'https://cdn.mindstamp.com/heygen.png'
      case 'loom':
        return 'https://cdn.mindstamp.com/loom.png'
      case 'panopto':
        return 'https://cdn.mindstamp.com/panop.png'
      case 'kaltura':
        return 'https://inplayer.com/wp-content/uploads/2018/04/kaltura-icon.png'
      case 'youtube':
        return 'https://resource-cdn.mindstamp.com/assets/images/youtube.png'
      case 'stock':
        return 'https://cdn.mindstamp.com/1e73e5cb95b89f1dce8b59c5236ca1fc28c7113b.png'
      case 'samples':
        return 'https://resource-cdn.mindstamp.com/assets/images/samples.png'
      default:
        return ''
    }
  }

  const getVideoThumbnail = (design) => {
    return design.thumbnail || getDefaultVideoThumbnail()
  }

  const getDefaultVideoThumbnail = () => {
    return 'https://beanscafe.org/wp-content/uploads/2021/04/Video-Placeholder.jpg'
  }

  return { getIntegrationImage, getVideoThumbnail, getIntegrationIcon, getDefaultVideoThumbnail, cdnImageUrl };
}