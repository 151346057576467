import "vidstack/player/styles/default/thumbnail.css";
import {
defineCustomElement,
MediaChaptersRadioGroupElement,
MediaRadioElement,
MediaThumbnailElement
} from "vidstack/elements";

defineCustomElement(MediaChaptersRadioGroupElement);
defineCustomElement(MediaRadioElement);
defineCustomElement(MediaThumbnailElement);