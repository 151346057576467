import dayjs from 'dayjs'

export function DateComposer() {
  const { t } = useI18n({})

  function dmyyyy(date) {
    return dayjs(date).format('MM/D/YY')
  }
  function hhmmyyyy(date) {
    return dayjs(date).format('MMM D, YYYY')
  }
  function hhmmyyyyt(date) {
    return dayjs(date).format('MMM D YYYY h:mm a')
  }
  function daysFromNow(date) {
    return Math.abs(dayjs().diff(dayjs(date), 'days'))
  }

  return {
    dmyyyy,
    hhmmyyyy,
    hhmmyyyyt,
    daysFromNow
  }
}