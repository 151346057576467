<script setup>

const props = defineProps({
  ann:{
    type: Object,
    required: true
  }
})

import { VideoStore } from '@/front/stores/video_store.js';
const videoStore = VideoStore();
const baseDesign = videoStore.video.design || {}
const customStyle = props.ann.customStyle && JSON.parse(props.ann.customStyle) || {}

const styling = computed(() => {
  // TODO make sure this doesnt break in diff context
  return {
    'color': props.ann.text_color || customStyle.color || videoStore.video.design.comment_text_color, 
    'background': props.ann.background_color || customStyle.background_color || baseDesign.button_background_color, 
    'borderRadius': (props.ann.border_radius || customStyle.borderRadius || baseDesign.button_border_radius) + 'px',
  }
})
</script>

<template>
  
  <div class="text-center w-fit px-4 py-2 mx-auto" :style="styling">
    <span v-html="ann.content"></span>
  </div>
  <!-- {{  styling }} -->

</template>