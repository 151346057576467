<script setup>
import Button from 'primevue/button';
import Select from 'primevue/select';
import Dialog from 'primevue/dialog';
import Checkbox from 'primevue/checkbox';
import InputText from 'primevue/inputtext';
import Tabs from 'primevue/tabs';
import TabList from 'primevue/tablist';
import Tab from 'primevue/tab';
import TabPanels from 'primevue/tabpanels';
import TabPanel from 'primevue/tabpanel';

import { DesignStore } from "@/front/stores/design_store.js";
import { onMounted, watch } from "vue";

import PlayButton from "@/front/icons/PlayButton.vue";
import ImagePlaceholder from "@/front/icons/ImagePlaceholder.vue";
import ImagePicker from "@/front/components/ImagePicker.vue";
import LayoutPicker from "@/front/components/LayoutPicker.vue";
import TransitionPicker from "@/front/components/TransitionPicker.vue";
import Translations from "@/front/components/Translations.vue";
import { debounce } from 'lodash';

import CustomColorPicker from "@/front/components/CustomColorPicker.vue";

const designStore = DesignStore();

// TODO manage applying to diff videos
// TODO warning about group inheritance

onMounted(() => {
  console.log("Show video design", props.video_id, props.group_id)
  designStore.show(props.video_id, props.group_id);
});

const thumbnailModal = ref(false)
const playButtonModal = ref(false)

const interactionsTransitionModal = ref(false)
const questionsTransitionModal = ref(false)
const switchTransitionModal = ref(false)
const messageTransitionModal = ref(false)
const menuTransitionModal = ref(false)
const endTransitionModal = ref(false)

const commentAlignOptions = [
  { text: "Top Left", value: "topLeft" },
  { text: "Top Right", value: "topRight" },
  { text: "Top Center", value: "topCenter" },
  { text: "Bottom Center", value: "bottom" },
]


const setThumbnail = (file) => {
  designStore.design.thumbnail = file.split("?")[0]
  thumbnailModal.value = false
  debouncedSave();
}

const setPlayButton = (file) => {
  designStore.design.play_button_image = file.split("?")[0]
  playButtonModal.value = false
  debouncedSave();
}

const setInteractionsTransition = (transition) => {
  designStore.design.interactions_transition = transition
  interactionsTransitionModal.value = false
  debouncedSave();
}

const setMessageTransition = (transition) => {
  designStore.design.message_transition = transition
  messageTransitionModal.value = false
  debouncedSave();
}

const setSwitchTransition = (transition) => {
  designStore.design.switch_transition = transition
  switchTransitionModal.value = false
  debouncedSave();
}

const setQuestionsTransition = (transition) => {
  designStore.design.question_transition = transition
  questionsTransitionModal.value = false
  debouncedSave();
}

const setEndTransition = (transition) => {
  designStore.design.end_menu.transition = transition
  endTransitionModal.value = false
  debouncedUpdateEndMenu();
}

const setMenuTransition = (transition) => {
  designStore.design.magic_menu.transition = transition
  menuTransitionModal.value = false
  debouncedUpdateMagicMenu();
}


const debouncedSave = debounce(() => {
  console.log("Design Changed", designStore.design)
  designStore.update()
  // search()
}, 1000);

const debouncedUpdateMagicMenu = debounce(() => {
  console.log("Magic Menu Changed", designStore.design.magic_menu)
  designStore.updateMagicMenu()
}, 1000);

const debouncedUpdateEndMenu = debounce(() => {
  console.log("Design Changed", designStore.design)
  designStore.updateEndMenu()
}, 1000);

const props = defineProps({
  video_id: {
    type: String,
    required: false,
  },
  group_id: {
    type: String,
    required: false,
  },
})
</script>


<template>

  <div v-if="!designStore.loading" class="">
    <Tabs value="0">
      <TabList>
        <Tab value="0">{{ $t('common.player') }}</Tab>
        <Tab value="1">{{ $t('common.branding') }}</Tab>
        <Tab value="2">{{ $t('common.buttons') }}</Tab>
        <Tab value="3">{{ $t('common.questions') }}</Tab>
        <Tab value="4">{{ $t('common.comments') }}</Tab>
        <Tab value="6">{{ $t('common.magic_menu') }}</Tab>
        <Tab value="7">{{ $t('common.end_menu') }}</Tab>
        <Tab value="8">{{ $t('common.general') }}</Tab>
        <Tab value="9">{{ $t('common.translations') }}</Tab>
      </TabList>
      <TabPanels>

        <TabPanel value="0">
          <div>
            <h1 class="text-3xl font-bold mb-4">Player</h1>
            {{ designStore.design && designStore.design.id }}
            <!-- Hide Controls -->
            <div class="flex items-center mb-4">
              <Checkbox v-model="designStore.design.hide_controls" :binary="true" @update:modelValue="debouncedSave()"
                :label="$t('common.hide_controls')" />
              <label for="default-checkbox" class="ms-2 text-sm font-medium text-gray-900">{{ $t('common.hide_controls')
                }}</label>
            </div>

            <!-- Show Unmute -->
            <div v-if="designStore.design.hide_controls" class="flex items-center mb-4">
              <Checkbox v-model="designStore.design.show_unmute" :binary="true" @update:modelValue="debouncedSave()"
                :label="$t('common.show_unmute')" />
              <label for="default-checkbox" class="ms-2 text-sm font-medium text-gray-900">{{ $t('common.show_unmute')
                }}</label>
            </div>

            <div v-if="!designStore.design.hide_controls">
              <!-- Show Seekbar -->
              <div class="flex items-center mb-4">
                <Checkbox v-model="designStore.design.show_seekbar" :binary="true" @update:modelValue="debouncedSave()"
                  :label="$t('common.show_seekbar')" />
                <label for="default-checkbox" class="ms-2 text-sm font-medium text-gray-900">{{
                  $t('common.show_seekbar') }}</label>
              </div>

              <!-- Seeking -->
              <div class="flex items-center mb-4">
                <Checkbox v-model="designStore.design.seekbar_active" :binary="true" @update:modelValue="debouncedSave()"
                  :label="$t('common.allow_seeking')" />
                <label for="default-checkbox" class="ms-2 text-sm font-medium text-gray-900">{{
                  $t('common.allow_seeking') }}</label>
              </div>

              <!-- Markers -->
              <div class="flex items-center mb-4">
                <Checkbox v-model="designStore.design.show_markers" :binary="true" @update:modelValue="debouncedSave()"
                  :label="$t('common.show_markers')" />
                <label for="default-checkbox" class="ms-2 text-sm font-medium text-gray-900">{{
                  $t('common.show_markers') }}</label>
              </div>

              <!-- Rewind -->
              <div class="flex items-center mb-4">
                <Checkbox v-model="designStore.design.show_rewind" :binary="true" @update:modelValue="debouncedSave()"
                  :label="$t('common.show_rewind_button')" />
                <label for="default-checkbox" class="ms-2 text-sm font-medium text-gray-900">{{
                  $t('common.show_rewind_button') }}</label>
              </div>

              <!-- Fast Forward -->
              <div class="flex items-center mb-4">
                <Checkbox v-model="designStore.design.show_fastforward" :binary="true" @update:modelValue="debouncedSave()"
                  :label="$t('common.show_fast_forward_button')" />
                <label for="default-checkbox" class="ms-2 text-sm font-medium text-gray-900">{{
                  $t('common.show_fast_forward_button') }}</label>
              </div>

              <!-- Speed -->
              <div class="flex items-center mb-4">
                <Checkbox v-model="designStore.design.show_speed" :binary="true" @update:modelValue="debouncedSave()"
                  :label="$t('common.show_speed')" />
                <label for="default-checkbox" class="ms-2 text-sm font-medium text-gray-900">{{ $t('common.show_speed')
                  }}</label>
              </div>
            </div>

            <!-- Looping preview -->
            <div class="flex items-center mb-4">
              <Checkbox v-model="designStore.design.use_looping_preview" :binary="true" @update:modelValue="debouncedSave()"
                :label="$t('common.use_looping_preview')" />
              <label for="default-checkbox" class="ms-2 text-sm font-medium text-gray-900">{{
                $t('common.use_looping_preview') }}</label>
            </div>
          </div>
        </TabPanel>

        <TabPanel value="1">
          <div>
            <h1 class="text-3xl font-bold mb-4">Branding</h1>
            <!-- Accent Color -->
            {{ designStore.design.id }}
            {{ designStore.design.color }}
            <div>
              <CustomColorPicker :color="designStore.design.color" :gradientColor="designStore.design.color"
                @update:color="designStore.design.color = $event; debouncedSave()" />
              <label for="default-checkbox" class="ms-2 text-sm font-medium text-gray-900">{{ $t('common.accent_color')
                }}</label>
            </div>

            <!-- Play Button -->
            <div @click="playButtonModal = true" class="cursor-pointer">
              <div v-if="designStore.design.play_button_image">
                <img class="w-16 h-16" :src="designStore.design.play_button_image" />
              </div>
              <div v-else class="w-16 h-16 bg-gray-200 rounded-2xl flex items-center justify-center my-4"
                :style="{ backgroundColor: designStore.design.color }">
                <PlayButton class="w-12 h-12 text-white" />
              </div>
              <div class="grid grid-cols-2 my-2 cursor-pointer">
                <button @click.stop="playButtonModal = true"
                  class="text-sm font-medium text-blue-800 dark:text-blue-500"> {{ $t('common.change') }}</button>
                <button v-if="designStore.design.play_button_image"
                  @click.stop="designStore.design.play_button_image = ''; debouncedSave()"
                  class="text-sm font-medium text-blue-800 dark:text-blue-500">{{ $t('common.remove') }}</button>
              </div>



            </div>

            <!-- Thumbnail -->
            <div class="col-span-2">
              <h1 class="text-1xl font-bold mb-4">Thumbnail</h1>


              <!-- Image -->
              <div v-if="designStore.design.thumbnail">
                <div><img class="w-full max-w-96aspect-video object-contain border-2 border-gray-200 rounded-lg"
                    :src="designStore.design.thumbnail" /></div>
                <!-- ThumbnailActions -->
                <div class="grid grid-cols-2 my-2 cursor-pointer">
                  <button @click="thumbnailModal = true" class="text-sm font-medium text-blue-800 dark:text-blue-500">
                    {{ $t('common.change') }}</button>
                  <button @click="designStore.design.thumbnail = ''; debouncedSave()"
                    class="text-sm font-medium text-blue-800 dark:text-blue-500">{{ $t('common.remove') }}</button>
                </div>
              </div>

              <!-- Placeholder -->
              <div v-else @click="thumbnailModal = true" class="cursor-pointer max-w-96">
                <ImagePlaceholder @setThumbnail="setThumbnail" class="w-full aspect-video" />
                <div class="text-sm text-center font-medium text-blue-800 dark:text-blue-500 my-2"> {{
                  $t('common.change') }}</div>
              </div>

            </div>
          </div>
        </TabPanel>

        <TabPanel value="2">

          <!-- Buttons -->
          <div class="my-10">
            <h1 class="text-3xl font-bold mb-4">{{ $t('common.buttons') }}</h1>
            <div class="my-4 bg-gray-200 rounded-lg p-4 text-center">
              <!-- TODO extract out -->
              <Button class="py-2 px-4 rounded-md"
                :style="{ border: `2px solid ${designStore.design.button_border_color}`, backgroundColor: designStore.design.button_background_color, color: designStore.design.button_text_color, borderRadius: `${designStore.design.button_border_radius}px` }">{{
                  $t('common.sample_button') }}</Button>
            </div>
            <!-- Button Backgrouund Color -->
            <div>

              <CustomColorPicker :color="designStore.design.button_background_color"
                :gradientColor="designStore.design.button_background_color"
                @update:color="designStore.design.button_background_color = $event; debouncedSave()" />
              <label for="default-checkbox" class="ms-2 text-sm font-medium text-gray-900">{{
                $t('common.button_background_color') }}</label>
            </div>

            <!-- Button Border Color -->
            <div>
              <CustomColorPicker :color="designStore.design.button_border_color"
                :gradientColor="designStore.design.button_border_color"
                @update:color="designStore.design.button_border_color = $event; debouncedSave()" />
              <label for="default-checkbox" class="ms-2 text-sm font-medium text-gray-900">{{
                $t('common.button_border_color') }}</label>
              <label for="default-checkbox" class="ms-2 text-sm font-medium text-gray-900">{{
                $t('common.button_border_color') }}</label>
            </div>

            <!-- Button Text Color -->
            <div>
              <CustomColorPicker :color="designStore.design.button_text_color" :gradientColor="designStore.design.button_text_color"
                @update:color="designStore.design.button_text_color = $event; debouncedSave()" />
              <label for="default-checkbox" class="ms-2 text-sm font-medium text-gray-900">{{
                $t('common.button_text_color') }}</label>
              <label for="default-checkbox" class="ms-2 text-sm font-medium text-gray-900">{{
                $t('common.button_text_color') }}</label>
            </div>

            <!-- Button Border Radius -->
            <div class="relative my-6 w-48">
              <label for="labels-range-input" class="sr-only">Labels range</label>
              <input v-model="designStore.design.button_border_radius" @input="debouncedSave()" id="labels-range-input"
                type="range" min="0" max="20"
                class="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700">
              <span class="text-sm text-gray-500 dark:text-gray-400 absolute start-0 -bottom-6">Square</span>
              <span class="text-sm text-gray-500 dark:text-gray-400 absolute end-0 -bottom-6">Round</span>
            </div>
          </div>
        </TabPanel>

        <!-- End Buttons -->





        <TabPanel value="3">
          <!-- Start Questions -->
          <div class="my-10">
            <h1 class="text-3xl font-bold mb-4">{{ $t('common.questions') }}</h1>

            <!-- TOOD check gradients  -->
            <!-- Background Color -->
            <div>
              <CustomColorPicker :color="designStore.design.question_background_color"
                :gradientColor="designStore.design.question_background_color"
                @update:color="designStore.design.question_background_color = $event; debouncedSave()" />
              <label for="default-checkbox" class="ms-2 text-sm font-medium text-gray-900">{{
                $t('common.question_background_color') }}</label>
            </div>

            <!-- Text Color -->
            <div>
              <CustomColorPicker :color="designStore.design.question_text_color"
                @update:color="designStore.design.question_text_color = $event; debouncedSave()" />
              <label for="default-checkbox" class="ms-2 text-sm font-medium text-gray-900">{{
                $t('common.question_text_color') }}</label>
            </div>

            <!-- Answers Color -->
            <div>
              <CustomColorPicker :color="designStore.design.answers_color"
                @update:color="designStore.design.answers_color = $event; debouncedSave()" />
              <label for="default-checkbox" class="ms-2 text-sm font-medium text-gray-900">{{ $t('common.answers_color')
                }}</label>
            </div>

            <!-- Correct Color -->
            <div>
              <CustomColorPicker :color="designStore.design.correct_color"
                @update:color="designStore.design.correct_color = $event; debouncedSave()" />
              <label for="default-checkbox" class="ms-2 text-sm font-medium text-gray-900">{{ $t('common.correct_color')
                }}</label>
            </div>

            <!-- Incorrect Color -->
            <div>
              <CustomColorPicker :color="designStore.design.incorrect_color"
                @update:color="designStore.design.incorrect_color = $event; debouncedSave()" />
              <label for="default-checkbox" class="ms-2 text-sm font-medium text-gray-900">{{
                $t('common.incorrect_color') }}</label>
            </div>

            <!-- Break answers / full width -->
            <div class="my-2">
              <Checkbox v-model="designStore.design.break_answers" :binary="true" @update:modelValue="debouncedSave()" />
              <label for="default-checkbox" class="ms-2 text-sm font-medium text-gray-900">{{
                $t('common.full_width_answers') }}</label>
            </div>

            <!-- Show correctness -->
            <div class="my-2">
              <Checkbox v-model="designStore.design.question_correctness" :binary="true"
                @update:modelValue="debouncedSave()" />
              <label for="default-checkbox" class="ms-2 text-sm font-medium text-gray-900">{{
                $t('common.show_correctness') }}</label>
            </div>

            <!-- Show numbering -->
            <div class="my-2">
              <Checkbox v-model="designStore.design.show_question_numbering" :binary="true"
                @update:modelValue="debouncedSave()" />
              <label for="default-checkbox" class="ms-2 text-sm font-medium text-gray-900">{{
                $t('common.show_question_numbering') }}</label>
            </div>

            <!-- Enter to continue -->
            <div class="my-2">
              <Checkbox v-model="designStore.design.enter_continue" :binary="true" @update:modelValue="debouncedSave()" />
              <label for="default-checkbox" class="ms-2 text-sm font-medium text-gray-900">{{
                $t('common.enter_continue') }}</label>
            </div>

            <!-- Fullscreen mobile -->
            <div class="my-2">
              <Checkbox v-model="designStore.design.use_fullscreen_mobile" :binary="true"
                @update:modelValue="debouncedSave()" />
              <label for="default-checkbox" class="ms-2 text-sm font-medium text-gray-900">{{
                $t('common.use_fullscreen_mobile') }}</label>
            </div>

            <!-- TODO - Copying to MM and CTA? -->


            <!-- Question layout -->
            <LayoutPicker :left="designStore.design.question_left" :top="designStore.design.question_top"
              :width="designStore.design.question_width" :height="designStore.design.question_height"
              :background="designStore.design.question_background_color" :text="designStore.design.question_text_color"
              :answers="designStore.design.answers_color"
              @update:left="designStore.design.question_left = $event; debouncedSave()"
              @update:top="designStore.design.question_top = $event; debouncedSave()"
              @update:width="designStore.design.question_width = $event; debouncedSave()"
              @update:height="designStore.design.question_height = $event; debouncedSave()" />

            <!-- Transitions -->
            <div>
              <div>{{ $t('common.transition') }}: {{ designStore.design.question_transition || $t('common.none') }}</div>
              <a class="text-sm font-medium text-blue-800 dark:text-blue-500"
                @click="questionsTransitionModal = true">{{ $t('common.change') }}</a>
            </div>

          </div>
        </TabPanel>
        <!-- End questions -->

        <TabPanel value="4">
          <!-- Comments -->
          <div class="my-10">
            <h1 class="text-3xl font-bold mb-4">{{ $t('common.comments') }}</h1>
            <div class="my-4 bg-gray-200 rounded-lg p-4 text-center">
              <!-- TODO extract out -->
              <button class="py-2 px-4 rounded-md"
                :style="{ backgroundColor: designStore.design.comment_background_color, color: designStore.design.comment_text_color, borderRadius: `${designStore.design.comment_border_radius}px` }">{{
                  $t('common.sample_comment') }}</button>
            </div>
            <!-- Comment Backgrouund Color -->
            <div>
              <CustomColorPicker :color="designStore.design.comment_background_color"
                :gradientColor="designStore.design.comment_background_color"
                @update:color="designStore.design.comment_background_color = $event; debouncedSave()" />
              <label for="default-checkbox" class="ms-2 text-sm font-medium text-gray-900">{{
                $t('common.comment_background_color') }}</label>
              <label for="default-checkbox" class="ms-2 text-sm font-medium text-gray-900">{{
                $t('common.comment_background_color') }}</label>
            </div>

            <!-- Comment Text Color -->
            <div>
              <CustomColorPicker :color="designStore.design.comment_text_color"
                :gradientColor="designStore.design.comment_text_color"
                @update:color="designStore.design.comment_text_color = $event; debouncedSave()" />
              <label for="default-checkbox" class="ms-2 text-sm font-medium text-gray-900">{{
                $t('common.comment_text_color') }}</label>
              <label for="default-checkbox" class="ms-2 text-sm font-medium text-gray-900">{{
                $t('common.comment_text_color') }}</label>
            </div>

            <!-- Comment Border Radius -->
            <div class="relative my-6 w-48">
              <label for="labels-range-input" class="sr-only">Labels range</label>
              <input v-model="designStore.design.comment_border_radius" @input="debouncedSave()" id="labels-range-input"
                type="range" min="0" max="20"
                class="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700">
              <span class="text-sm text-gray-500 dark:text-gray-400 absolute start-0 -bottom-6">Square</span>
              <span class="text-sm text-gray-500 dark:text-gray-400 absolute end-0 -bottom-6">Round</span>
            </div>

            <!-- Comment Positioning -->
            <div class="relative my-6 w-48">
              <Select v-model="designStore.design.comments_align" :options="commentAlignOptions" @change="debouncedSave()"
                optionLabel="text" optionValue="value" placeholder="Top Left" class="w-full md:w-56" />
            </div>

            <!-- Avatar -->
            <div class="flex items-center mb-4">
              <Checkbox v-model="designStore.design.comment_show_avatar" :binary="true" @update:modelValue="debouncedSave()"
                :label="$t('common.comment_show_avatar')" />
              <label for="default-checkbox" class="ms-2 text-sm font-medium text-gray-900">{{
                $t('common.comment_show_avatar') }}</label>
            </div>
          </div>
          <!-- End Comments -->
        </TabPanel>




        <TabPanel value="6">
          <!-- Magic Menu -->
          <!-- TODO make sure we have a MM -->
          <div v-if="designStore.design.magic_menu" class="my-10">
            <h1 class="text-3xl font-bold mb-4">{{ $t('common.magic_menu') }}</h1>

            <!-- TOOD check gradients  -->
            <!-- Background Color -->
            <div>
              <CustomColorPicker :color="designStore.design.magic_menu.color" :gradientColor="designStore.design.magic_menu.color"
                @update:color="designStore.design.magic_menu.color = $event; debouncedUpdateMagicMenu()" />
              <label for="default-checkbox" class="ms-2 text-sm font-medium text-gray-900">{{
                $t('common.background_color') }}</label>
            </div>

            <!-- Break answers / full width -->
            <div class="my-2">
              <Checkbox v-model="designStore.design.magic_menu.pause" :binary="true"
                @update:modelValue="debouncedUpdateMagicMenu()" />
              <label for="default-checkbox" class="ms-2 text-sm font-medium text-gray-900">{{
                $t('common.pause_for_menu') }}</label>
            </div>

            <!-- Show correctness -->
            <div class="my-2">
              <Checkbox v-model="designStore.design.magic_menu.show_on_pause" :binary="true"
                @update:modelValue="debouncedUpdateMagicMenu()" />
              <label for="default-checkbox" class="ms-2 text-sm font-medium text-gray-900">{{ $t('common.show_on_pause')
                }}</label>
            </div>

            <!-- Show numbering -->
            <div class="my-2">
              <Checkbox v-model="designStore.design.magic_menu.visible" :binary="true"
                @update:modelValue="debouncedUpdateMagicMenu()" />
              <label for="default-checkbox" class="ms-2 text-sm font-medium text-gray-900">{{
                $t('common.magic_menu_icon_visible') }}</label>
            </div>

            <!-- Magic Menu layout -->
            <LayoutPicker :left="designStore.design.magic_menu.left" :top="designStore.design.magic_menu.top"
              :width="designStore.design.magic_menu.width" :height="designStore.design.magic_menu.height"
              :background="designStore.design.magic_menu.color" :text="designStore.design.magic_menu.text_color"
              @update:left="designStore.design.magic_menu.left = $event; debouncedUpdateMagicMenu()"
              @update:top="designStore.design.magic_menu.top = $event; debouncedUpdateMagicMenu()"
              @update:width="designStore.design.magic_menu.width = $event; debouncedUpdateMagicMenu()"
              @update:height="designStore.design.magic_menu.height = $event; debouncedUpdateMagicMenu()" />

            <!-- Transitions -->
            <div>
              <div>{{ $t('common.transition') }}: {{ designStore.design.magic_menu.transition || $t('common.none') }}</div>
              <a class="text-sm font-medium text-blue-800 dark:text-blue-500" @click="menuTransitionModal = true">{{
                $t('common.change') }}</a>
            </div>

          </div>
          <!-- End Magic Menu -->




        </TabPanel>

        <TabPanel value="7">

          <!-- Start END Menu -->
          <!-- TODO make sure we have one -->
          <div v-if="designStore.design.end_menu" class="my-10">
            <h1 class="text-3xl font-bold mb-4">{{ $t('common.end_menu') }}</h1>

            <!-- TOOD check gradients  -->
            <!-- Background Color -->
            <div>
              <CustomColorPicker :color="designStore.design.end_menu.color" :gradientColor="designStore.design.end_menu.color"
                @update:color="designStore.design.end_menu.color = $event; debouncedUpdateEndMenu()" />
              <label for="default-checkbox" class="ms-2 text-sm font-medium text-gray-900">{{
                $t('common.background_color') }}</label>
            </div>

            <!-- Break answers / full width -->
            <div class="my-2">
              <Checkbox v-model="designStore.design.end_menu.pause" :binary="true"
                @update:modelValue="debouncedUpdateEndMenu()" />
              <label for="default-checkbox" class="ms-2 text-sm font-medium text-gray-900">{{
                $t('common.pause_for_menu') }}</label>
            </div>

            <!-- Show correctness -->
            <div class="my-2">
              <Checkbox v-model="designStore.design.end_menu.show_on_pause" :binary="true"
                @update:modelValue="debouncedUpdateEndMenu()" />
              <label for="default-checkbox" class="ms-2 text-sm font-medium text-gray-900">{{ $t('common.show_on_pause')
                }}</label>
            </div>

            <!-- Show numbering -->
            <div class="my-2">
              <Checkbox v-model="designStore.design.end_menu.visible" :binary="true"
                @update:modelValue="debouncedUpdateEndMenu()" />
              <label for="default-checkbox" class="ms-2 text-sm font-medium text-gray-900">{{
                $t('common.magic_menu_icon_visible') }}</label>
            </div>

            <!-- Magic Menu layout -->
            <LayoutPicker :left="designStore.design.end_menu.left" :top="designStore.design.end_menu.top"
              :width="designStore.design.end_menu.width" :height="designStore.design.end_menu.height"
              :background="designStore.design.end_menu.color" :text="designStore.design.end_menu.text_color"
              @update:left="designStore.design.end_menu.left = $event; debouncedUpdateEndMenu()"
              @update:top="designStore.design.end_menu.top = $event; debouncedUpdateEndMenu()"
              @update:width="designStore.design.end_menu.width = $event; debouncedUpdateEndMenu()"
              @update:height="designStore.design.end_menu.height = $event; debouncedUpdateEndMenu()" />

            <!-- Transitions -->
            <div>
              <div>{{ $t('common.transition') }}: {{ designStore.design.end_menu.transition || $t('common.none') }}</div>
              <a class="text-sm font-medium text-blue-800 dark:text-blue-500" @click="endTransitionModal = true">{{
                $t('common.change') }}</a>
            </div>

          </div>
          <!-- Finish END Menu -->

        </TabPanel>


        <TabPanel value="8">

          <!-- Start GENERAL -->
          <div class="my-10">
            <h1 class="text-3xl font-bold mb-4">{{ $t('common.general') }}</h1>

            <!-- Message Background Color -->
            <div>
              <CustomColorPicker :color="designStore.design.message_background_color"
                :gradientColor="designStore.design.message_background_color"
                @update:color="designStore.design.message_background_color = $event; debouncedSave()" />
              <label for="default-checkbox" class="ms-2 text-sm font-medium text-gray-900">{{
                $t('common.message_background_color') }}</label>
            </div>

            <!-- Message Text Color -->
            <div>
              <CustomColorPicker :color="designStore.design.question_text_color"
                @update:color="designStore.design.question_text_color = $event; debouncedSave()" />
              <label for="default-checkbox" class="ms-2 text-sm font-medium text-gray-900">{{
                $t('common.question_text_color') }}</label>
            </div>

            <!-- Video Background Color -->
            <div class="relative my-6 w-48">
              <Select v-model="designStore.design.video_background_color"
                :options="[{ text: 'White', value: 'white' }, { text: 'Black', value: 'black' }]" @change="debouncedSave()"
                optionLabel="text" optionValue="value" class="w-full md:w-56" />
            </div>

            <!-- Fade audio -->
            <div class="my-2">
              <Checkbox v-model="designStore.design.fade_audio" :binary="true" @update:modelValue="debouncedSave()" />
              <label for="default-checkbox" class="ms-2 text-sm font-medium text-gray-900">{{ $t('common.fade_audio')
                }}</label>
            </div>

            <!-- Show countdown bar -->
            <div class="my-2">
              <Checkbox v-model="designStore.design.show_countdown_bar" :binary="true" @update:modelValue="debouncedSave()" />
              <label for="default-checkbox" class="ms-2 text-sm font-medium text-gray-900">{{
                $t('common.show_countdown_bar') }}</label>
            </div>

            <!-- Show countdown timer -->
            <div v-if="designStore.design.show_countdown_bar" class="my-2">
              <Checkbox v-model="designStore.design.show_countdown" :binary="true" @update:modelValue="debouncedSave()" />
              <label for="default-checkbox" class="ms-2 text-sm font-medium text-gray-900">{{
                $t('common.show_countdown_seconds') }}</label>
            </div>

            <!-- Guide Chapters -->
            <div class="my-2">
              <Checkbox v-model="designStore.design.guide_chapters" :binary="true" @update:modelValue="debouncedSave()" />
              <label for="default-checkbox" class="ms-2 text-sm font-medium text-gray-900">{{
                $t('common.show_guide_chapters') }}</label>
            </div>

            <!-- Guide Interactions -->
            <div class="my-2">
              <Checkbox v-model="designStore.design.guide_interactions" :binary="true" @update:modelValue="debouncedSave()" />
              <label for="default-checkbox" class="ms-2 text-sm font-medium text-gray-900">{{
                $t('common.show_guide_interactions') }}</label>
            </div>

            <!-- Guide Transcripts -->
            <div class="my-2">
              <Checkbox v-model="designStore.design.guide_transcripts" :binary="true" @update:modelValue="debouncedSave()" />
              <label for="default-checkbox" class="ms-2 text-sm font-medium text-gray-900">{{
                $t('common.show_guide_transcripts') }}</label>
            </div>

            <!-- Pin Chapters -->
            <div class="my-2">
              <Checkbox v-model="designStore.design.static_chapters" :binary="true" @update:modelValue="debouncedSave()" />
              <label for="default-checkbox" class="ms-2 text-sm font-medium text-gray-900">{{
                $t('common.static_chapters') }}</label>
            </div>

            <!-- Pin Cart -->
            <div class="my-2">
              <Checkbox v-model="designStore.design.static_cart" :binary="true" @update:modelValue="debouncedSave()" />
              <label for="default-checkbox" class="ms-2 text-sm font-medium text-gray-900">{{ $t('common.static_cart')
                }}</label>
            </div>






            <!-- Message layout -->
            <LayoutPicker :left="designStore.design.message_left" :top="designStore.design.message_top"
              :width="designStore.design.message_width" :height="designStore.design.message_height"
              :background="designStore.design.message_background_color" :text="designStore.design.message_text_color"
              :answers="designStore.design.answers_color" @update:left="designStore.design.message_left = $event; debouncedSave()"
              @update:top="designStore.design.message_top = $event; debouncedSave()"
              @update:width="designStore.design.message_width = $event; debouncedSave()"
              @update:height="designStore.design.message_height = $event; debouncedSave()" />

            <!-- Message Transition -->
            <div>
              <div>{{ $t('common.message_transition') }}: {{ designStore.design.message_transition || $t('common.none') }}
              </div>
              <a class="text-sm font-medium text-blue-800 dark:text-blue-500" @click="messageTransitionModal = true">{{
                $t('common.change') }}</a>
            </div>

            <!-- Interactions Transition -->
            <div>
              <div>{{ $t('common.interactions_transition') }}: {{ designStore.design.interactions_transition ||
                $t('common.none') }}</div>
              <a class="text-sm font-medium text-blue-800 dark:text-blue-500"
                @click="interactionsTransitionModal = true">{{ $t('common.change') }}</a>
            </div>

            <!-- Switch Transition -->
            <!-- TODO these transitions don't match up with existing up, right, left, down, etc. -->
            <div>
              <div>{{ $t('common.switch_transition') }}: {{ designStore.design.switch_transition || $t('common.none') }}</div>
              <a class="text-sm font-medium text-blue-800 dark:text-blue-500" @click="switchTransitionModal = true">{{
                $t('common.change') }}</a>
            </div>

          </div>
          <!-- End GENERAL -->
        </TabPanel>


        <TabPanel value="9">
          <!-- TODO autosave translations -->
          <Translations />
        </TabPanel>
      </TabPanels>
    </Tabs>

    <!-- Keys -->
    <div v-if="false" class=" my-10 col-span-3">
      <h1 class="text-3xl font-bold mb-4">KEYS TODO</h1>
      <ul>
        <li v-for="(item, key, i) in designStore.design" :key="key">
          {{ key }}: {{ item }}
        </li>
      </ul>
    </div>





    <!-- Modals -->
    <div>

      <!-- Thumbnail picker -->
      <Dialog v-model:visible="thumbnailModal" modal :header="$t('common.choose_image')" class="w-4/5 max-w-4xl">
        <ImagePicker @setImage="setThumbnail" />
      </Dialog>

      <!-- Playbutton picker -->
      <Dialog v-model:visible="playButtonModal" modal :header="$t('common.choose_image')" class="w-4/5 max-w-4xl">
        <ImagePicker @setImage="setPlayButton" />
      </Dialog>


      <!-- Interactions Transition -->
      <Dialog v-model:visible="interactionsTransitionModal" modal :header="$t('common.choose_transition')"
        class="w-4/5 max-w-8xl">
        <TransitionPicker :value="designStore.design.interactions_transition" @setTransition="setInteractionsTransition" />
      </Dialog>

      <!-- Questions Transition -->
      <Dialog v-model:visible="questionsTransitionModal" modal :header="$t('common.choose_transition')"
        class="w-4/5 max-w-8xl">
        <TransitionPicker :value="designStore.design.questions_transition" @setTransition="setQuestionsTransition" />
      </Dialog>

      <!-- Message Transition -->
      <Dialog v-model:visible="messageTransitionModal" modal :header="$t('common.choose_transition')"
        class="w-4/5 max-w-8xl">
        <TransitionPicker :value="designStore.design.message_transition" @setTransition="setMessageTransition" />
      </Dialog>

      <!-- Switch Transition -->
      <Dialog v-model:visible="switchTransitionModal" modal :header="$t('common.choose_transition')"
        class="w-4/5 max-w-8xl">
        <TransitionPicker :value="designStore.design.switch_transition" @setTransition="setSwitchTransition" />
      </Dialog>

      <!-- Menu Transition -->
      <Dialog v-model:visible="menuTransitionModal" modal :header="$t('common.choose_transition')"
        class="w-4/5 max-w-8xl">
        <TransitionPicker :value="designStore.design.magic_menu.transition" @setTransition="setMenuTransition" />
      </Dialog>

      <!-- End Transition -->
      <Dialog v-model:visible="endTransitionModal" modal :header="$t('common.choose_transition')"
        class="w-4/5 max-w-8xl">
        <TransitionPicker :value="designStore.design.end_menu.transition" @setTransition="setEndTransition" />
      </Dialog>

    </div>
  </div>

</template>