<script setup>

import { ColorPicker } from "vue3-colorpicker";
import "vue3-colorpicker/style.css";

const props = defineProps({
  // TODO handle bad props
  color: {
    // type: String,
    // required: true
  },
  gradientColor: {
    // type: String,
    // required: false
  },
  blurClose: {
    type: Boolean,
    default: true
  },
  shape: {
    type: String,
    default: 'square'
  }
});

import { defineEmits } from 'vue'
const emit = defineEmits(['update:color', 'update:gradientColor'])

const useType = computed(() => {
  return props.color && props.gradientColor || isGradient(props.color) ? 'both' : props.gradientColor ? 'gradient' : 'pure'
});

const updateColor = (color) => {
  console.log("Update color: ", color)
  emit('update:color', color)
}

const isGradient = (color) => {
  if (!color) {
    return false
  }
  const gradientKeywords = [
    'linear-gradient',
    'radial-gradient',
    'conic-gradient',
    'repeating-linear-gradient',
    'repeating-radial-gradient'
  ];
  
  // Return true if any of the gradient keywords is found in the color string
  return gradientKeywords.some(keyword => color.includes(keyword));
}

</script>

<template>
  <!-- TODO lang -->
  <!-- TODO fix displaying gradients -->
  <!-- TODO centering -->
  <ColorPicker 
    class="custom-color-picker"
    :useType="useType" 
    :blurClose="true" 
    format="hex" 
    lang="En" 
    :shape="props.shape"
    :pureColor="color"
    :gradientColor="isGradient(color) ? gradientColor : ''"
    @update:pureColor="updateColor" 
    @update:gradientColor="updateColor">
    <template #extra>
      <button @click="updateColor('#000000')">Reset Color</button>
    </template>
  </ColorPicker>
</template>

<style scoped>
.custom-color-picker {
  border-radius: 10px;
}
</style>