<template>
  <section class="container mx-auto">
    SCRATCH
    <ColorPicker useType="both" lang="En" :gradientColor="color" @update:pureColor="updateColor" @update:gradientColor="updateColor" ></ColorPicker>

    PureColor: {{ color }}

    <div>

      <div class="w-20 h-20" :style="{background: color }">

      </div>
    </div>
  </section>
</template>

<script setup>  
import { ColorPicker } from "vue3-colorpicker";
import "vue3-colorpicker/style.css";
var color = ref('linear-gradient(45deg, blue, red)')
const updateColor = (arg) => {
  console.log(arg)
  color.value = arg
}

onMounted(() => {
});
</script>
