<template>
  <div>
    <div class="grid grid-cols-4">
      <div class="text-center">
        Full
        <div @click="setFull" class="w-16 h-12 bg-gray-200 rounded-md mx-auto my-2 relative cursor-pointer" :class="{ 'border-4 border-yellow-500': whichLayout.full }">
          <div class="w-full h-full absolute top-0 left-0" :style="{ background: props.background }">
          </div>
        </div>
      </div>
      <div class="text-center">
        Left
        <div @click="setLeft" class="w-16 h-12 bg-gray-200 rounded-md mx-auto my-2 relative cursor-pointer" :class="{ 'border-4 border-yellow-500': whichLayout.left }">
          <div class="w-1/2 h-full absolute top-0 left-0 border-2 rounded-md border-black" :style="{ background: props.background }">
          </div>
        </div>
      </div>
      <div class="text-center">
        Right
        <div @click="setRight" class="w-16 h-12 bg-gray-200 rounded-md mx-auto my-2 relative cursor-pointer" :class="{ 'border-4 border-yellow-500': whichLayout.right }">
          <div class="w-1/2 h-full absolute top-0 right-0 border-2 rounded-md border-black" :style="{ background: props.background }">
          </div>
        </div>
      </div>
      <div class="text-center">
        Custom
        <div @click="custom = true" class="w-16 h-12 bg-gray-200 rounded-md mx-auto my-2 relative cursor-pointer" :class="{ 'border-4 border-yellow-500': whichLayout.custom }">
          <div class="w-full h-full absolute top-0 left-0 rounded-md">
          </div>
        </div>
      </div>
    </div>
    <div v-if="custom || whichLayout.custom" class="grid grid-cols-4 text-center mt-3">
      <div>
        <div>Left</div>
        <InputNumber v-model="left" @input="customDebounce" suffix="%" inputId="horizontal-buttons" showButtons buttonLayout="vertical" :step="10" />
      </div>
      <div>
        <div>Top</div>
        <InputNumber v-model="top" @input="customDebounce" suffix="%" inputId="horizontal-buttons" showButtons buttonLayout="vertical" :step="10" />
      </div>
      <div>
        <div>Width</div>
        <InputNumber v-model="width" @input="customDebounce" suffix="%" inputId="horizontal-buttons" showButtons buttonLayout="vertical" :step="10" />
      </div>
      <div>
        <div>Height</div>
        <InputNumber v-model="height" @input="customDebounce" suffix="%" inputId="horizontal-buttons" showButtons buttonLayout="vertical" :step="10" />
      </div>
    </div>
  </div>
</template>


<script setup>
import InputNumber from 'primevue/inputnumber'
import { onMounted } from 'vue';
import { debounce } from 'lodash';

const props = defineProps({
  background: {
    type: String,
    required: true,
  },
  text: {
    type: String,
    required: false,
  },
  answers: {
    type: String,
    required: false,
  },
  left: { 
    required: true,
  },
  top: { 
    required: true,
  },
  width: { 
    required: true,
  },
  height: { 
    required: true,
  },
});

const left = ref(parseInt(props.left))
const top = ref(parseInt(props.top))
const width = ref(parseInt(props.width))
const height = ref(parseInt(props.height))


const customDebounce = debounce(() => {
  console.log("Custom Debounce")
  emit('update:left', left.value)
  emit('update:top', top.value)
  emit('update:width', width.value)
  emit('update:height', height.value)
}, 1000)

const emit = defineEmits(['update:left', 'update:top', 'update:width', 'update:height'])


const custom = ref(false)
const setLeft = () => {
  emit('update:left', 0)
  emit('update:top', 0)
  emit('update:width', 50)
  emit('update:height', 100)

  left.value = 0
  top.value = 0
  width.value = 50
  height.value = 100
}
const setRight = () => {
  emit('update:left', 50)
  emit('update:top', 0)
  emit('update:width', 50)
  emit('update:height', 100)

  left.value = 50
  top.value = 0
  width.value = 50
  height.value = 100
}
const setFull = () => {
  emit('update:left', 0)
  emit('update:top', 0)
  emit('update:width', 100)
  emit('update:height', 100)

  left.value = 0
  top.value = 0
  width.value = 100
  height.value = 100
}

const setCustom = () => {
  custom.value = true
}

// Determines if any layouts match for highlighting
const whichLayout = computed(() => {
  const left = props.left == 0 && props.top == 0 && props.width == 50 && props.height == 100
  const right = props.left == 50 && props.top == 0 && props.width == 50 && props.height == 100
  const full = props.left == 0 && props.top == 0 && props.width == 100 && props.height == 100
  const custom = !left && !right && !full
  return { left: left, right: right, full: full, custom:  custom }
})


</script>