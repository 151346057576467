import { defineStore } from 'pinia'

export const GroupStore = defineStore('group', {
  state: () => {
    return {
      groups: [],
      group: {},
      loading: false,
      columns: ['CREATED', 'NAME', 'VIDEOS', 'MEMBERS', 'VIEWS', 'INTERACTIONS'],
      pagination: {page: 1},
      busy: false
    }
  },

  actions: {

    async create(data) {
      return this.Api.post('/groups', data).then(response => {
        console.log(response.data)
        this.groups.unshift(response.data.group);
        return response.data.group;
      });
    },

    async updateVideos(data) {
      return this.Api.post(`/groups/${this.group.id}/videos`, data).then(response => {
        console.log(response.data)
        this.group.videos = response.data.group.videos
      });
    },

    async update() {
      this.busy = true
      return this.Api.put(`/groups/${this.group.id}`, { group: { name: this.group.name, description: this.group.description, facebook_pixel_id: this.group.facebook_pixel_id, gtag: this.group.gtag } }).then(response => {
        this.group = response.data.group
        this.busy = false
      })
    },

    async index(path="/groups") {
      this.loading = true
      return this.Api.get(path).then(response => {  
        this.pagination = response.data.pagination;
        this.groups = response.data.groups;        
        this.loading = false
      })  
    },

    async show(id) {
      this.loading = true
      if (id != this.group.id) {
        this.group = {}
      }
      return this.Api.get(`/groups/${id}`).then(response => {  
        this.group = response.data.group;        
        this.loading = false
      })  
    },

    async destroy(id) {      
      return this.Api.destroy(`/groups/${id}`).then(response => {  
        this.loading = false
      }).catch(error => {
        this.errors = error.response.data.errors;
        this.loading = false
      }) 
    },

    // TODO maybe move these to invites store?
    async createInvite(data) {
      return this.Api.post(`/invites`, data).then(response => {
        return response.data.invite
      })
    },

    async remindInvite(id) {
      return this.Api.post(`/invites/${id}/remind`).then(response => {
        return response.data.invite
      })
    },

    async retractInvite(id) {
      return this.Api.destroy(`/invites/${id}`).then(response => {
        return response.data.invite
      })
    }
  }
})