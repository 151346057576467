<script setup>
import { SeriesStore } from '@/front/stores/series_store.js'
const seriesStore = SeriesStore()
import { AssetStore } from '@/front/stores/asset_store.js'
const assetStore = AssetStore()
import { useToast } from "primevue/usetoast";
const toast = useToast();
import { useI18n } from 'vue-i18n'
const { t } = useI18n({});
import RadioButton from 'primevue/radiobutton';
import InputText from 'primevue/inputtext';
import Select from 'primevue/select';
import Button from 'primevue/button';
import { Delete02Icon } from 'hugeicons-vue';
const busy = ref(false)


const updateSeries = () => {
  busy.value = true
  seriesStore.update({ name: seriesStore.series.name, description: seriesStore.series.description, privacy: seriesStore.series.privacy, password: seriesStore.series.password, access_list_id: seriesStore.series.access_list_id }).then(() => {
    busy.value = false
    toast.add({ title: t('common.success'), summary: t('common.series_updated'), severity: 'success', life: 3000 })
  }).catch((error) => {
    console.log("💾 SeriesManage updateSeries error", error)
    toast.add({ title: t('common.error'), summary: error.response.data.message, severity: 'error', life: 3000 })
    busy.value = false
  }).finally(() => {
    busy.value = false
  })
}
const canSaveSeries = computed(() => {
  if (seriesStore.series.privacy == 1 && (seriesStore.series.password || "").length == 0) {
    return false
  }
  if (seriesStore.series.privacy == 3 && !seriesStore.series.access_list_id) {
    return false
  }
  return seriesStore.series.name && seriesStore.series.description
})

const password = ref('')
const addPassword = () => {
  seriesStore.series.password = seriesStore.series.password || []
  seriesStore.series.password.push(password.value)
  password.value = ''
}
const removePassword = (password) => {
  seriesStore.series.password = seriesStore.series.password.filter(t => t != password)
}


</script>

<template>
  <div class="max-w-md mx-auto">
    <div class="text-left mb-4">
      <label class="font-medium text-lg">{{ $t('common.name') }}</label>
      <InputText v-model="seriesStore.series.name" class="w-full mt-1" :placeholder="$t('common.name')" />
    </div>
    <div class="text-left mb-4">
      <label class="font-medium text-lg">{{ $t('common.description') }}</label>
      <InputText v-model="seriesStore.series.description" class="w-full mt-1"
        :placeholder="$t('common.description')" />
    </div>
    <div class="text-left mb-4">
      <label class="font-medium text-lg">{{ $t('common.privacy') }}</label>
      <div class="flex items-center my-2">
        <RadioButton v-model="seriesStore.series.privacy" :value="0" variant="filled" />
        <label class="ml-2">{{
          $t('common.privacy_link') }}
        </label>
      </div>
      <div class="flex items-center my-2">
        <RadioButton v-model="seriesStore.series.privacy" :value="3" variant="filled" />
        <label class="ml-2">{{
          $t('common.privacy_access_list') }}
        </label>
      </div>
      <div v-if="seriesStore.series.privacy == 3">
        <Select v-model="seriesStore.series.access_list_id" :options="assetStore.lists" class="mt-1 mb-4 w-full"
          :placeholder="$t('common.choose_access_list')" optionLabel="name" optionValue="id" />
        <div class="text-center">
          <Button size="small" outlined>
            <router-link :to="`/account/organization/access-lists`">{{ $t('common.new_access_list')
              }}</router-link>
          </Button>
        </div>
      </div>
      <div class="flex items-center my-2">
        <RadioButton v-model="seriesStore.series.privacy" :value="1" variant="filled" />
        <label class="ml-2">{{ $t('common.privacy_password') }}</label>
      </div>
      <div v-if="seriesStore.series.privacy == 1" class="text-center mt-2">
        <div class="flex flex-wrap mb-2">
          <div v-for="password in seriesStore.series.password" :key="password" @click="removePassword(password)"
            class="bg-gray-200 rounded-md px-4 py-2 m-1 cursor-pointer inline-flex items-center">
            <Delete02Icon class="w-4 h-4 mr-2" />
            {{ password }}
          </div>
        </div>
        <InputText v-model="password" class="w-full mt-1" :placeholder="$t('common.enter_password')" />
        <Button @click="addPassword" size="small" class="mt-2" :disabled="password.length == 0">{{
          $t('common.add_password') }}</Button>
      </div>
    </div>
    <Button @click="updateSeries" :disabled="!canSaveSeries" :loading="busy" size="large" class="mt-4">{{
      $t('common.save') }}</Button>
  </div>
</template>