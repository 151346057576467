<script setup>
import { UserStore } from '@/front/stores/user_store.js'
const userStore = UserStore();
import { useRoute } from 'vue-router'
const router = useRouter()
import { useToast } from 'primevue/usetoast';
const toast = useToast();
import { useI18n } from 'vue-i18n'
const { t } = useI18n({});
import { StringComposer } from '@/front/composables/StringComposer.js'
const { formatCents } = StringComposer();
import { DateComposer } from '@/front/composables/DateComposer.js'
const { daysFromNow, dmyyyy } = DateComposer();


import Button from 'primevue/button'
import RadioButton from 'primevue/radiobutton'
import TextArea from 'primevue/textarea'
import Checkbox from 'primevue/checkbox'
const busy = ref(false)

const reasons = ref([
  { label: t('common.missing_features'), value: 'Missing Features' },
  { label: t('common.product_quality_less_than_expected'), value: 'Product Quality Less Than Expected' },
  { label: t('common.ease_of_use_less_than_expected'), value: 'Ease of Use Less Than Expected' },
  { label: t('common.support_quality_less_than_expected'), value: 'Support Quality Less Than Expected' },
  { label: t('common.found_an_alternative'), value: 'Found an Alternative' },
  { label: t('common.budget'), value: 'Budget' },
  { label: t('common.no_longer_need_it'), value: 'No Longer Need It (Project Based)' },
  { label: t('common.other'), value: 'Other' },
])
const reasonSelected = ref(null)
const reasonAnswer = ref('')

const secondQuestion = computed(() => {
  switch (reasonSelected.value) {
    case 'Missing Features':
      return t('common.missing_features_answer')
    case 'Product Quality Less Than Expected':
      return t('common.product_quality_less_than_expected_answer')
    case 'Ease of Use Less Than Expected':
      return t('common.ease_of_use_less_than_expected_answer')
    case 'Support Quality Less Than Expected':
      return t('common.support_quality_less_than_expected_answer')
    case 'Found an Alternative':
      return t('common.found_an_alternative_answer')
    case 'No Longer Need It (Project Based)':
      return t('common.no_longer_need_it_answer')
    case 'Other':
      return t('common.please_tell_us_why')
    case 'Budget':
      return t('common.budget_answer')
    default:
      return ''
  }
})

const keepSubscription = () => {
  router.push('/')
}


const cancelSubscription = () => {
  busy.value = true
  userStore.cancelSubscription(reasonSelected.value, reasonAnswer.value).then(() => {
    toast.add({
      title: t('common.subscription_canceled'),
      severity: 'success',
      life: 3000
    })
    router.push('/account/billing')
  }).finally(() => {
    busy.value = false
  })
}

</script>

<template>
  <div>
    <div class="p-4">
      <div class="p-8 mb-96 text-center bg-white w-fit mx-auto rounded-2xl shadow-xl mt-8 min-w-[60dvw]">
        <h1 class="text-4xl font-bold">{{ $t('common.confirm_cancellation') }}</h1>
        <div class="mt-4 text-xl text-center max-w-lg mx-auto">
          <div class="my-4 text-2xl font-bold mt-8"> {{ $t('common.why_cancelling') }}</div>
          <div class="w-full mx-auto text-left">
            <div v-for="reason in reasons" :key="reason.value" class="mb-4">
              <RadioButton :value="reason.value" v-model="reasonSelected" />
              <label class="ml-2">{{ reason.label }}</label>
            </div>
          </div>
          <div v-if="secondQuestion" class="mt-4" >
            <div class="my-4 text-xl font-bold mt-8"> {{ secondQuestion }}</div>
            <TextArea v-model="reasonAnswer" class="w-full" :placeholder="t('common.please_provide_details')" />
          </div>
          <div v-if="reasonAnswer" class="mt-4" >
            <label class="ml-2 text-sm">{{ $t('common.consent_to_cancellation_text') }}</label>
          </div>
          <div class="mt-8 flex justify-center gap-4">
            <Button :disabled="!reasonSelected || !reasonAnswer" :loading="busy" :label="t('common.cancel_subscription')" @click="cancelSubscription" severity="danger"/>
            <Button :label="t('common.keep_my_subscription')" :loading="busy" @click="keepSubscription" severity="success"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
td {
  @apply p-2;
  @apply border border-gray-200;
}
</style>
