<script setup>
import { UserStore } from '@/front/stores/user_store.js'
const userStore = UserStore();
import { useRoute } from 'vue-router'
const router = useRouter()
import { useToast } from 'primevue/usetoast';
const toast = useToast();
import { useI18n } from 'vue-i18n'
const { t } = useI18n({});
import { StringComposer } from '@/front/composables/StringComposer.js'
const { formatCents } = StringComposer();
import { DateComposer } from '@/front/composables/DateComposer.js'
const { daysFromNow, dmyyyy } = DateComposer();

const loading = ref(true)

import Button from 'primevue/button'
onMounted(() => {
  userStore.fetchStats().then(() => {
    console.log(userStore.stats)
    loading.value = false
  })
});

const pauseLabel = computed(() => {
  var str = t('common.pause_subscription_for') + ' '
  if (userStore.organization.plan == 'starter') {
    str+= '$9 / ' + t('common.month')
  } else if (userStore.organization.plan == 'core') {
    str+= '$29 / ' + t('common.month')
  } else if (userStore.organization.plan == 'pro') {
    str+= '$49 /  ' + t('common.month')
  } else if (userStore.organization.plan == 'enterprise') {
    str+= '$79 / ' + t('common.month')
  }
  return str
})

const busy = ref(false)

const pauseSubscription = () => {
  busy.value = true
  userStore.pauseSubscription().then(() => {
    toast.add({
      title: t('common.subscription_paused'),
      severity: 'success',
      summary: t('common.subscription_paused_summary'),
      detail: t('common.subscription_paused_detail'),
    })
    router.push('/account/billing')
  }).finally(() => {
    busy.value = false
  })
}

</script>

<template>
  <div>
    <div class="p-4">
      <div class="p-8 mb-96 text-center bg-white w-fit mx-auto rounded-2xl shadow-xl mt-8 min-w-[60dvw]">
        <h1 class="text-4xl font-bold">{{ $t('common.pause_subscription') }}</h1>
        <div v-if="loading">
          Loading..
        </div>

        <div v-else class="mt-4 text-xl text-center">
          <div class="my-4 text-2xl font-medium"> {{ $t('common.take_a_break') }}.</div>
          <div class="my-4 text-xl"> 
            <Button @click="pauseSubscription" :label="pauseLabel" size="large" :loading="busy"/>
          </div>
          <div class="my-4" v-if="userStore.stats.videos > 0"> 
            <div class="max-w-xl mx-auto text-sm">
              {{  $t('common.you_have') }} {{ userStore.stats.videos }} {{ $t('common.videos') }} 
              {{ $t('common.with') }} {{ userStore.stats.views }} {{ $t('common.views') }}, 
              {{ userStore.stats.interactions }} {{ $t('common.interactions') }}, {{ $t('common.and') }} 
              {{ userStore.stats.viewers }} {{ $t('common.viewers') }} {{ $t('common.in_mindstamp') }}.
              {{ $t('common.dont_lose') }}
            </div>
          </div>
          <div class="my-4">
            <Button size="small" severity="danger" text :loading="busy">
              <router-link to="/account/cancel">{{ $t('common.continue_to_cancel') }}</router-link>
            </Button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
td {
  @apply p-2;
  @apply border border-gray-200;
}
</style>
