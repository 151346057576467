import { defineStore } from 'pinia'
import { TimeFormatter } from '@/front/composables/TimeFormatter.js'
const { convertDecitimeToKey } = TimeFormatter();
import { PlayerStore } from '@/front/stores/player_store.js'
import { PlaybackStore } from '@/front/stores/playback_store.js'
import { UserStore } from '@/front/stores/user_store.js'
import { VideoStore } from '@/front/stores/video_store.js'
import { UxComposer } from '@/front/composables/UxComposer.js'
const { focusAndHighlight, focusAndSelect } = UxComposer()
import { StringComposer } from '@/front/composables/StringComposer.js'
const { transformContent } = StringComposer()

import _ from 'lodash'; // To create a deep clone of the interaction object

export const AnnStore = defineStore('anns', {
  state: () => {
    return {
      anns: [],
      loading: true,
      ogAnn: null, // holds the original copy of the interaction
      editAnn: null, // holds the current state of the interaction being edited
      isEditing: false, // flag to track if we are editing an interaction
      timeMap: {}, // map of all interactions by time
      active: [], // list of active interactions on layer
      hoverAnn: null, // holds the current state of the interaction being hovered over (NOT MARKER HOVER)
      markerAnn: null, // holds the current state of the interaction being hovered over (MARKER HOVER)
    }
  },

  actions: {
    addAnn(type_of) {
      console.log("🔎 AnnStore addAnn", type_of)
      const playerStore = PlayerStore()
      const videoStore = VideoStore()
      const finish = Math.min(Number((playerStore.decitime + 10).toFixed(1)), Number((playerStore.playerState.duration - 0.1).toFixed(1)))
      const font_size = playerStore.videoElement.videoWidth / 33
      const ann = {
        type_of: type_of,
        exact_time: playerStore.decitime,
        exact_finish: finish,
        time: 0,
        content: '',
        isActive: true,
        pos_left: 35,
        pos_top: 41.5,
        pos_width: 30,
        pos_height: 17,
        font_size: font_size,
        border_radius: 10,
        og_video_width: playerStore.videoElement.videoWidth,
        og_video_height: playerStore.videoElement.videoHeight,
        token: videoStore.video.token
      }
      // For button, hotspot, etc set specific styles
      this.setSpecificStyles(ann)
      this.anns.push(ann)
      this.setMap()
      this.editAnn = ann
      this.isEditing = true
      console.log("🔎 focusAndHighlight", 'edit-ann-content')
      focusAndHighlight('edit-ann-content')
    },
    setSpecificStyles(ann) {
      const playerStore = PlayerStore()
      const videoStore = VideoStore()
      if (ann.type_of === 'button') {
        ann.pos_left = 40
        ann.pos_top = 41.5
        ann.pos_width = 30
        ann.pos_height = 17
        ann.background_color = videoStore.video.design.button_background_color
        ann.text_color = videoStore.video.design.button_text_color
        ann.border_color = videoStore.video.design.button_border_color
      } else if (ann.type_of === 'hotspot') {
        ann.background_color
        ann.pos_left = 40
        ann.pos_top = 40
        ann.pos_width = 30
        ann.pos_height = 30
        ann.background_color = videoStore.video.design.color
        ann.border_color = videoStore.video.design.color
        ann.border_radius = videoStore.video.design.border_radius
      } else if (ann.type_of === 'text') {
        ann.pos_left = 40
        ann.pos_top = 40
        ann.pos_width = 20
        ann.pos_height = 10
        ann.text_align = 'left'
        ann.content = 'Enter Text'
        ann.text_color = videoStore.video.design.comment_text_color
        ann.font_size = playerStore.videoElement.videoWidth / 50
        console.log("Set Text Color", ann.color)
        ann.background_color = videoStore.video.design.comment_background_color
        ann.border_radius = videoStore.video.design.comment_border_radius
        focusAndSelect('edit-ann-content')
      } else if (ann.type_of === 'image') {
        ann.pos_left = 40
        ann.pos_top = 40
        ann.pos_width = 30
        ann.pos_height = 30
      }
    },
    startEdit(id) {
      console.log("AnnStore startEdit", id)
      const ann = this.anns.find(ann => ann.id === id)
      if (!ann) {
        console.log("AnnStore startEdit ann not found", id)
        return
      }
      this.ogAnn = _.cloneDeep(ann);
      console.log("ogAnn", this.ogAnn)
      this.editAnn = ann
      this.isEditing = true
      const playerStore = PlayerStore()
      console.log("go to time", this.editAnn.exact_time)
      // TODO timing
      // playerStore.player.currentTime = this.editAnn.exact_time
      if (!this.editAnn.isActive) {
        playerStore.seek(this.editAnn.exact_time)
      }
    },
    async saveAnn(ann) {
      console.log("💦 AnnStore saveAnn", ann.id)
      if (ann.id) {
        this.updateAnn(ann)
      } else {
        this.createAnn(ann)
      }
    },
    async createAnn(ann) {
      const playerStore = PlayerStore()
      console.log("💦 AnnStore createAnn", ann)
      return this.Api.post(`/annotations`, ann).then(response => {
        console.log("💦 AnnStore createAnn response", response)
        this.stopEditing()
        this.anns.push(response.data.annotation)
        this.setMap()
        this.updateActive(playerStore.decitime)
      }).catch(error => {
        console.log("💦 AnnStore updateAnn error", error)
      })
    },
    async updateAnn(ann) {
      const playerStore = PlayerStore()
      console.log("💦 AnnStore updateAnn", ann)
      return this.Api.put(`/annotations/${ann.id}`, ann).then(response => {
        this.clearEditAnn()
        this.setMap()
        this.updateActive(playerStore.decitime)
      }).catch(error => {
        console.log("💦 AnnStore updateAnn error", error)
      })
    },

    stopEditing() {
      const ct = this.editAnn.exact_time
      if (this.editAnn.id) {
        const index = this.anns.findIndex(i => i.id === this.editAnn.id);
        if (index !== -1) {
          Object.assign(this.anns[index], this.ogAnn);
          this.editAnn = null; // Clear editing state
          this.ogAnn = null; // Clear original interaction copy
        }
      }
      this.clearEditAnn()
      this.updateActive(ct)
      this.anns = this.anns.filter(item => item.id !== undefined && item.id !== null);
    },

    clearEditAnn() {
      this.isEditing = false
      this.editAnn = null
      this.ogAnn = null
    },


    async index(path) {
      const playbackStore = PlaybackStore()
      const videoStore = VideoStore()
      this.resetState()
      this.loading = true
      return this.Api.get(`/annotations/${path}`).then(response => {
        console.log("AnnStore index response ", response)
        this.pagination = response.data.pagination;
        this.anns = response.data.annotations;

        // Only substitute variables if we are not managing the video
        if (!videoStore.video.manage) {
          this.anns.forEach(ann => {
            if (ann.content.includes('{{')) {
              console.log("Transforming content", ann.content, playbackStore.variables)
              ann.transformed_content = transformContent(ann.content, playbackStore.variables)
              console.log("Transformed content", ann.transformed_content)
            }
          })
        }
        this.setMap()
      }).finally(() => {
        this.loading = false
      })
    },
    async setMap() {
      this.timeMap = {}
      // sort anns by exact_time, then by created_at if exact_time is the same
      this.anns.sort((a, b) => {
        if (a.exact_time === b.exact_time) {
          return new Date(a.created_at) - new Date(b.created_at);
        }
        return a.exact_time - b.exact_time;
      });
      this.anns.forEach(ann => {

        // Convert start and end keys back to numbers to loop through the range
        const startTime = Math.round(ann.exact_time * 10);
        const endTime = Math.round(ann.exact_finish * 10);

        // Populate the hash for each 1/10th of a second within the range
        for (let time = startTime; time <= endTime; time++) {
          const key = convertDecitimeToKey(time / 10);
          // console.log(time, key)

          // Initialize the entry if it doesn't exist
          if (!this.timeMap[key]) {
            this.timeMap[key] = { anns: [], dialogs: [] };
          }

          // Add the interaction to the list for this key
          this.timeMap[key].anns.push(ann);
        }
      });
    },

    async updateActive(time) {
      // console.log("💟  AnnStore updateActive", time)
      const newInteractions = this.timeMap[convertDecitimeToKey(time)] && this.timeMap[convertDecitimeToKey(time)].anns || []
      const oldInteractions = this.active;

      const removes = []
      // Determine what to remove
      oldInteractions.forEach((interaction, index) => {
        if (!newInteractions.some(newInteraction => newInteraction.id === interaction.id)) {
          removes.push(interaction.id)
        }
      });
      // Remove interactions that are no longer active
      // Can't iterate on oldInteractions directly because index is not stable
      // when we remove an item from the array
      removes.forEach(id => {
        const index = oldInteractions.findIndex(interaction => interaction.id === id)
        if (index !== -1) {
          oldInteractions.splice(index, 1);
        }
      })

      // Add new items from newInteractions
      newInteractions.forEach(newInteraction => {
        if (!oldInteractions.some(interaction => interaction.id === newInteraction.id)) {
          newInteraction.isActive = true
          oldInteractions.push(newInteraction);
        }
      });

    },

    // Hovering NOT on the marker bar
    startHover(ann) {
      this.hoverAnn = ann
    },

    endHover() {
      this.hoverAnn = null
    },

    // Hovering on the marker bar
    startMarkerHover(ann) {
      this.markerAnn = ann
      this.hoverAnn = ann
    },

    endMarkerHover() {
      this.markerAnn = null
      this.hoverAnn = null
    },

    resetState() {
      this.anns = []
      this.timeMap = {}
      this.isEditing = false
      this.editAnn = null
      this.ogAnn = null
    },

    async destroy(id) {
      return this.Api.destroy(`/anns/${id}`).then(response => {
        this.errors = {};
      }).catch(error => {
        this.errors = error.response.data.errors;
      })
    },

    getVariableOptions() {
      const userStore = UserStore()
      const videoStore = VideoStore()

      const results = ['name', 'name', 'pScore', 'cScore']
      try {
        Object.keys(JSON.parse(userStore.organization.variables)).forEach((key) => {
          results.push(key)
        });
      } catch (e) {
        console.log("Error parsing org variables", e)
      }
      if (videoStore.video.variables) {
        try {
          Object.keys(JSON.parse(videoStore.video.variables)).forEach((key) => {
            results.push(key)
          });
        } catch (e) {
          console.log("Error parsing video variables", e)
        }
      }
      this.anns.forEach(ann => {
        if (ann.variable) {
          results.unshift(ann.variable)
        }
      })
      return [...new Set(results)];
    },
    addEndAnn(type) {
      this.addAnn(type)
      this.editAnn.tray_type = 'ctaTray'
      console.log("🔎 AnnStore addEndAnn", this.editAnn)
    },
    addMenuAnn(type) {
      this.addAnn(type)
      this.editAnn.tray_type = 'resourceTray'
      console.log("🔎 AnnStore addMenuAnn", this.editAnn)
    }
  }
})