<script setup>
import { UserStore } from '@/front/stores/user_store.js'
const userStore = UserStore();
import { AssetStore } from '@/front/stores/asset_store.js'
const assetStore = AssetStore();
import { useI18n } from 'vue-i18n'
const { t } = useI18n({});
import Checkbox from 'primevue/checkbox';
import InputText from 'primevue/inputtext';

const emit = defineEmits(['update'])

const selected = ref([])
const search = ref('')

const props = defineProps({
  group_id: {
    type: String,
    required: false
  },
  video_ids: {
    type: Array,
    required: false
  }
})

const videoList = computed(() => {
  var videos = assetStore.videos
  if (props.video_ids) {
    videos = videos.filter(video => props.video_ids.includes(video.id))
  }
  if (props.group_id) {
    console.log("Filtering videos by group_id", props.group_id)
    console.log("Videos", videos)
    videos = videos.filter(video => video.group_id == props.group_id)
    console.log("Videos", videos)
  }
  if (search.value.length) {
    return videos.filter(video => video.title.toLowerCase().includes(search.value.toLowerCase()))
  }
  return videos
})

watch(selected, () => {
  console.log("selected", selected.value)
  emit('update', selected.value)
})

</script>

<template>
  <div>
    <div class="p-2 mb-2">
      <InputText v-model="search" class="w-full" :placeholder="$t('common.search')" />
    </div>
    <div v-for="video in videoList" :key="video.id" class="flex items-left mb-2 min-w-[600px]">
      <Checkbox v-model="selected" :value="video.id" class="mr-2" />
      <div class="overflow-hidden text-left">{{ video.title }}</div>
    </div>
  </div>
</template>
