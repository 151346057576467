<script setup>
import { UserStore } from "@/front/stores/user_store.js";
const userStore = UserStore();

import { VideoStore } from '@/front/stores/video_store.js'
const videoStore = VideoStore()

import { AnnStore } from '@/front/stores/ann_store.js'
const annStore = AnnStore()

import { ImageComposer } from '@/front/composables/ImageComposer'
const { getIntegrationImage } = ImageComposer()

import Button from 'primevue/button'

const connectStripe = () => {
  window.location.href = '/users/auth/stripe'
}

</script>

<template>
  <div >
    <img :src="getIntegrationImage('stripe')" class="w-48 mx-auto max-w-xs my-4" />
    <div v-if="userStore.user.stripe_connect_connected">
      <p class="text-center">
        Stripe {{ $t('common.connected') }}
      </p>
      <div class="text-center mt-2">
        <Button @click="connectStripe" :label="userStore.user.stripe_connect_connected ? $t('common.connect_again') : $t('common.connect')" class="mx-1" />
      </div>
    </div>
    <div v-else class="text-center">
      Stripe {{ $t('common.not_connected') }}
      <Button @click="connectStripe" :label="$t('common.connect')" class="mx-1" />
    </div>
  </div>
</template>

<style scoped></style>
