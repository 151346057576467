<script lang="ts" setup>
// Import styles.
import 'vidstack/player/styles/base.css';
// Register elements.
import 'vidstack/player';
import 'vidstack/player/ui';
import 'vidstack/icons';


import { isHLSProvider, type MediaCanPlayEvent, type MediaProviderChangeEvent } from 'vidstack';
import type { MediaPlayerElement } from 'vidstack/elements';
import { computed, onMounted, ref } from 'vue';

import VideoLayout from '@/front/components/player/components/layouts/VideoLayout.vue';

const props = defineProps({
  controls: {
    type: Boolean,
    default: true,
  },
})


import { ClipStore } from '@/front/stores/clip_store.js';

const clipStore = ClipStore();
const $player = ref<MediaPlayerElement>();
const $src = ref('');

onMounted(() => {
  if (!clipStore.src) {
    return
  }
  clipStore.player = $player

  // const { currentSrc, controlsHidden, currentTime, duration, fullscreen, playing, qualities, quality, seekableEnd, seekableStart, textTrack, volume, waiting, controlsVisible, ended, error, height, muted, paused, playbackRate, seeking, started, width } = $player.value?.state
  $src.value = clipStore.src

  $player.value?.subscribe(({ currentSrc, controlsHidden, fullscreen, playing, qualities, quality, seekableEnd, seekableStart, textTrack, volume, waiting, controlsVisible, duration, ended, error, height, muted, paused, playbackRate, seeking, started, width }) => {
    clipStore.setPlayerState({ currentSrc, controlsHidden, fullscreen, playing, qualities, quality, seekableEnd, seekableStart, textTrack, volume, waiting, controlsVisible, duration, ended, error, height, muted, paused, playbackRate, seeking, started, width })
  });
});

function onProviderChange(event: MediaProviderChangeEvent) {
  const provider = event.detail;
  // We can configure provider's here.
  if (isHLSProvider(provider)) {
    provider.config = {};
  }
}

  //

// We can listen for the `can-play` event to be notified when the player is ready.
function onCanPlay(event: MediaCanPlayEvent) {
  $player.value?.play()
  // ...
}

function onTimeUpdate(event: MediaTimeUpdateEvent) {
  // console.log('onTimeUpdate', event.detail.currentTime);
  // clipStore.handleTimeUpdate(event.detail.currentTime);
}

const getCrossOrigin = computed(() => {
  // console.log("🌜 Player getCrossOrigin", $src.value)
  if ($src.value.includes("cloudfront")) {
    return false
  } else if ($src.value.includes("panopto")) {
    return 'anonymous'
  } else {
    return 'anonymous'
  }
});

</script>

<template>
  <media-player
    class="w-full aspect-video bg-black text-white font-sans overflow-hidden ring-media-focus data-[focus]:ring-4"
    title="Sprite Fight"
    :src="$src"
    :crossOrigin="getCrossOrigin"
    playsInline
    @provider-change="onProviderChange"
    @can-play="onCanPlay"
    @loaded-metadata="onLoadedMetadata"
    @time-update="onTimeUpdate"
    ref="$player"
    id="media-player"
  >
    <media-provider id="media-provider">
    </media-provider>
    <VideoLayout v-if="controls" :isClip="true" />
  </media-player>
</template>