<script setup>
const location = useRoute();
const router = useRouter();
import { UserStore } from '@/front/stores/user_store.js'
const userStore = UserStore()
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'
import Button from 'primevue/button'
import Select from 'primevue/select'
import Checkbox from 'primevue/checkbox'
import { useClipboard } from '@vueuse/core'
const { text, copy, copied, isSupported } = useClipboard()
import { debounce } from 'lodash'

import { useToast } from "primevue/usetoast";
const toast = useToast();

import { SeriesStore } from '@/front/stores/series_store.js'
const seriesStore = SeriesStore()

const { t } = useI18n({});
import { useConfirm } from "primevue/useconfirm";
const confirm = useConfirm();

import { StringComposer } from '@/front/composables/StringComposer.js'
const { objectToQueryString } = StringComposer()


const copyLink = () => {
  copy(shareLink.value + "hey")
  toast.add({ severity: 'success', summary: 'Success', detail: t('common.copied'), life: 3000 });
}

const copyEmbedCode = () => {
  copy(embedCode.value)
  toast.add({ severity: 'success', summary: 'Success', detail: t('common.copied'), life: 3000 });
}

const copyUnbrandedLink = () => {
  copy(unbrandedLink.value)
  toast.add({ severity: 'success', summary: 'Success', detail: t('common.copied'), life: 3000 });
}

const copyUnbrandedEmbedCode = () => {
  copy(unbrandedEmbedCode.value)
  toast.add({ severity: 'success', summary: 'Success', detail: t('common.copied'), life: 3000 });
}

const copyPopoutEmbedCode = () => {
  // TODO popout logic
  alert("TODO")
  // copy(`https://share.mindstamp.com/oembed/${location.params.id}?src={{URL}}`)
  // toast.add({ severity: 'success', summary: 'Success', detail: t('common.copied'), life: 3000 });
}

const copyBasicIframeCode = () => {
  // TODO basic iframe logic
  alert("TODO")
  // copy(`https://share.mindstamp.com/oembed/${location.params.id}?src={{URL}}`)
  // toast.add({ severity: 'success', summary: 'Success', detail: t('common.copied'), life: 3000 });
}

const shareLink = computed(() => {
  return `${baseLinkDomain.value}/series/${seriesStore.series.id}`
})

const unbrandedLink = computed(() => {
  return `https://interact.video/series/${seriesStore.series.id}`
})

const embedLink = computed(() => {
  return `${baseEmbedDomain.value}/series/${seriesStore.series.id}/embed`
})

const unbrandedEmbedLink = computed(() => {
  return `https://interact.video/series/${seriesStore.series.id}/embed`
})

const linkParams = computed(() => {
  return objectToQueryString({
    t: videoStore.video.start_time || "",
    autoplay: videoStore.video.autoplay ? 1 : "",
    loop: videoStore.video.loop ? 1 : "",
    fullscreen: videoStore.video.fullscreen ? 1 : "",
  })
})

const embedCode = computed(() => {
  return `<iframe src="${embedLink.value}" width="100%" height="100%" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>`
})

const unbrandedEmbedCode = computed(() => {
  return `<iframe src="${unbrandedEmbedLink.value}" width="100%" height="100%" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>`
})

const baseEmbedDomain = computed(() => {
  return userStore.organization.domain_is_active ? userStore.organization.custom_domain : "https://embed.mindstamp.com"
})

const baseLinkDomain = computed(() => {
  return userStore.organization.domain_is_active ? userStore.organization.custom_domain : "https://share.mindstamp.com"
})

const debouncedSave = debounce(() => {
  videoStore.update()
}, 2000)

const updateStartTime = (value) => {
  videoStore.video.start_time = value
  debouncedSave()
}

onMounted(() => {
});

const publishSeries = () => {
  seriesStore.update({ published: true }).then(() => {
    toast.add({ severity: 'success', summary: 'Success', detail: t('common.series_published'), life: 3000 });
    seriesStore.series.published = true
  })
}

const unpublishSeries = () => {
  seriesStore.update({ published: false }).then(() => {
    toast.add({ severity: 'success', summary: 'Success', detail: t('common.series_unpublished'), life: 3000 });
    seriesStore.series.published = false
  })
}



</script>

<template>
  <div class="p-4">
    <div v-if="seriesStore.series.published" class="grid grid-cols-2">
      <div class="text-left">
        {{ shareLink }}
        <div class="mb-4"><Button size="large" @click="copyLink"> {{ $t('common.copy_link') }} </Button> </div>
        <div class="mb-4"><Button @click="copyEmbedCode"> {{ $t('common.copy_embed_code') }} </Button> </div>
        <div class="mb-4"><Button @click="copyUnbrandedLink"> {{ $t('common.copy_unbranded_link') }} </Button> </div>
        <div class="mb-4"><Button @click="copyUnbrandedEmbedCode"> {{ $t('common.copy_unbranded_embed_code') }}</Button> </div>
        <div class="mt-12"><Button @click="unpublishSeries"> {{ $t('common.unpublish_series') }}</Button> </div>
        <!-- TODO these functionalities -->
        <!-- <div class="mb-4"><Button> {{ $t('common.scorm') }} </Button> </div>
          <div class="mb-4"><Button> {{ $t('common.invite_viewers') }} </Button> </div>
          <div class="mb-4"><Button> {{ $t('common.personalize_video') }} </Button> </div>
          <div class="mb-4"><Button> {{ $t('common.download_video') }} </Button> </div>
          <div class="mb-4"><Button> {{ $t('common.unbranded_link') }} </Button> </div>
          <div class="mb-4"><Button> {{ $t('common.whatsapp') }} </Button> </div>
          <div class="mb-4"><Button> {{ $t('common.linkedin') }} </Button> </div>
          <div class="mb-4"><Button> {{ $t('common.mail') }} </Button> </div>
          <div class="mb-4"><Button> {{ $t('common.facebook') }} </Button> </div>
          <div class="mb-4"><Button> {{ $t('common.twitter') }} </Button> </div>
          <div class="mb-4"><Button> {{ $t('common.qrcode') }} </Button> </div> -->
      </div>
      <div>
        <SharingPreview />
      </div>
    </div>
    <div v-else class="text-center">
      <div>
        <p>{{ $t('common.series_not_published') }}</p>
        <Button size="large" @click="publishSeries" class="my-4"> {{ $t('common.publish_series') }} </Button>

      </div>
    </div>
  </div>
</template>
