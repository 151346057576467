<script setup>
import { AssetStore } from '@/front/stores/asset_store.js'
const assetStore = AssetStore();
import { AudioStore } from '@/front/stores/audio_store.js';
const audioStore = AudioStore();
import { ClipStore } from '@/front/stores/clip_store.js';
const clipStore = ClipStore();

const location = useRoute();
const router = useRouter();
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'
import Button from 'primevue/button'
import Menu from 'primevue/menu'

import { StringComposer } from '@/front/composables/StringComposer.js'
const { getFileName, getFileType } = StringComposer()
import { ImageComposer } from '@/front/composables/ImageComposer.js'
const { getDefaultVideoThumbnail, getDefaultFileIcon, cdnImageUrl } = ImageComposer()

const { t } = useI18n({});
import { useConfirm } from "primevue/useconfirm";
const confirm = useConfirm();

import AudioPlayer from '@/front/components/AudioPlayer.vue'
import ClipPlayer from '@/front/components/ClipPlayer.vue'
import Dialog from 'primevue/dialog'
import FileUploader from '@/front/components/FileUploader.vue'
import WYG from '@/front/components/WYG.vue';
import Select from 'primevue/select'
import InputText from 'primevue/inputtext'
import { PlusSignCircleIcon, PlusSignSquareIcon, Upload01Icon, Upload02Icon, Upload03Icon } from 'hugeicons-vue';

const props = defineProps({
  document: {
    type: Boolean,
    default: false
  },
  clip: {
    type: Boolean,
    default: false
  },
  audio: {
    type: Boolean,
    default: false
  },
  image: {
    type: Boolean,
    default: false
  },
  manage: {
    type: Boolean,
    default: false
  }
})

const playbackDialog = ref(false)
const showFilter = computed(() => {
  return !(props.document || props.clip || props.audio || props.image)
})

const fileList = computed(() => {
  var options = []
  if (filterType.value == 'documents' || props.document) {
    options = assetStore.documents
  } else if (filterType.value == 'images' || props.image) {
    options = assetStore.images
  } else if (filterType.value == 'clips' || props.clip) {
    options = assetStore.clips
  } else if (filterType.value == 'audios' || props.audio) {
    options = assetStore.audios
  } else if (filterType.value == 'articles' || props.article) {
    options = assetStore.articles
  } else {
    options = assetStore.documents.concat(assetStore.audios).concat(assetStore.clips).concat(assetStore.images).concat(assetStore.articles)
  }
  if (searchString.value) {
    options = options.filter(option => {
      console.log("🌜 Option", option)
      return getFileName(option.content || option.key).toLowerCase().includes(searchString.value.toLowerCase()) || (option.title && option.title.toLowerCase().includes(searchString.value.toLowerCase()))
    })
  }
  return options.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at))
})

const availableTypes = computed(() => {
  return ['image/png', 'image/jpeg', 'image/gif', 'video/mp4', 'video/quicktime', 'application/pdf', 'audio/mpeg']
})

const isImage = (file) => {
  return getFileType(file.content || file.key) == 'JPG' || getFileType(file.content || file.key) == 'PNG' || getFileType(file.content || file.key) == 'GIF' || getFileType(file.content || file.key) == 'BMP' || getFileType(file.content || file.key) == 'SVG'
}

const isVideo = (file) => {
  return getFileType(file.content || file.key) == 'MP4' || getFileType(file.content || file.key) == 'MOV' || getFileType(file.content || file.key) == 'AVI' || getFileType(file.content || file.key) == 'WMV'
}

const isAudio = (file) => {
  return getFileType(file.content || file.key) == 'MP3' || getFileType(file.content || file.key) == 'WAV' || getFileType(file.content || file.key) == 'OGG' || getFileType(file.content || file.key) == 'FLAC'
}

const isDocument = (file) => {
  return getFileType(file.content || file.key) == 'PDF' || getFileType(file.content || file.key) == 'DOC' || getFileType(file.content || file.key) == 'DOCX' || getFileType(file.content || file.key) == 'TXT' || getFileType(file.content || file.key) == 'RTF' || getFileType(file.content || file.key) == 'HTML' || getFileType(file.content || file.key) == 'XML' || getFileType(file.content || file.key) == 'XLS' || getFileType(file.content || file.key) == 'XLSX' || getFileType(file.content || file.key) == 'CSV' || getFileType(file.content || file.key) == 'PPT' || getFileType(file.content || file.key) == 'PPTX' || getFileType(file.content || file.key) == 'ODT' || getFileType(file.content || file.key) == 'ODS' || getFileType(file.content || file.key) == 'ODP'
}

const getText = (file) => {
  return file.title || getFileName(file.content || file.key)
}

const filterType = ref(null)

const filterOptions = computed(() => {
  console.log("🌜 assetStore filterOptions")
  return [
    { label: 'Documents (' + assetStore.documents.length + ')', value: 'documents' },
    { label: 'Images (' + assetStore.images.length + ')', value: 'images' },
    { label: 'Clips (' + assetStore.clips.length + ')', value: 'clips' },
    { label: 'Audios (' + assetStore.audios.length + ')', value: 'audios' },
    { label: 'Articles (' + assetStore.articles.length + ')', value: 'articles' },
  ]
}
)

const searchString = ref('')

const showUploadDialog = ref(false)
const showWygDialog = ref(false)
onMounted(() => {
  console.log("🌜 AssetSelector onMounted")
});

const emit = defineEmits(['select'])
const finishUpload = (file) => {
  console.log("🌜 Finish Upload, now create!", file)
  // Create Asset
  assetStore.createAsset(file).then((response) => {
    console.log("🌜 Asset created", response)
    showUploadDialog.value = false
    emit("select", file)
  })
}

const selectFile = (file) => {
  console.log("🌜 Select File", file)
  emit("select", file.key || file.content)
}

const getImage = (file) => {
  if (isImage(file)) {
    return cdnImageUrl(file.content)
  } else if (isVideo(file)) {
    return file.thumbnail || getDefaultVideoThumbnail()
  } else if (isAudio(file)) {
    return file.thumbnail || 'https://knetic.org.uk/wp-content/uploads/2020/07/Audio-Record-Placeholder.png'
  } else if (isDocument(file)) {
    return file.thumbnail || 'https://t3.ftcdn.net/jpg/02/26/42/06/360_F_226420649_vlXjp3JyUrnW5EHY00dvhbqkVdUfyafj.jpg'
  } else {
    return 'https://cdn-icons-png.freepik.com/256/16468/16468822.png?semt=ais_hybrid'
  }
}

const hideAsset = (id) => {
  assetStore.hideAsset(id).then((response) => {
    console.log("🌜 Asset hidden", response)
  })
}

const resetAsset = () => {
  assetStore.selected = null
  playbackDialog.value = false
}

const title = ref('')
const viewFile = (file) => {
  assetStore.selected = file
  if (isAudio(file)) {
    audioStore.streamById(file.id)
    title.value = file.title
  } else if (isVideo(file)) {
    clipStore.streamById(file.id)
    title.value = file.title
  } else if (isDocument(file)) {
    assetStore.streamById(file.id)
    title.value = file.title
  }
  playbackDialog.value = true
}

const saveTitle = () => {
  assetStore.updateAsset(assetStore.selected.id, { title: title.value }).then((response) => {
    console.log("🌜 Asset updated", response)
    playbackDialog.value = false
    title.value = ''
  })
}

const menu = ref();

// TODO icons for these
const items = ref([
  {
    label: t('common.choose_asset_type'),
    items: [
      {
        label: t('common.video_clip'),
        command: () => {
          console.log("🌜 Video Clip")
          showUploadDialog.value = true
        }
      },
      {
        label: t('common.audio_clip'),
        command: () => {
          console.log("🌜 Audio Clip")
          showUploadDialog.value = true
        }
      },
      {
        label: t('common.document'),
        command: () => {
          console.log("🌜 Document")
          showUploadDialog.value = true
        }
      },
      {
        label: t('common.image'),
        command: () => {
          console.log("🌜 Image")
          showUploadDialog.value = true
        }
      },
      {
        label: t('common.article'),
        command: () => {
          console.log("🌜 Article")
          showWygDialog.value = true
        }
      }
    ]
  }
]);
const toggle = (event) => {
  menu.value.toggle(event);
};

</script>

<template>
  <div class="w-full">
    <div class="grid grid-cols-7 gap-2">
      <div class="col-span-4" :class="{ 'col-span-6': !showFilter }">
        <InputText v-model="searchString" :placeholder="t('common.search_files')" class="w-full" />
      </div>
      <div v-if="showFilter && !assetStore.loading" class="col-span-2">
        <Select v-model="filterType" :options="filterOptions" optionLabel="label" optionValue="value" showClear
          :placeholder="t('common.all_files')" class="w-full" />
      </div>
      <div class="col-span-1">
        <!-- TODO allow article creation -->
        <Button severity="info" class="mx-auto" @click="toggle" aria-haspopup="true" aria-controls="overlay_menu">
          <PlusSignCircleIcon class="w-6 h-5 mr-1" />
          {{ t('common.new') }}
        </Button>
        <Menu ref="menu" id="overlay_menu" :model="items" :popup="true" class="shadow-2xl" />
      </div>

    </div>
    <div class="mt-4 w-full">
      <table v-if="fileList.length > 0" class="w-full border-collapse">
        <!-- <thead>
          <tr>
            <th class="border p-2 text-left">Name</th>
            <th class="border p-2 text-left">Type</th>
            <th class="border p-2 text-left">Select</th>
          </tr>
        </thead> -->
        <tbody>
          <tr v-for="file in fileList" :key="file.id" :id="file.id" class="border-y border-x">
            <td class="px-2 w-1/2">
              <div class="flex items-center text-left">
                <div class="w-16 h-16 p-2"><img :src="getImage(file)" class="w-full h-full object-contain" /></div>
                <div class="ml-4 overflow-x-scroll">{{ getText(file) }}</div>
              </div>
            </td>
            <td class="p-4 text-right w-1/2">
              <Button v-if="manage" :label="t('common.view')" size="small" @click="viewFile(file)" class="mr-2" />
              <Button v-if="manage" :label="t('common.delete')" size="small" severity="danger"
                @click="hideAsset(file.id)" class="mr-2" />
              <Button v-else :label="t('common.select')" size="small" @click="selectFile(file)" class="mr-2" />
            </td>
          </tr>
        </tbody>
      </table>
      <div v-show="fileList.length == 0" class="text-center text-gray-500">
        {{ t('common.no_files_found') }}
      </div>
    </div>
    <Dialog v-model:visible="showUploadDialog" :header="t('common.upload_file')" :modal="true" :closeOnEscape="false"
      :closeOnBackdrop="false">
      <FileUploader :type="availableTypes" @success="finishUpload" />
    </Dialog>
    <Dialog v-model:visible="showWygDialog" :header="t('common.new_article')" :modal="true" :closeOnEscape="false"
      :closeOnBackdrop="false">
      <WYG @close="showWygDialog = false" class="w-full h-auto" />
    </Dialog>
    <Dialog v-model:visible="playbackDialog" modal :header="$t('common.view_asset')" dismissableMask class="max-w-2xl">
      <AudioPlayer v-if="isAudio(assetStore.selected) && audioStore.src" class="w-full" />
      <ClipPlayer v-else-if="isVideo(assetStore.selected) && clipStore.src" class="w-full min-w-2xl" />
      <iframe v-else-if="isDocument(assetStore.selected)" :src="assetStore.currentSrc" class="w-full h-[80vh]" />
      <img v-else-if="isImage(assetStore.selected)" :src="cdnImageUrl(assetStore.selected)" class="w-full h-auto" />
      <div v-else class="w-full h-auto">
        <WYG :article="assetStore.selected" @close="resetAsset" class="w-full h-auto" />
      </div>
      <!-- Only edit titles for audio and video -->
      <div v-if="isAudio(assetStore.selected) || isVideo(assetStore.selected)" class="flex items-center mt-4">
        <InputText v-model="title" :placeholder="t('common.title')" class="w-full mr-2" />
        <Button :label="t('common.save')" @click="saveTitle" class="ml-2" />
      </div>
    </Dialog>
  </div>
</template>
