import { defineStore } from 'pinia'
  
export const ClipStore = defineStore('clip', {
  state: () => {
    return {
      player: null,
      currentTime: 0,
      playerState: {},
      src: null,
    }
  },

  actions: {
    setPlayerState(state) {
      this.playerState = state
    },
    setSrc(src) {
      this.src = src
    },
    togglePlay() {
      this.playerState.playing ? this.player.pause() : this.player.play()
    },
    seekToPercentage(percentage) {
      console.log("seekToPercentage", percentage)
      this.playerStore.seek((percentage / 100) * this.playerState.duration)
    },
    async streamById(id) {
      console.log("🌜 ClipStore streamById", id)
      this.Api.get(`/assets/stream/${id}`).then(response => {
        console.log("🔊 ClipStore streamById", response.data)
        this.src = response.data.url
      })
    }
  },
  getters: {
    progress: (state) => Math.round((state.currentTime / state.playerState.duration) * 100),
  }
})