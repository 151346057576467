import { AssetStore } from "@/front/stores/asset_store.js"

export function AssetFinder() {

  function findVideoById(id) {
    const assetStore = AssetStore();
    return assetStore.videos.find(asset => asset.id === id)
  }
  function findVideoByToken(token) {
    const assetStore = AssetStore();
    return assetStore.videos.find(asset => asset.token === token)
  }

  function findGroupById(id) {
    const assetStore = AssetStore();
    return assetStore.groups.find(asset => asset.id === id)
  }

  return { findVideoById, findVideoByToken, findGroupById };
}