<script setup>
import { UserStore } from '@/front/stores/user_store.js'
const userStore = UserStore();
import { useRoute } from 'vue-router'
const router = useRouter()
import { useI18n } from 'vue-i18n'
const { t } = useI18n({});
import { AssetStore } from '@/front/stores/asset_store.js'
const assetStore = AssetStore();

import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Button from 'primevue/button';


import { useConfirm } from "primevue/useconfirm";
import { PlusSignCircleIcon } from 'hugeicons-vue';
const confirm = useConfirm();

import { useToast } from 'primevue/usetoast';
const toast = useToast();


import Dialog from 'primevue/dialog';
import InputText from 'primevue/inputtext';

const createList = () => {
  assetStore.createList(newList.value).then((response)=> {
    console.log("CreateList", response)
    router.push({ name: 'access_lists_editor_path', params: { id: response.data.id } })
    toast.add({ severity: 'success', summary: t('common.success'), detail: t('common.list_created'), life: 3000 });
  }).catch((error)=> {
    toast.add({ severity: 'error', summary: t('common.error'), detail: error, life: 3000 });
  })
  newListModal.value = false
}

onMounted(() => {
  assetStore.index("AccessListsIndex")
})


const editList = (list) => {
  console.log(list)
  assetStore.currentList = list
  router.push({ name: 'access_lists_editor_path', params: { id: list.id } })
}

const deleteList = (list) => {
  console.log(list)
  confirm.require({
    message: 'Are you sure you want to cancel? All changes will be lost.',
    header: 'Confirmation',
    icon: 'pi pi-exclamation-triangle',
    rejectProps: {
      label: 'Cancel',
      severity: 'secondary',
      outlined: true
    },
    acceptProps: {
      label: 'Discard Changes',
      severity: 'danger',
    },
    accept: () => {
      assetStore.deleteList(list.id).then((response)=> {
        console.log("DeleteList", response)
        assetStore.lists = assetStore.lists.filter(l => l.id !== list.id)
        toast.add({ severity: 'success', summary: t('common.success'), detail: t('common.list_deleted'), life: 3000 });
      }).catch((error)=> {
        toast.add({ severity: 'error', summary: t('common.error'), detail: error, life: 3000 });
      })
    },
    reject: () => {
      toast.add({ severity: 'error', summary: t('common.error'), detail: t('common.list_not_deleted'), life: 3000 });
    }
  });
}

const newListModal = ref(false)
const newList = ref({
  name: '',
  description: ''
})
const startCreateList = () => {
  newList.value = {
    name: '',
    description: ''
  }
  newListModal.value = true
}
</script>

<template>
  <div>
    <!-- TODO breadcrumbs -->
    <div class="p-4">
      <div class="p-8 mb-96 text-center bg-white w-fit mx-auto rounded-2xl shadow-xl mt-8 min-w-[60dvw]">
        <h1 class="text-4xl font-bold mb-4">{{ $t('common.access_lists') }}: {{ assetStore.lists?.length }}</h1>
        <Button @click="startCreateList">
          <PlusSignCircleIcon class="w-4 h-4 mr-2"/>  
          {{ $t('common.new_list') }}
        </Button>
        <DataTable :value="assetStore.lists">
          <Column field="name" :header="$t('common.name')"></Column>
          <Column field="description" :header="$t('common.description')"></Column>
          <Column :header="$t('common.people')">
            <template #body="slotProps">
              {{ slotProps.data.items.length}}
            </template>
          </Column>
          <Column :header="$t('common.actions')"> 
            <template #body="slotProps">
              <Button @click="editList(slotProps.data)" :label="$t('common.edit')" class="mr-2" size="small"/>
              <Button @click="deleteList(slotProps.data)" :label="$t('common.delete')" size="small" severity="danger"/>
            </template>
          </Column>
        </DataTable>

      </div>
    </div>
  </div>
  <Dialog v-model:visible="newListModal" modal :header="$t('common.new_list')" class="w-4/5 max-w-xl">
      <div class="grid grid-cols-1 gap-4">
        <div>
          <p class="font-medium">{{ $t('common.name') }}</p>
          <InputText v-model="newList.name" :placeholder="$t('common.name')" autofocus class="w-full"/>
        </div>
        <div>
          <p class="font-medium">{{ $t('common.description') }}</p>
          <InputText v-model="newList.description" :placeholder="$t('common.description')" class="w-full"/>
        </div>
        <div class="text-center">
          <Button @click="createList" :label="$t('common.save')" />
        </div>
        <div class="text-center">
          <Button @click="newListModal = false" :label="$t('common.cancel')" severity="secondary" text/>
        </div>
      </div>
    </Dialog>
</template>
