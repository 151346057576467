<script setup>

  const props = defineProps({
    ann: {
      type: Object,
      required: true
    }
  })

  import { VideoStore } from '@/front/stores/video_store.js';
  const videoStore = VideoStore();

  const customStyle = props.ann.customStyle && JSON.parse(props.ann.customStyle) || {}
  // TODO make sure this doesnt break in diff context
  const baseDesign = videoStore.video.design || {}
  const circleStyle = computed(() => {
    return {
      'background': props.ann.background_color || customStyle.background_color || baseDesign.button_background_color,
      'width': '50px',
      'height': '50px',
    }
  })
  const ringStyle = computed(() => {
    // TODO make sure this doesnt break in diff context
    return {
      'borderColor': props.ann.background_color || customStyle.background_color || baseDesign.button_background_color,
      'borderWidth': '4px',
      'min-width': '50px',
      'min-height': '50px',
    }
  })
</script>

<template>
  <div>
    <div class="flex items-center justify-center min-h-[60px]">
      <!-- <span class="spot-ring" :style="ringStyle"></span> -->
      <span class="spot-circle" :style="circleStyle"></span>
    </div>
  </div>

</template>