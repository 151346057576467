import { defineStore } from 'pinia'

export const SeriesStore = defineStore('series', {
  state: () => {
    return {
      series_list: [],
      bruh: 'yo',
      series: {},
      loading: false,
      columns: ['CREATED', 'NAME'],
      pagination: {page: 1}
    }
  },

  actions: {
    async index(path="/series") {
      return this.Api.get(path).then(response => {  
        this.pagination = response.data.pagination;
        this.series_list = response.data.series_list;        
      })  
    },

    async show(id) {
      this.series = {}
      return this.Api.get(`/series/${id}`).then(response => {  
        this.series = response.data.series;        
      })  
    },

    async destroy(id) {      
      return this.Api.destroy(`/series/${id}`).then(response => {  
        this.errors = {};
      }).catch(error => {
        this.errors = error.response.data.errors;
      }) 
    },

    async update(data) {
      return this.Api.put(`/series/${this.series.id}`, data).then(response => {
        this.series = response.data.series;
      });
    },

    async updateVideos(data) {
      return this.Api.post(`/series/${this.series.id}/videos`, data).then(response => {
        console.log(response.data)
        this.series.videos = response.data.series.videos
      });
    },

    async updateCollaboration(data) {
      return this.Api.post(`/series/${data.series_id}/collaboration`, data).then(response => {
        this.series.people = response.data.series.people
      });
    }
  }
})