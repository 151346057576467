<script setup>
import { UserStore } from '@/front/stores/user_store.js'
const userStore = UserStore();
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'
const { t } = useI18n({});

onMounted(() => {
});


</script>

<template>
  Member org
</template>
