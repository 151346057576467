export function PaywallHelper() {
  const { t } = useI18n({})

  function getFee(amount) {
    // TODO currency conversion
    var x = {
      stripe: Number(amount * 0.03 + 0.30).toFixed(2),
      mindstamp: Number(amount * 0.05).toFixed(2),
    }
    var total = Number(amount)  - (Number(x.stripe) + Number(x.mindstamp))
    var str = t('common.after_stripe_fee') + ' and ' + t('common.after_mindstamp_fee') + ', ' + t('common.youll_earn_estimate') + ' ' + total.toFixed(2) + ' ' + t('common.from_each_sale')
    return str
  }

  function getLinkExpirations() {
    return [
      { label: t('common.forever'), value: -1 },
      { label: '1 ' + t('common.day'), value: 1 },
      { label: '7 ' + t('common.days'), value: 2 },
      { label: '30 ' + t('common.days'), value: 3 }
    ]
  }

  function getCurrencies() {
    return ["USD", "AED", "AFN", "ALL", "AMD", "ANG", "AOA", "ARS", "AUD", 
			"AWG", "AZN", "BAM", "BBD", "BDT", "BGN", "BHD", "BIF", "BMD", "BND",
			"BOB", "BRL", "BSD", "BWP", "BYN", "BZD", "CAD", "CDF", "CHF", "CLP",
			"CNY", "COP", "CRC", "CVE", "CZK", "DJF", "DKK", "DOP", "DZD", "EGP", 
			"ETB", "EUR", "FJD", "FKP", "GBP", "GEL", "GIP", "GMD", "GNF", "GTQ", 
			"GYD", "HKD", "HNL", "HTG", "HUF", "IDR", "ILS", "INR", "ISK", "JMD", 
			"JOD", "JPY", "KES", "KGS", "KHR", "KMF", "KRW", "KWD", "KYD", "KZT",
			"LAK", "LBP", "LKR", "LRD", "LSL", "MAD", "MDL", "MGA", "MKD", "MMK",
			"MNT", "MOP", "MRO", "MUR", "MVR", "MWK", "MXN", "MYR", "MZN", "NAD",
			"NGN", "NIO", "NOK", "NPR", "NZD", "OMR", "PAB", "PEN", "PGK", "PHP",
			"PKR", "PLN", "PYG", "QAR", "RON", "RSD", "RUB", "RWF", "SAR", "SBD",
			"SCR", "SEK", "SGD", "SHP", "SLE", "SOS", "SRD", "STD", "SZL", "THB",
			"TJS", "TND", "TOP", "TRY", "TTD", "TWD", "TZS", "UAH", "UGX", "UYU",
			"UZS", "VND", "VUV", "WST", "XAF", "XCD", "XOF", "XPF", "YER", "ZAR",
			"ZMW"]
  }

  return {
    getCurrencies,
    getFee,
    getLinkExpirations
  };
}