<script setup>
import { SeriesStore } from "@/front/stores/series_store.js";
const seriesStore = SeriesStore();
const router = useRouter();
import { useToast } from 'primevue/usetoast';
import { useI18n } from 'vue-i18n'
const { t } = useI18n({});

import VideoCard from '@/front/components/VideoCard.vue';

const openVideo = (video) => {
  console.log("💾 SeriesShow openVideo", video)
  if (seriesStore.series.manage) {
    router.push({ name: 'edit_video_path', params: { id: video.id } })
  } else {
    router.push({ name: 'playback_path', params: { id: video.id } })
  }
}

</script>

<template>
  <div>
    <div v-if="seriesStore.series.id">
      <!-- TODO breadcrumbs -->
      <!-- {{  seriesStore.series }} -->
      <h1 class="text-4xl font-bold mb-4">{{ seriesStore.series.name }}</h1>
      <div class="grid grid-cols-4 gap-4 mt-6">
      <VideoCard v-for="video in seriesStore.series.videos" :video="video" :key="video.id" class="mb-8 cursor-pointer"
          @click="openVideo(video)" :stats="false" />
      </div>
    </div>
  </div>
</template>
