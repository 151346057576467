<template>
  <div id="video-uploader">
    <Dashboard :uppy="uppy" :props="dashboardOptions" class="align-center justify-center"/>
  </div>
</template>
<script setup>
  import { Dashboard } from '@uppy/vue';
  import Uppy from '@uppy/core';
  import Webcam from '@uppy/webcam';
  import AwsS3 from '@uppy/aws-s3';
  // import RemoteSources from '@uppy/remote-sources';
  // import { COMPANION_URL, COMPANION_ALLOWED_HOSTS } from '@uppy/transloadit';
  import { UploadStore } from '@/front/stores/upload_store';
  import { TimeFormatter } from "../composables/TimeFormatter.js";
  const { capitalizeWords } = TimeFormatter();
  const uploadStore = UploadStore()

  import Axios from "axios";
  import { createApi } from '@/plugins/api';

  const Api = createApi({ handler: Axios, namespace: '/' });

  // Don't forget the CSS: core and UI components + plugins you are using
  import '@uppy/core/dist/style.css';
  import '@uppy/dashboard/dist/style.css';
  import '@uppy/webcam/dist/style.css';

  const props = defineProps({
    type: {
      type: Array,
      default: ['video/*']
    },
    remote: {
      type: Boolean,
      default: false
    },
    webcam: {
      type: Boolean,
      default: false
    }
  })

  var uploadUrl = ref(null)
  var uploadKey = ref(null)

  const uppy = new Uppy({
    restrictions: {
      maxNumberOfFiles: 1,
      allowedFileTypes: props.type
    },
    debug: true,
    autoProceed: true,
  })
  const dashboardOptions = {
    proudlyDisplayPoweredByUppy: false,
  }

  // S3
  // TODO bucket
  uppy.use(AwsS3, {
    bucket: 'mindstamp-dev',
    getUploadParameters(file, options) {
      const filenameWithoutSpaces = file.name.replace(/\s+/g, '');
      return Api.get(`/uppy/s3?filename=${filenameWithoutSpaces}&filetype=${file.type}`).then((response) =>  {
        console.log("Set Key: ", response.data.key)
        uploadUrl.value = response.data.url
        uploadStore.uploadSettings.key = response.data.key
        return {
          method: "PUT",
          url: response.data.url,
          headers: {
            "Content-Type": file.type,
          },
        };
      })
    },
    createMultipartUpload(file) {
      console.log("🌜 Create Multipart Upload")
      let data = {"filename": file.name, "filetype": file.type}
      return Api.post("/uppy/s3-multipart", data).then((response) =>  {
        uploadStore.uploadSettings.key = response.data.key
        return response.data
      })
    },
    listParts(file, { uploadId, key }) {
      let data = {"key": key}
      return Api.post(`/uppy/s3-multipart/${uploadId}`, data).then((response) =>  {
        console.log("🌜 List Parts Response", response)
        return response.data
      })
    },
    signPart(file, { key, uploadId, partNumber }) {
      let data = {"key": key}
      return Api.post(`/uppy/s3-multipart/${uploadId}/${partNumber}`, data).then((response) =>  {
        // console.log("🌜 Sign Part Response", response)
        return response.data
      })
    },
    abortMultipartUpload(file, { uploadId, key }) {
      return Api.destroy(`/uppy/s3-multipart/${uploadId}?key=${key}`).then((response) =>  {
        console.log("🌜 Abort Multipart Upload Response", response)
        return response.data
      })
    },
    completeMultipartUpload(file, { uploadId, key, parts }) {
      let data = {"key": key, "parts": parts}
      return Api.post(`/uppy/s3-multipart/${uploadId}/complete`, data).then((response) =>  {
        console.log("🌜 Complete Multipart Upload Response", response)
        return response.data
      })
    },
    shouldUseMultipart(file) {
      return file.size > 100 * 2 ** 20;
    },
  })

  if (props.remote) {
    // Remote sources
    uppy.use(RemoteSources, {
      companionUrl: COMPANION_URL,
      companionAllowedHosts: COMPANION_ALLOWED_HOSTS,
    })
  } 

  if (props.webcam) {
    // Webcam
    uppy.use(Webcam, {
      mirror: true,
      preferredVideoMimeType: 'video/webm'
    })
  } 



  const emit = defineEmits(['success', 'start'])

  uppy.on('upload-success', (file) => {
    console.log("🌜 Upload Success", file)
    emit('success', uploadUrl.value)
    setTimeout(() => {
      uploadStore.isUploading = false
    }, 5000);
  })
  uppy.on('upload-error', (file) => {
    console.log("🌜 Upload error", file)
    emit('error', file)
    setTimeout(() => {
      uploadStore.isUploading = false
    }, 5000);
  })
  uppy.on('upload-start', (files) => {
    console.log("🌜 Upload Start", files)
    uploadStore.uploadFile = files[0].data
    uploadStore.uploadSettings.type_of = "upload"
    uploadStore.isUploading = true
    uploadStore.uploadSettings.title = capitalizeWords(files[0].name.replace(/\.[^/.]+$/, ""))
    emit('start')
  })
  uppy.on('upload-progress', (event) => {
    // console.log("💦 Upload Progress", event.progress.percentage)
    uploadStore.uploadProgress = event.progress.percentage
    uploadStore.isUploading = true
    if (event.progress.percentage === 100) {
      uploadStore.isUploading = false
    }
  })

onMounted(() => {
  console.log("🌜 Video Uploader Mounted")
})
</script>

<style>
#video-uploader .uppy-Dashboard-inner {
  margin: 0 auto;
}

.uppy-Dashboard-AddFiles-title {
  line-height: 10;
}
</style>

