export function StringValidator() {
  const { t } = useI18n({})

  function isValidEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email)
  }

  function isValidPhoneNumber(phone) {
    const phoneRegex = /^\+?[0-9]{1,15}$/;
    return phoneRegex.test(phone)
  } 

  function isValidDomain(domain) {
    if (!domain) return false;
    
    // Check if the domain starts with https://
    if (!domain.startsWith('https://')) return false;
    
    // Remove the https:// protocol
    const domainWithoutProtocol = domain.slice(8);
    
    // Regular expression for domain validation
    const domainRegex = /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$/;
    
    return domainRegex.test(domainWithoutProtocol);
  }
  return {
    isValidEmail,
    isValidPhoneNumber,
    isValidDomain
  };
}