import { defineStore } from 'pinia'

export const AssetStore = defineStore('assets', {
  state: () => {
    return {
      videos: [],
      groups: JSON.parse(localStorage.getItem("groups") || "[]"),
      series: JSON.parse(localStorage.getItem("series") || "[]"),
      tags: JSON.parse(localStorage.getItem("tags") || "[]"),
      chapters: JSON.parse(localStorage.getItem("chapters") || "[]"),
      assets: JSON.parse(localStorage.getItem("assets") || "[]"),
      clips: JSON.parse(localStorage.getItem("clips") || "[]"),
      images: JSON.parse(localStorage.getItem("images") || "[]"),
      articles: JSON.parse(localStorage.getItem("articles") || "[]"),
      audios: JSON.parse(localStorage.getItem("audios") || "[]"),
      sources: JSON.parse(localStorage.getItem("sources") || "[]"),
      documents: JSON.parse(localStorage.getItem("documents") || "[]"),
      lists: JSON.parse(localStorage.getItem("lists") || "[]"),
      loading: false,
      selected: null,
      currentSrc: null,
      currentList: null
    }
  },

  actions: {
    async index(from="empty") {
      console.log("AssetStoreIndex", from)
      this.loading = true
      return this.Api.get('/assets').then(response => { 
        console.log("🌜 AssetStore index", response)
        this.videos = response.data.videos
        this.groups = response.data.groups
        this.series = response.data.series
        this.tags = response.data.tags
        this.chapters = response.data.chapters
        this.assets = response.data.assets
        this.images = response.data.images
        this.clips = response.data.clips
        this.articles = response.data.articles
        this.files = response.data.files
        this.countries = response.data.countries
        this.audios = response.data.audios
        this.sources = response.data.sources
        this.lists = response.data.lists
        this.documents = response.data.documents
        this.storeData(response.data)
      }).finally(() => {
        this.loading = false
      })
    },
    async indexVideos() {
      return this.Api.get('/videos').then(response => {  
        console.log("🌜 AssetStore indexVideos", response)
        this.videos = response.data.videos
      })
    },
    findVideo (id) {
      if (id.includes("---")) {
        return this.videos.find(video => video.token == id.split("---")[1])
      } else {
        return this.videos.find(video => video.id == id)
      }
    },
    findSource (id) {
      return this.sources.find(source => source.id == id)
    },
    findAudio(id) {
      return this.audios.find(asset => asset.id == id)
    },
    findFile(id) {
      return this.files.find(asset => asset.id == id)
    },
    findArticle(id) {
      return this.articles.find(asset => asset.id == id)
    },
    storeData(data) {
      localStorage.setItem("videos", JSON.stringify(data.videos || []))
      localStorage.setItem("groups", JSON.stringify(data.groups || []))
      localStorage.setItem("series", JSON.stringify(data.series || []))
      localStorage.setItem("tags", JSON.stringify(data.tags || []))
      localStorage.setItem("chapters", JSON.stringify(data.chapters || []))
      localStorage.setItem("assets", JSON.stringify(data.assets || []))
      localStorage.setItem("images", JSON.stringify(data.images || []))
      localStorage.setItem("clips", JSON.stringify(data.clips || []))
      localStorage.setItem("articles", JSON.stringify(data.articles || []))
      localStorage.setItem("countries", JSON.stringify(data.countries || []))
      localStorage.setItem("files", JSON.stringify(data.files || []))
      localStorage.setItem("lists", JSON.stringify(data.lists || []))
    },
    createAsset(file) {
      return this.Api.post('/assets', { file: file }).then(response => {
        console.log("🌜 AssetStore created", response)
        this.index()
        return response
      })
    },
    updateAsset(id, data) {
      return this.Api.put('/assets/' + id, data).then(response => {
        console.log("🌜 AssetStore updateAsset", response)
        this.index()
        return response
      })
    },
    hideAsset(id) {
      return this.Api.post('/assets/hide', { id: id }).then(response => {
        console.log("🌜 AssetStore hideAsset", response)
        this.index()
        return response
      })
    },
    updateArticle(id, data) {
      return this.Api.put('/articles/' + id, data).then(response => {
        console.log("🌜 AssetStore updateArticle", response)
        this.index()
        return response
      })
    },
    createArticle(data) {
      return this.Api.post('/articles', data).then(response => {
        console.log("🌜 AssetStore createArticle", response)
        this.index()
        return response
      })
    },
    streamById(id) {
      return this.Api.get('/assets/stream/' + id).then(response => {
        console.log("🌜 AssetStore streamById", response)
        this.currentSrc = response.data.url
      })
    },
    fetchList(id) {
      return this.Api.get('/access-lists/' + id).then(response => {
        console.log("🌜 AssetStore fetchList", response)
        this.currentList = response.data.access_list
      })
    },
    addListItem(entry) {
      return this.Api.post('/access-lists/' + this.currentList.id + '/items', { item: entry })
    },
    removeListItem(id) {
      console.log("🌜 AssetStore removeListItem", id)
      return this.Api.destroy('/access-lists/' + this.currentList.id + '/items/' + id)
    },
    updateList(id, data) {
      return this.Api.put('/access-lists/' + id, data)
    },
    createList(data) {
      return this.Api.post('/access-lists', data)
    },
    deleteList(id) {
      return this.Api.destroy('/access-lists/' + id)
    },
    addListItems(items) {
      return this.Api.post('/access-lists/' + this.currentList.id + '/items/bulk', { items: items })
    }
  }
})